import { getAuthToken } from '../../../Utils/Device'
import { NetworkError } from '../../../Utils/Network'
import { __PublicAPI__ } from '../../../Infra/HttpServices'

const BasicUserInfoHttp = async () => {
  const token = await getAuthToken()

  const response = await fetch(
    `${__PublicAPI__}/auth/profile`,
    {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }
  )

  return response.json()
}

const QrCodeAndSecretKeyUserInfoHttp = async () => {
  const token = await getAuthToken()

  const response = await fetch(
    `${__PublicAPI__}/auth/getQrCodeAndSecretKey`,
    {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }
  )

  return response.json()
}

const LoyaltyPointsCustomerInfoHttp = async ({ nameLike }) => {
  const token = await getAuthToken()
  let requestPath = `${__PublicAPI__}/api/order/loyaltyPointsByCustomer`

  if(nameLike !== "") {
    requestPath += `/?nameLike=${nameLike}`
  }

  const response = await fetch(
    requestPath,
    {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }
  )

  return response.json()
}


const UpdatePersonalInfoHttp = async (data) => {
  const token = await getAuthToken()

  const response = await fetch(
    `${__PublicAPI__}/auth/customer`,
    {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT',
      body: JSON.stringify(data)
    }
  )

  document.location.reload();

  return response
}

const PasswordForgotHttp = async (data) => {
  const response = await fetch(
    `${__PublicAPI__}/auth/reset/forgot-password`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  )

  if (response.ok)
    return response

  return response.json()
}

const PhoneChangeHttp = async () => {
  const token = await getAuthToken()

  const response = await fetch(
    `${__PublicAPI__}/auth/reset/phone`,
    {
      headers:
      {
        'Authorization': `Bearer ${token}`,
      }
    }
  )

  if (response.ok)
    return {}

  return response.json()
}

const PhoneChangeCallbackHttp = async (data) => {
  const token = await getAuthToken()

  const response = await fetch(
    `${__PublicAPI__}/auth/reset/phone/callback`,
    {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  )

  if (response.ok)
    return response

  return response.json()
}

const ChangePhoneHttp = async (data) => {
  const token = await getAuthToken()

  const response = await fetch(
    `${__PublicAPI__}/auth/change/phone`,
    {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  )

  if (response.ok)
    return response

  return response.json()
}

const PasswordForgotCallbackHttp = async (data) => {
  const response = await fetch(
    `${__PublicAPI__}/auth/reset/forgot-password/callback`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  )

  if (response.ok)
    return response

  return response.json()
}

const ChangeEmailHttp = async (data) => {
  const token = await getAuthToken()

  const response = await fetch(
    `${__PublicAPI__}/auth/reset/email`,
    {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }
  )

  if (response.ok)
    return response

  return response.json()
}

const PasswordChangeHttp = async (data) => {
  const token = await getAuthToken()

  const response = await fetch(
    `${__PublicAPI__}/auth/reset/password`,
    {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  )

  if (response.ok)
    return response

  return response.json()
}

const GetAddressHttp = async () => {
  
  const token = await getAuthToken()

  const response = await fetch(
    `${__PublicAPI__}/api/address`,
    {
      method: 'GET',
      headers:
      {
        'Authorization': `Bearer ${token}`,
      }
    }
  )
  
  if (response.ok)
    return await response.json()

  return []
}

const DeleteAddressHttp = async (addressId) => {
  const token = await getAuthToken()

  const response = await fetch(
    `${__PublicAPI__}/api/address/${addressId}`,
    {
      method: 'DELETE',
      headers:
      {
        'Authorization': `Bearer ${token}`,
      }
    }
  )

  document.location.reload();
  
  return response;
}

export const BasicUserInfoHttpService = NetworkError(BasicUserInfoHttp)
export const GetPersonalInfoHttpService = NetworkError(BasicUserInfoHttp)
export const UpdatePersonalInfoHttpService = NetworkError(UpdatePersonalInfoHttp)
export const PasswordForgotHttpService = NetworkError(PasswordForgotHttp)
export const PasswordForgotCallbackHttpService = NetworkError(PasswordForgotCallbackHttp)
export const ChangeEmailHttpService = NetworkError(ChangeEmailHttp)
export const PasswordChangeHttpService = NetworkError(PasswordChangeHttp)
export const PhoneChangeHttpService = NetworkError(PhoneChangeHttp)
export const PhoneChangeCallbackHttpService = NetworkError(PhoneChangeCallbackHttp)
export const ChangePhoneHttpService = NetworkError(ChangePhoneHttp)
export const GetAddressHttpService = NetworkError(GetAddressHttp)
export const DeleteAddressHttpService = NetworkError(DeleteAddressHttp);
export const QrCodeAndSecretKeyUserInfoHttpService = NetworkError(QrCodeAndSecretKeyUserInfoHttp)
export const LoyaltyPointsCustomerInfoHttpService = NetworkError(LoyaltyPointsCustomerInfoHttp)