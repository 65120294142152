import React from 'react';

import LoadingComponent from '../../../../components/utils/withLoading'

import { PhoneChangeHttpService } from '../../HttpServices'
import './style.scss'

class PhoneResetMessage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      error: null
    }
  }

  async componentDidMount() {
    const response = await PhoneChangeHttpService()
   
    if (response.error)
      return this.setState({
        loading: false,
        error: response.error
      })

    return this.setState({
      error: null,
      loading: false
    })
  }

  render() {
    return (
      <LoadingComponent loading={this.state.loading}>
        {this.state.error
          ? <p>An Error occured</p>
          : <div className='um-container'>
              <img src={require("../../../../static/img/email.png")} alt="email" className='um-image' />
              <p className='um-text'>Un email a été envoyé a votre adresse, veuillez vérifier votre boîte de réception pour changer votre numéro de téléphone.</p>
            </div>
        }
      </LoadingComponent>
    );
  }
}

export default PhoneResetMessage
