import React from 'react'

import { LoadScript } from '@react-google-maps/api'

const LoadScriptWrapper = ({ id, googleMapsApiKey, libraries, region, language, children }) => (
  <LoadScript
    id={id}
    googleMapsApiKey={googleMapsApiKey}
    libraries={libraries}
    region={region}
    language={language}
  >
    {children}
  </LoadScript>
)

export default LoadScriptWrapper