import React, { useState } from "react";

import './style.scss';

const PhoneNumber = ({ placeholder, setValue, noContainer = false, style }) => {
  const [error, setError] = useState("");
  const [phoneNumber, setPhone] = useState(null);

  const validate = (value) => {
    if (!value) {
      return "Ce champ est obligatoire";
    } else if (!/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i.test(value)) {
      return "Numéro de téléphone invalide";
    }

    return null;
  };

  const setPhoneNumber = (value) => {
    const error = validate(value);

    if (error) setError(error);
    else setError("");

    setPhone(value);
    setValue(value);
  };

  return (
    <div className={noContainer? "forms-field-no-container": "forms-field"}>
      <input
        style={style}
        placeholder={placeholder}
        onChange={(evt) => setPhoneNumber(evt.target.value)}
      />

      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default PhoneNumber;