import React from "react"

import Thumbnail from "./Thumbnail"
import { getFavoriteRestaurants ,getAds,getConfig, getCategories} from "../../HttpServices"
import LoadingComponent from "../../../../components/utils/withLoading"
import { MenuContext } from "../../../../context"
import { MenuProvider } from "../../../../context/Provider"
import Dialog from "../../../../components/DiningModeControls/Dialog"
import withNavigateHook from "../../../../components/WithNavigation"
import './style.scss'

class FavoriteRestaurantListView extends React.Component {
  static contextType = MenuContext

  constructor(props) {
    super(props)

    this.state = {
      restaurants: props.restaurants || [],
      error: props.error || null,
      ShowDialog: false,
      ads : [],
      AppConf : {},
      open: false,
      categories: [],
      nameLike: ""
    }
  }
  redirectToRestaurant = (id) => {
    this.props.navigate(`/restaurant/${id}/`)
  }

  toggleOpen() {
    return this.setState({
      open: !this.state.open
    })
  }

  resetFilters() {
    this.setState({
      categories: []
    }, () => {
      this.props.ReloadData({categories: this.state.categories, nameLike: this.state.nameLike})
    })
  }

  handleCheckboxClick(id) {
    let categ = [...this.state.categories];

    if(!categ.includes(id)) {
      categ.push(id)
    }else {
      let i = categ.indexOf(id);

      if(i !== -1) {
        categ.splice(i, 1);
      }
    }

    this.setState({
      categories: categ
    });
  }

  onPress = (restaurant) => {
    const { SupportsOnSpot, SupportsPickUp, SupportsDelivery } = {
      ...restaurant,
    }

    // Create the new state objects and check if the new state requires changing the dining mode
    let newDiningModes = this.context.DINING_MODES.map(mode => {
      if (mode.id === "onspot")
        mode.disabled = !SupportsOnSpot

      if (mode.id === "takeaway")
        mode.disabled = !SupportsPickUp
      
      if (mode.id === "delivery")
        mode.disabled = !SupportsDelivery

      return mode
    })

    const newDisabledMode = newDiningModes.find(mode => mode.selected && mode.disabled)

    if (newDisabledMode) {
      return (
        <MenuProvider>
          <Dialog
            message={`Ce restaurant ne supporte pas le mode ${newDisabledMode.label} vous devez choisir un autre mode`}
            context={this.context}
            redirect={() => this.redirectToRestaurant(restaurant.Id)}
            modes={newDiningModes}
          />
        </MenuProvider>
      )
    }

    this.redirectToRestaurant(restaurant.Id)
  }
  
  handleTextChange (text) {
    this.setState({
      nameLike: text
    });

    this.props.ReloadData({
      categories: this.state.categories,
      nameLike: text
    });
  }

  render() {
    return (
      <div className="fav-restaurant-container">

        <div className="body">
          {/* Filters */}
          <div className="filters">
            <div className="flex">
              <div className="filters-container">
                <div className="select-category">
                  <img src={require("../../../../static/img/category.png")} alt="category" />
                  <p className="normal-text">Spécialités</p>
                  <button
                    onClick={() => this.toggleOpen()}
                  >
                    <img src={require("../../../../static/img/dropdown.png")} alt="dropdown" />
                  </button>
                </div>
                <div className="search-field">
                  <img src={require("../../../../static/img/loupe.png")} alt="loupe" />
                  <input placeholder="Restaurant" onChange={(event) => this.handleTextChange(event.target.value)} />
                </div>
              </div>
              <div className="dummy">
                {this.state.open &&
                  <div className="category-list">
                    <div className="categories-container">
                      {this.props.categories.length > 0 && this.props.categories.map(c => (
                        <div key={c.Id} className="category">
                          <input onClick={() => this.handleCheckboxClick(c.Id)} type="checkbox" name="category" value={c.Id} />
                          <p>{c.Name}</p>
                        </div>
                      ))}
                      {this.props.categories.length <= 0 &&
                        <p className="normal-text">Pas de données à afficher</p>
                      }
                    </div>
                    <div className="button-container">
                      <button
                        onClick={() =>{
                          this.props.ReloadData({categories: this.state.categories, nameLike: this.state.nameLike})
                          this.setState({open: false});
                        }}
                      >
                        <p>Valider</p>
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="dining-filters">
              <button
                onClick={() => {
                  this.resetFilters()
                }}
              >
                <p className="reset-filters bold">Tout afficher</p>
              </button>
            </div>
          </div>

          {/* Restaurant list */}
          <div className="restaurant-list" id="restaurant-list">
            {/* Restaurant list is not empty */}

            {this.props.restaurants.map((restaurant) => (
              <Thumbnail
                restaurant={restaurant}
                onPress={this.onPress}
                ReloadData={() => this.props.ReloadData({ categories: this.state.categories, nameLike: this.state.nameLike })}
                key={restaurant.Id}
                redirectTo={(location) => this.props.navigate(location)}
              />
            ))}

            {/* Restaurant list is empty */}
            {this.props.restaurants.length === 0 && !this.props.error && (
              <p className="text">
                Pas de restaurants favoris
              </p>
            )}

            {/* Error message */}
            {this.props.error && (
              <p className="error text">
                {this.props.error}
              </p>
            )}
          </div>
        </div>
      </div>
    )
  }
}

function getFavoriteRestaurantList(WrappedComponent) {
  return class extends React.Component {
    static contextType = WrappedComponent.contextType

    constructor(props) {
      super(props)

      this.state = {
        loading: true,
        error: "",
        restaurants: [],
        categories: []
      }
    }
    
    getFavoriteRestaurants = ({categories = [], nameLike = ""}) => {
      return getFavoriteRestaurants({categories: categories, nameLike: nameLike})
      .then((restaurants) =>
      this.setState({
        restaurants: restaurants,
        loading: false,
      })
      )
      .catch((err) =>
      this.setState({
        error: "Une erreur s'est produite lors de la connexion",
        loading: false,
      })
      )
      .catch((err) =>
      this.setState({
        error: "Une erreur s'est produite lors de la connexion",
        loading: false,
      })
      )
    }

    getCategories() {
      return getCategories()
        .then((categories) =>
          this.setState({
            categories: categories
          })
        )
    }

    componentDidMount() {
      this.getFavoriteRestaurants({}).then(() => this.getCategories())
    }

    render() {
      return (
        <LoadingComponent loading={this.state.loading}>
          <WrappedComponent
            restaurants={this.state.restaurants}
            categories={this.state.categories}
            loading={this.state.loading}
            ReloadData={this.getFavoriteRestaurants}
            error={this.state.error}
            {...this.props}
          />
        </LoadingComponent>
      )
    }
  }
}

export default withNavigateHook(
  getFavoriteRestaurantList(FavoriteRestaurantListView)
);