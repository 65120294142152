import React from 'react';
import SeparatorLogo from '../../static/img/separator.svg';

import './style.scss';

const Breadcrumb = ({ sections, onPress }) => (
  <div className='breadcrumb-container'>
    {sections
      ? sections.map((section, index) =>
        sections.length > index + 1
          ? <div key={index} className="back">
            <div onClick={() => onPress(index)} className="text-container">
              <p className='link black bold'>{section}</p>
            </div>
            <div className='separator'>
              <img src={SeparatorLogo} alt="separator" />
            </div>
          </div>
          : <div key={999}>
            <div className='text-container'>
              <p className='last-item grey bold'>{section}</p>
            </div>
          </div>
      )
      : null}
  </div>
)

export default Breadcrumb;