import React, { useContext, useState } from 'react';

import { MenuContext } from '../../../context';
import DiningMode from '../../Navbar/DiningModeList/index';
import ChevronLogo from '../../../static/img/chevron.svg';

import './style.scss';

const DiningNav = ({ onPress }) => {
  const context = useContext(MenuContext);
  const [open, setOpen] = useState(false);

  return (
    <div id="order-mode" className='dining-nav-container'>
      {context.DINING_MODES.map(mode => mode.selected &&
        <button key={mode.id} onClick={() => setOpen(!open)}>
          <img src={mode.logo} alt="mode-logo" />
          <p>{mode.label}</p>
          <img src={ChevronLogo} alt="chevron" />
        </button>
      )}

      <DiningMode context={context} open={open} close={() => setOpen(false)} />
    </div>
  )
}

export default DiningNav;