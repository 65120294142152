import React, { useState } from 'react'
import { v4 as uuid } from 'uuid';

import { MenuContext } from '../../../../context'

import StepCrumb from '../../../../components/StepCrumb'

import MenuManager from '../../Modules'

import SelectionList from './SelectionView'
import ProductSection from './SectionView'
import CompositionView from './CompositionView'
import AllergenesView from './AllergenesView';
import CloseIcon from "../../../../static/img/letter-xblack.svg";
import Modal from '../../../../components/Modal';
import './style.scss'; 

/*
  TODO: Save the menu along the cart
  TODO: Save the section Ids along the cart components
  TODO: This may require saving the product sections along the with the cart to prevent having to add and navigate the whole menu,
        this is one step closer to getting rid of the menu structure from the other parts of the application!
*/

// ScrollView bug: https://github.com/microsoft/reactxp/issues/598
/* NOTE ON MODAL STATE LOGIC:
  React-xp modals are supposedly rendered inside the RootView defined by `RX.UserInterface.setMainView`,
  this declaration can be found in the `index.tsx`.
  Currently neither this nor specifying the `rootViewId` option on the modal seems to render the modal under the root view,
  resulting in a disconnection between app and modal state.
  A workaround is to pass the rootView state as prop from the component that displays the modal and save the state back
  when closing the modal.
*/

/*
CASE 1: The user is doing a first time selection.
CASE 2: The user is editing a component.
*/

/*
Component Rules:
  - Should respect MaxSelection flag.
*/

/*
Product Section Rules:
  - Should respect MaxSelection flag.
  - Should respect MinSelection flag.
*/

const MenuConfiguration = ({ menu, cart, activeChoice, activeComponent, resetActiveComponent, nextSection, goToSection, sectionIndex, displayComposition, isEditing, onClose, Style }) => {
  const [allergenes, setAllergenes] = useState(null)
  
  return (
    <div className='cart-compo-container'>
      <Modal
        isOpen={displayComposition}
        id="Cart-Compo"
      >
        {displayComposition &&
          <CompositionView
            componentId={activeComponent && activeComponent.Id}
            compositions={activeComponent && activeComponent.Compositions}
            activeComponent={activeComponent}
            addCompositionToCart={cart && cart.addComposition}
            removeCompositionFromCart={cart && cart.removeComposition}
            getCompositionQuantity={cart && cart.getCompositionQuantity}
            getCompositionComponentQuantity={cart && cart.getCompositionComponentQuantity}
            validate={resetActiveComponent}
            onClose={onClose}
            isEditing={isEditing}
            displayAllergenes={(allergenes) => setAllergenes(allergenes)}
          />
        }
      </Modal>
      {allergenes &&
        <AllergenesView
          allergenes={allergenes}
          onClose={() => setAllergenes(null)}
        />
      }

      {/* Modal header */}
      <div className='header'>
        {/* Menu title */}
        <div className='header-container'>
          <p className='header-text'>{menu.Name}</p>
          {/* Modal close button */}
          <button
            onClick={() => {
              onClose()
            }}
            className="header-button"
          >
            <img src={CloseIcon} alt="close" />
            <p>Quitter le menu</p>
          </button>
        </div>

        {/* Menu steps */}
        <div className='sc-container'>
          <div>
            <StepCrumb steps={activeChoice.Products} stepIndex={sectionIndex} onStepPress={(index) => goToSection(index)} />
          </div>
        </div>
      </div>

      {/* Modal Body */}
      <div className='body'>
        <div className='back-container'>
          <div className='dummy'></div>
          {sectionIndex > 0 &&
            <button
              onClick={() => {
                goToSection(sectionIndex - 1)
              }}
              className="back-button"
            >
              <p>Retour</p>
            </button>
          }
        </div>
        <div className='sec-container'>
          {/* Selected components */}
          <SelectionList cart={cart.Components} cost={cart.totalCost} />

          {/* Menu section */}
          <ProductSection
            components={menu.Sections[sectionIndex].Components}
            getComponentQuantity={(component) => cart.getComponentQuantity(component)}
            addComponentToCart={(selectedComponent) => cart.addComponent(selectedComponent)}
            triggerNextStep={() => nextSection()}
          />
        </div>
      </div>
    </div>
  )
}


function NormalizeMenu(WrappedComponent) {
  return class extends React.Component {
    // Context must be passed down explicitly
    static contextType = MenuContext

    /*
      There is two types of menus:
        - A menu that starts with more than one Base product (e.g nuggets)
        - A menu that starts with just one Base product
      There is no flag that communicates this difference, this is why this func has been written.
    */
    isSingleBaseProduct() {
      return this.props.activeChoice.Products[0].Components.length === 1
    }

    filterComponentsBasedOnDiningMode(components) {
      const diningModeMap = {
        onspot: 1,
        takeaway: 2,
      };

      let newList = [];

      newList = components.filter(
        (item) =>
          item.TypeEmporteOuSurPlace === 0 ||
          item.TypeEmporteOuSurPlace === diningModeMap[this.props.context.diningMode]
      )

      return newList;
    }

    // Only pass down the required params.
    normalizeMenu() {
      if (this.props.context.diningMode === "delivery")
        return {
          Id: this.props.activeChoice.Id,
          Name: this.props.activeChoice.Name,
          isSingleBaseProduct: this.isSingleBaseProduct(),
          Sections: this.props.activeChoice.Products
            .map(section => ({
              Id: section.Id,
              Name: section.Name,
              MaxSelection: section.MaxSelection,
              Components: section.Components
                .filter(component => component.IsDeliverable === true)
                .map(component => ({
                  Id: component.Id,
                  Name: component.Name,
                  Image: component.Image,
                  Price: component.PriceDelivery,
                  MaxSelection: component.MaxSelection,
                  HasCompositions: component.HasCompositions,
                  HasAllergenes: component.HasAllergenes,
                  Allergenes: component.Allergenes,
                  IsNextButton: component.IsNextButton,
                  LoyaltyPointsProd: component.LoyaltyPointsProdDelivery,
                  LoyaltyPointsRetrait: component.LoyaltyPointsRetraitDelivery,
                  LoyaltyPointsPrice: component.LoyaltyPointsPriceDelivery,
                  IdPcAchatOffert: component.IdPcAchatOffert,
                  OfIsApply: component.OfIsApply,
                  OfNbAc: component.OfNbAc,
                  OfNbOf: component.OfNbOf,
                  OfIsPc: component.OfIsPc,
                  MaxCmpSelection: component.MaxCmpSelection,
                  Compositions: component.Compositions.map(composition => ({
                    Id: composition.Id,
                    Name: composition.Name,
                    Image: composition.Image,
                    Price: composition.Price,
                    MaxSelection: composition.MaxSelection,
                    IsDefault: composition.IsDefault,
                    Allergenes: composition.Allergenes
                  }))
                }))
            }))
        }

      return {
        Id: this.props.activeChoice.Id,
        Name: this.props.activeChoice.Name,
        isSingleBaseProduct: this.isSingleBaseProduct(),
        Sections: this.props.activeChoice.Products.map(section => ({
          Id: section.Id,
          Name: section.Name,
          MaxSelection: section.MaxSelection,
          Components: this.filterComponentsBasedOnDiningMode(section.Components).map(component => ({
            Id: component.Id,
            Name: component.Name,
            Image: component.Image,
            Price: component.Price,
            MaxSelection: component.MaxSelection,
            HasCompositions: component.HasCompositions,
            HasAllergenes: component.HasAllergenes,
            Allergenes: component.Allergenes,
            IsNextButton: component.IsNextButton,
            LoyaltyPointsProd: component.LoyaltyPointsProd,
            LoyaltyPointsRetrait: component.LoyaltyPointsRetrait,
            LoyaltyPointsPrice: component.LoyaltyPointsPrice,
            IdPcAchatOffert: component.IdPcAchatOffert,
            OfIsApply: component.OfIsApply,
            OfNbAc: component.OfNbAc,
            OfNbOf: component.OfNbOf,
            OfIsPc: component.OfIsPc,
            MaxCmpSelection: component.MaxCmpSelection,
            Compositions: component.Compositions.map(composition => ({
              Id: composition.Id,
              Name: composition.Name,
              Image: composition.Image,
              Price: composition.Price,
              MaxSelection: composition.MaxSelection,
              IsDefault: composition.IsDefault,
              Allergenes: composition.Allergenes
            }))
          }))
        }))
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          menu={this.normalizeMenu()}
        />
      )
    }
  }
}

function GetCart(WrappedComponent) {
  return class extends React.Component {
    // Context must be passed down explicitly
    static contextType = MenuContext

    render() {
      const cart = this.props.context.cart.find(entry => entry.Id === this.props.cartId)

      return <WrappedComponent {...this.props} cart={cart} activeChoice={cart.menuNode} />
    }
  }
}

// Check why the cart ends up in the menu object.
// This should be the last step.

function StateFromCart(WrappedComponent) {
  return class extends React.Component {
    // Context must be passed down explicitly
    static contextType = MenuContext

    constructor(props) {
      super(props)

      this.state = {
        ...this.init(),
        ...this.initComposition(),
        complete: true
      }
    }

    /* Initialize from an existing cart */
    initComposition() {
      const product = this.props.menu.Sections[this.props.sectionIndex].Components.find(component => component.Id === this.props.componentId)

      if (this.props.displayComposition)
        return {
          activeComponent: product,
          displayComposition: true
        }

      return {
        activeComponent: null,
        displayComposition: false
      }
    }

    /* Initialize an empty cart */
    init() {

      // Case 1: A menu that starts with just one Base product \__('v')__/
      if (this.props.menu.isSingleBaseProduct)
        return {
          Id: this.props.cart.Id,
          ProductId: this.props.cart.ProductId,
          Name: this.props.cart.Name,
          Quantity: this.props.cart.Quantity,
          Components: this.props.cart.Components,
          Compositions: this.props.cart.Compositions,
          sectionIndex: this.props.sectionIndex || 1,
          Sections: this.props.menu.Sections,
          family: this.props.cart.family,
          menuNode: this.props.cart.menuNode,
          diningMode: this.props.cart.diningMode
        }

      // Case 1: A menu that starts with more than one Base product (e.g nuggets)
      return {
        Id: this.props.cart.Id,
        ProductId: this.props.cart.ProductId,
        Name: this.props.cart.Name,
        Quantity: this.props.cart.Quantity,
        Components: this.props.cart.Components,
        Compositions: this.props.cart.Compositions,
        sectionIndex: this.props.sectionIndex || 0,
        Sections: this.props.menu.Sections,
        family: this.props.cart.family,
        menuNode: this.props.cart.menuNode,
        diningMode: this.props.cart.diningMode
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          menu={this.state}
          onClose={this.props.onClose}
          onOpen={this.props.onOpen}
        />
      )
    }
  }
}

function StateFromMenu(WrappedComponent) {
  return class extends React.Component {
    // Context must be passed down explicitly
    static contextType = MenuContext

    constructor(props) {
      super(props);

      this.state = {
        ...this.init(),
        ...this.initComposition()
      }
    }

    /* Initialize an empty cart */
    init() {
      // Case 1: A menu that starts with just one Base product \__('v')__/
      if (this.props.menu.isSingleBaseProduct)
        return {
          Id: uuid(),
          ProductId: this.props.menu.Id,
          Name: this.props.menu.Name,
          Quantity: 1,
          Components: [{
            ...this.props.menu.Sections[0].Components[0],
            SectionIndex: 0,
            Quantity: 1
          }],
          Compositions: [],
          sectionIndex: 1,
          Sections: this.props.menu.Sections
        }

      // Case 1: A menu that starts with more than one Base product (e.g nuggets)
      return {
        Id: uuid(),
        ProductId: this.props.menu.Id,
        Name: this.props.menu.Name,
        Quantity: 1,
        Components: [],
        Compositions: [],
        displayComposition: false,
        sectionIndex: 0,
        Sections: this.props.menu.Sections
      }
    }

    initComposition() {
      const product = this.props.menu.Sections[0].Components[0]
      const displayComposition = this.props.menu.isSingleBaseProduct && this.props.menu.Sections[0].Components[0].HasCompositions

      if (displayComposition)
        return {
          activeComponent: product,
          displayComposition: true
        }

      return {
        activeComponent: null,
        displayComposition: false
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          menu={this.state}
        />
      )
    }
  }
}

const InitializeFromMenu = NormalizeMenu(StateFromMenu(MenuManager(MenuConfiguration)));

const InitializeFromCart = GetCart(NormalizeMenu(StateFromCart(MenuManager(MenuConfiguration))));

export default InitializeFromMenu

export { InitializeFromCart }