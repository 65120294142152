import React from 'react';

import { MenuContext } from '../../../../context';
import LoadingComponent from '../../../../components/utils/withLoading';
import RedirectAfter from '../../../../Utils/Router';

import { socialLoginHttp } from '../../HttpServices';

import './style.scss';


const SocialSigninMessage = () => (
  <div className='authn-container'>
    <img src={"/static/img/enter.svg"} alt="enter" />
    <p>
      Vous êtes maintenant connecté à votre compte.
    </p>
  </div>
)

SocialSigninMessage.displayName = "SocialSigninMessage"

function fetchAuthToken(WrappedComponent) {
  return class extends React.Component {
    static contextType = MenuContext

    constructor(props) {
      super(props)

      this.state = {
        loading: true
      }
    }


    componentDidMount() {
      const sut = localStorage.getItem("sut")

      if (!sut)
        return

      return socialLoginHttp(sut)
        .then(response => {
          return this.setState({
            loading: false
          }, () => {
            this.context.socialAuthenticate(response.jwt)
          })
        })
        .catch(() =>
          this.setState({
            error: "Une erreur s'est produite lors de la connexion",
            loading: false
          })
        )
        .catch(() =>
          this.setState({
            error: "Une erreur s'est produite lors de la connexion",
            loading: false
          })
        )
    }

    render() {
      return (
        <LoadingComponent
          loading={this.state.loading}
        >
          <WrappedComponent {...this.props} />
        </LoadingComponent>
      )
    }
  }
}

export default fetchAuthToken(
  RedirectAfter(
    {
      after: 4000,
      to: "/",
    },
    SocialSigninMessage
  )
)
