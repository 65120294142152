import React, { useContext, useState } from "react";
import Modal from "../../../../components/Modal";

import { InitializeFromCart } from "../../../Restaurant/Views/MenuConfig";
import { MODAL_ID } from "../../../Restaurant/Config";
import { __ImageHost__ } from '../../../../Infra/HttpServices';
import { MenuContext } from "../../../../context";
import AllergenesView from "../../../Restaurant/Views/MenuConfig/AllergenesView";
import CartDialog from "../../../../components/CartDialog";
import EditIcon from "../../../../static/img/edit.svg";
import EditCartIcon from "../../../../static/img/edit_cart.svg";
import TrashIcon from "../../../../static/img/trash.svg"
import './style.scss';

// eslint-disable-next-line react/display-name
const NormalizeComposableProduct = (WrappedComponent) => (props) => {
  const { Components, Sections } = props;

  // ********************** Transform Components **********************
  // sort based on section index and remove the base component (the menu itself)
  const newComponents = [...Components];
  newComponents.sort(
    (currentComponent, nextComponent) =>
      currentComponent.SectionIndex - nextComponent.SectionIndex
  );

  const baseIngredient = { ...Components[0] };

  // Remove first component (the menu)
  newComponents.shift();

  // ********************** Transform Sections **********************
  // Remove the base component from sections
  const newSections = [...Sections];
  newSections.shift();

  return (
    <WrappedComponent
      {...props}
      baseIngredient={baseIngredient}
      Components={newComponents}
      Sections={newSections}
    />
  );
};

const SectionButton = ({ Id, Name, onPress }) => (
  <button
    key={Id}
    id={Id}
    onClick={() => onPress()}
    className="cart-section-button"
  >
    <div className="add-text-button">
      <p>+</p>
    </div>
    <p>{Name}</p>
  </button>
);

const Component = (props) => {
  const {
    Id,
    Name,
    Image,
    SectionIndex,
    isEditable,
    isMaxSelection,
    HasCompositions,
    Allergenes,
    compositions,
    onPress,
    LoyaltyPointsRetrait,
    IsLoyaltyApplyed,
    loyaltyPoints
  } = props;
  const [displayAllergenes, setDisplayAllergenes] = useState(false);
  const context = useContext(MenuContext);

  return (
    <div className="product-component-container">
      <div className="component">
        <img width="150px"
          src={`${Image}`}
          alt="product"
          className="component-image"
        />
        <p className="component-name">{Name}</p>
        <div className="perso-container">
          {(isEditable || isMaxSelection === true) && (
            <button
              onClick={() => onPress(Id, SectionIndex, "remove")}
              className="edit-button"
            >
              <img src={EditIcon} alt="edit" />
            </button>
          )}
          {HasCompositions && (
            <button
              onClick={() => onPress(Id, SectionIndex, "edit")}
              className="perso-button"
            >
              <p>
                Personnaliser
              </p>
            </button>
          )}
          {!IsLoyaltyApplyed && LoyaltyPointsRetrait > 0 && (
            <button
              onClick={() => context.applyComponentLoyalty(Id, loyaltyPoints)}
              className="compo-loyalty-button"
            >
              <p>
                {LoyaltyPointsRetrait} pts
              </p>
            </button>
          )}
          {IsLoyaltyApplyed && LoyaltyPointsRetrait > 0 && (
            <button
              onClick={() => context.disApplyComponentLoyalty(Id)}
              className="used-loyalty-button"
            >
              <p>
                {LoyaltyPointsRetrait} pts
              </p>
            </button>
          )}
        </div>
        {Allergenes && Allergenes.length > 0 && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setDisplayAllergenes(true);
            }}
            className="card-button-allergen"
          >
            <p>allergènes</p>
          </button>
        )}
      </div>

      {HasCompositions && (
        <div className="component-compo">
          {compositions.map((composition, i) => {
            return composition.IsDefault ? (
              <p key={i} className="default">{`sans ${composition.Name}`}</p>
            ) : (
              <p key={i}>{`+ ${composition.Name}`}</p>
            );
          })}
        </div>
      )}
      <AllergenesView
        allergenes={Allergenes}
        open={displayAllergenes}
        onClose={() => setDisplayAllergenes(false)}
      />
    </div>
  );
};

const getCompositionAllergenes = (baseAllergenes, compositions) => {
  let alg = [];
  
  if (compositions && compositions.length > 0) {
    compositions.forEach((cmp) => {
      if (cmp.IsDefault) {
        if (cmp.Allergenes && cmp.Allergenes.length > 0) {
          for (var j = 0; j < cmp.Allergenes.length; j++) {
            if (
              !(
                baseAllergenes.filter(
                  (value) => value.Id == cmp.Allergenes[j].Id
                ).length > 0
              )
            ) {
              alg.push(cmp.Allergenes[j]);
            } else {
              baseAllergenes = baseAllergenes.filter(
                (value) => value.Id !== cmp.Allergenes[j].Id
              );
              alg = baseAllergenes;
            }
          }
        }
      } else {
        if (cmp.Allergenes && cmp.Allergenes.length > 0) {
          for (var p = 0; p < cmp.Allergenes.length; p++) {
            alg.push(cmp.Allergenes[p]);
          }
        }
      }
    });
  } else {
    alg = baseAllergenes;
  }

  return alg;
};

const SectionComponents = ({
  components,
  compositions,
  sectionIndex,
  isMaxSelection,
  onPress,
  loyaltyPoints,
  Style
}) => {
  const componentList = components.map((component, index) => {
    return component.SectionIndex === sectionIndex ? (
      <Component
        {...component}
        Style={Style}
        key={`${component.Id}-${index}`}
        isEditable={!component.HasCompositions}
        onPress={onPress}
        isMaxSelection={isMaxSelection}
        compositions={compositions.filter(
          (composition) => composition.ComponentId === component.Id
        )}
        Allergenes={getCompositionAllergenes(
          component.Allergenes,
          compositions.filter(
            (composition) => composition.ComponentId === component.Id
          )
        )}
        LoyaltyPointsPrice={component.LoyaltyPointsPrice}
        LoyaltyPointsRetrait={component.LoyaltyPointsRetrait}
        loyaltyPoints={loyaltyPoints}
      />
    ) : null;
  });
  return componentList;
};

const ComposableProduct = (props) => {
  const {
    Id,
    Name,
    Quantity,
    baseIngredient,
    Components,
    Compositions,
    Sections,
    totalCost,
    incrementQuantity,
    decrementQuantity,
    deleteEntry,
    IsLoyaltyApplyed,
    loyaltyPoints,
    Style
  } = props;
  
  const context = useContext(MenuContext);
  const [cartConfirmationOpen, setCartConfirmationOpen] = useState(false)
  const [initCartOpen, setInitCartOpen] = useState(false)
  const [state, setState] = useState({
    Id: null,
    sectionIndex: null,
    componentId: null,
    displayComposition: null
  });
  const [func, setFunc] = useState(null)

  const showCartConfirmation = (callback) => {
    setFunc(() => callback)
    setCartConfirmationOpen(true)
  }

  const openMenu = (Id, componentId, action, sectionIndex) => {
    // Adjust sectionIndex since we removed the base section
    // TODO: split into sub functions
    if (action === "remove") {
      context.removeComponentFromCart(Id, componentId, (context) => {
        setState({
          Id: Id,
          sectionIndex: sectionIndex
        })
        setInitCartOpen(true);
      });
      return;
    }

    if (action === "edit") {
      setState({
        Id: Id,
        sectionIndex: sectionIndex,
        componentId: componentId,
        displayComposition: componentId && true
      })
      setInitCartOpen(true);
    }

    if (action === "open") {
      setState({
        Id: Id,
        sectionIndex: sectionIndex + 1,
      })
      setInitCartOpen(true);
    }
  };

  const HasCompositions = baseIngredient? baseIngredient.HasCompositions? true: false : false;

  return (
    <div className="product-component-container">
      {/* Product Header */}
      <div className="product-header">
        <p className="product-quantity">
          {Quantity > 1 && Quantity}
        </p>
        <p className="product-title bold">{Name}</p>
        <p className="product-price">{`PRIX : ${totalCost} €`}</p>
        <div className="product-edit">
          {HasCompositions && 
            <button
              onClick={() => openMenu(Id, baseIngredient.Id, "edit", 0)}
            >
              <img src={EditCartIcon} alt="cart" />
            </button>
          }
          <button onClick={() => showCartConfirmation(() => deleteEntry(Id))}>
            <img src={TrashIcon} alt="trash" />
          </button>
        </div>
      </div>

      {/* Product Components */}
      <div className="component-list">
        <div className="section-container">
          {/* Display the base ingredient */}
          <Component
            {...baseIngredient}
            compositions={Compositions.filter(
              (composition) => composition.ComponentId === baseIngredient.Id
            )}
            Allergenes={getCompositionAllergenes(
              baseIngredient.Allergenes,
              Compositions.filter(
                (composition) => composition.ComponentId === baseIngredient.Id
              )
            )}
            onPress={() => openMenu(Id, baseIngredient.Id, "edit", 0)}
            LoyaltyPointsPrice={baseIngredient.LoyaltyPointsPrice}
            LoyaltyPointsRetrait={baseIngredient.LoyaltyPointsRetrait}
            loyaltyPoints={loyaltyPoints}
            Style={Style}
          />

          <div className="used-component">
            {/* Display the rest of ingredients */}
            {Sections.map((section, index) => (
              <div
                key={`${section.Id}-${index}`}
                className="section"
              >
                <SectionComponents
                  Style={Style}
                  sectionIndex={index + 1}
                  components={Components}
                  compositions={Compositions}
                  onPress={(componentId, sectionId, action) =>
                    openMenu(Id, componentId, action, sectionId)
                  }
                  loyaltyPoints={loyaltyPoints}
                  isMaxSelection={Components.find(component => component.SectionIndex === (index + 1)) !== undefined
                    && props.menuNode.Products.find(product => product.Id === section.Id).MaxSelection === 1}
                  // IsLoyaltyApplyed ={IsLoyaltyApplyed}
                  // applyLoyalty ={applyLoyalty}
                  // disApplyLoyalty ={disApplyLoyalty}
                />
              </div>
            ))}
          </div>

          <div className="unused-component">
            {Sections.map((section, index) => (
              <div
                key={`${section.Id}-${index}`}
                className="section"
              >
                {Components.find(component => component.SectionIndex === (index + 1)) !== undefined
                && props.menuNode.Products.find(product => product.Id === section.Id).MaxSelection === 1? null :
                  <SectionButton
                    Id={section.Id}
                    Name={section.Name}
                    onPress={() => openMenu(Id, null, "open", index)}
                    Style={Style}
                  />
                }
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Product controls */}
      <div className="product-controls">
        <button
          onClick={() => decrementQuantity(Id)}
          className="product-quantity-button"
        >
          <p>-</p>
        </button>
        <button
          onClick={() => incrementQuantity(Id)}
          className="product-quantity-button"
        >
          <p>+</p>
        </button>
      </div>

      <Modal
        isOpen={initCartOpen}
        id="Menu"
      >
        <InitializeFromCart
          closeMenu={() => setInitCartOpen(false)}
          cartId={state.Id}
          sectionIndex={state.sectionIndex}
          componentId={state.componentId}
          displayComposition={state.displayComposition}
          onOpen={() => console.log("onOpen")}
          onClose={() => setInitCartOpen(false)}
          isEditing={true}
          context={context}
        />
      </Modal>

      <Modal
        isOpen={cartConfirmationOpen}
        id="Delete"
      >
        <CartDialog
          close={() => setCartConfirmationOpen(false)}
          onConfirm={func}
          message={
            "Supprimer le produit ?"
          }
        />
      </Modal>
    </div>
  );
};

ComposableProduct.displayName = "ComposableProduct";

export default NormalizeComposableProduct(ComposableProduct);
