import React from 'react';
import CloseIcon from "../../static/img/letter-xblack.svg";
import './style.scss';

const Dialog = ({ message, onConfirm, close }) => {
  return (
    <div onClick={() => close()} className="cart-dialog-container">
      <div id="change-mode-dialog" className='dialog-container'>
        <button onClick={() => close()} className="dialog-close-button">
          <img src={CloseIcon} alt="close" />
        </button>
        <p className='dialog-text'>
          {message}
        </p>
        <div className='buttons-container'>
          <button id="cancel-mode" onClick={() => close()} className="dialog-cancel-button">
            <p>
              Annuler
            </p>
          </button>
          <button
            id="confirm-mode-dialog"
            onClick={() => {
              onConfirm()
              close()
            }}
            className="dialog-confirm-button"
          >
            <p>
              Valider
            </p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Dialog;