import React from "react"
import './style.scss';

// TODO: Hook to dimension
const dining = ({ context, setDiningMode }) => {

  return (
    <div className="dining-mode-container">
      {context.DINING_MODES.map((mode, id) => (
        <button
          key={id}
          onClick={() => setDiningMode(mode.id)}
          className={mode.selected ? "active": ""}
        >
          <img src={mode.logo} alt="dining-mode-img" />
          <p>{mode.label}</p>
        </button>
      ))}
    </div>
  )
}

export default dining;
