export default {
  fieldsets: [
    {
      title: "Informations personnelles",
      fields: [
        {
          id: "firstName",
          label: "Prénom *",
        },
        {
          id: "lastName",
          label: "Nom *",
        },
        {
          id: "email",
          label: "Email *",
          editable: false
        },
        {
          id: "phoneNumber",
          label: "Téléphone *"
        }
      ]
    }
  ]
}