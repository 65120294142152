import React from 'react';

import FormHOC from '../../../Utils/Forms'

import { ChangePhoneHttpService } from '../HttpServices'
import PhoneChangeModel from '../Models/PhoneChange'

import ProfileNavigation from './ProfileNavigation'
import AccountStatus from './AccountStatus';

import './style.scss'

class PhoneChange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedField: null,
      formState: {}
    }
  }

  setFieldValue(field, newValue) {
    const newFormState = {
      ...this.state.formState
    }
    newFormState[field] = newValue

    this.setState({ formState: newFormState })
  }

  Submit() {
    this.props.Submit({
      ...this.state.formState,
    })
  }

  render() {
    return (
      <div>
        <AccountStatus />

        <div className='u-Container'>
          <div className='u-Header'>
            <div className='u-NavContainer'>
              <ProfileNavigation navigate={this.props.navigate} />
            </div>

            <div className='u-firstForm'>
              {PhoneChangeModel.map(fieldset =>
                <div key={fieldset.title} className="u-fieldSet">
                  {/* Fieldset Body */}
                  <div className='u-fieldSetBody'>
                    {fieldset.fields.map(field =>
                      <div key={field.id} className="u-field">
                        <input
                          placeholder={field.label}
                          value={this.state[field.id]}
                          onFocus={() => this.setState({ focusedField: field.id })}
                          onChange={(evt) => this.setFieldValue(field.id, evt.target.value)}
                          className="u-input"
                        />

                        {this.props.errors[field.id] &&
                          <div>
                            {this.props.errors[field.id].map((error) =>
                              <p key={error} className="u-error">{error}</p>
                            )}
                          </div>}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='u-formContainer'>
            <div className='u-formContainerDesk'>
              <div className='u-form'>
                {/* Form body */}
                <div>
                  {/* Fieldset */}
                  {PhoneChangeModel.map(fieldset =>
                    <div key={fieldset.title} className="u-fieldSet">
                      {/* Fieldset Header */}
                      <div className='u-fieldSetHeader'>
                        <p className='u-fieldSetHeaderText'>{fieldset.title}</p>
                      </div>

                      {/* Fieldset Body */}
                      <div className='u-fieldSetBody'>
                        {fieldset.fields.map(field =>
                          <div key={field.id} className="u-field">
                            <input
                              placeholder={field.label}
                              value={this.state[field.id]}
                              onFocus={() => this.setState({ focusedField: field.id })}
                              onChange={(evt) => this.setFieldValue(field.id, evt.target.value)}
                              className="u-input"
                            />

                            {this.props.errors[field.id] &&
                              <div>
                                {this.props.errors[field.id].map((error) =>
                                  <p key={error} className="u-error">{error}</p>
                                )}
                              </div>}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {this.props.error &&
                  <div>
                    <p key={this.props.error} className="u-error">{this.props.error}</p>
                  </div>
                }

                <div className='u-formControls'>
                  <button onClick={() => this.Submit()} id="confirm-reset-pwd" className='u-submitButton'>
                    {this.props.loading
                      ? <img src={"/static/img/tail-loading.svg"} alt="loading" className='tl-img' />
                      : <p className='u-submitButtonText'>Envoyer</p>
                    }
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default FormHOC(
  {
    successRedirect: "/payment/method",
    action: ChangePhoneHttpService
  },
  PhoneChange
);