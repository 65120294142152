import React from 'react';
import Modal from '../../../../../components/Modal';

import { MenuContext } from '../../../../../context'

import CardDesc from './CardDescription'

import './style.scss'

class Card extends React.Component {
  static contextType = MenuContext

  constructor(props) {
    super(props);

    this.state = {
      hovering: false,
      openDesc: false
    }
  }

  showCardDescription() {
    this.setState({
      openDesc: true
    })
  }

  render() {
    return (
      <div className={`menuconfig-card ${this.props.selectionCount > 0 ? "card-selected": ""}`}>
        <button
          onClick={this.props.isNext && this.props.isNext === true ? this.props.scrollToTop : this.props.onPress}
        >
          <div className='menuconfig-image-container'>
            <img width="150px" src={this.props.image} alt="product" className='image' />
          </div>

          <div className='text-container'>

            <div>
              <div>
                <p className='title'>{this.props.title}</p>
              </div>
              <div className='price-container'>
                {this.props.price > 0 && (
                  <p className='price'>{this.props.price} €</p>
                )}
                {this.props.allergens &&
                  <div className='allergen-container'>
                    <button 
                      onClick={(e) => {
                        e.stopPropagation()
                        this.props.displayAllergenes(this.props.allergens)
                      }}
                      className="allergen-button"
                    >
                      <p>allergènes</p>
                    </button>
                  </div>
                }
                {this.props.selectionCount > 0 && (
                  <p className='selection-count'>
                    {'x' + this.props.selectionCount}
                  </p>
                )}
                {(this.props.loyaltyPointsRetrait > 0 && this.props.loyaltyPointsPrice > 0) && (
                  <p className='loyalty'>{this.props.loyaltyPointsRetrait} pts = {(this.props.price - this.props.loyaltyPointsPrice).toFixed(2)}€</p>
                )}
              </div>
            </div>
            {!this.props.isNext &&
              <div>
                <p className='add'>Ajouter</p>
              </div>
            }

            {this.props.text &&
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  this.showCardDescription()
                }}
                className="infos-container"
              >
                <img src={require('../../../../../static/img/letter-iblue.png')} alt="info" />
              </div>
            }
          </div>

        </button>

        <Modal
          isOpen={this.state.openDesc}
          id="Desc-modal"
        >
          <CardDesc description={this.props.text} />
        </Modal>
      </div>
    );
  }
}

export default Card;