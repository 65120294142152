import React, { useContext, useEffect } from 'react'

import jwtDecode from 'jwt-decode'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuContext } from '../context';

export function requireAuth(WrappedComponent) {
  const HOC = (props) => {
    const context = useContext(MenuContext);
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
      const isAuthenticated = context.isAuthenticated()

      if (!isAuthenticated) {
        navigate(`/login?redirect=${location}`)
        return context.logout()
      }
    }, []);

    return <WrappedComponent {...props} />
  }

  return HOC;
}

export const validateJWT = (token) => {
  try {
    const decodedToken = jwtDecode(token)
    // Unix timestamps are relative to the year 1970 while Date object timestamps isnt.
    const expires = decodedToken.exp * 1000
    const now = new Date()

    return expires > now.getTime()
  } catch (e) {
    return false
  }
}

export const getAuthToken = async () => {
  const jwt = await localStorage.getItem('jwt') || ''

  if (jwt.length === 0)
    return null

  const isValidToken = validateJWT(jwt)

  if (!isValidToken)
    return null

  return jwt
}