import React from 'react';
import ReactModal from 'react-modal';

import './style.scss';

ReactModal.setAppElement('#root');

const customStyles = {
    overlay: {
      zIndex: 999,
    },
    content: {
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      marginRight: "0",
      padding: "15px",
      border: "none",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      overflow: "visible",
      background: "transparent",
    },
};

const Modal = ({ children, isOpen, id }) => {
    return (
        <ReactModal
            isOpen={isOpen}
            style={customStyles}
            id={id}
            contentLabel={id}
        >
            {children}
        </ReactModal>
    );
};

export default Modal;