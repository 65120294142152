import React, { useContext, useState } from 'react';

import { MenuContext } from '../../context';
import { toast } from 'react-toastify';
import withNavigateHook from '../WithNavigation';
import HomeIcon from "../../static/img/home.svg"
import CloseIcon from "../../static/img/letter-xblack.svg"
import "./style.scss";

const SideMenu = ({ location, navigate, show, setShow }) => {
  const context = useContext(MenuContext);

  const cancelPromotion = () => {
    if (context.remise && context.remise.IdPromo) {
      context.unApplyPromotion()
      toast.info("promotion inappliquée")
    }
  }

  const showCart = () => {
    cancelPromotion()
    context.toggleCart()
  }

  const navigateTo = (to) => {
    navigate(to)
    setShow(false);
  }

  return (
    show && <div onClick={() => setShow(false)} className="side-menu-container">
      <div onClick={(e) => e.stopPropagation()} className="side-menu">
        <div className='sm-first-bloc '>
          <div className='sm-row'>
            <img src={HomeIcon} alt="home" />
            <button onClick={() => setShow(false)}>
              <img src={CloseIcon} alt="close" />
            </button>
          </div>
          <p>" Bienvenue à FIO eat, où vous pouvez vivre des meilleures expériences culinaires. "</p>
        </div>

        <div className='sm-bloc'>
          <p className='title'>Nos services</p>

          {context.jwt ?
            <div>
              <button onClick={() => { navigateTo("/search"); }}>
                <p className={`${location === "/search"? "active": ""}`}>Trouver un restaurant</p>
              </button>
              <button onClick={() => { navigateTo("/user/"); }}>
                <p className={`${location === "/user/"? "active": ""}`}>Mon profil</p>
              </button>
              <button onClick={() => { navigateTo("/user/commande/"); }}>
                <p className={`${location === "/user/commande/"? "active": ""}`}>Mes commandes</p>
              </button>
              <button onClick={() => { navigateTo("/restaurant/favorite/list"); }}>
                <p className={`${location === "/restaurant/favorite/list"? "active": ""}`}>Mes restaurants favoris</p>
              </button>
              <button onClick={() => { navigateTo("/user/fidelite"); }}>
                <p className={`${location === "/user/fidelite"? "active": ""}`}>Fidélité</p>
              </button>
              <button onClick={() => showCart()}>
                <p>Panier</p>
              </button>
            </div>:
            <div>
              <button onClick={() => { navigateTo("/search"); }}>
                <p className={`${location === "/search"? "active": ""}`}>Trouver un restaurant</p>
              </button>
              <button onClick={() => { navigateTo("/login/"); }}>
                <p className={`${location === "/login/"? "active": ""}`}>Connexion</p>
              </button>
              <button onClick={() => { navigateTo("/signup/"); }}>
                <p className={`${location === "/signup/"? "active": ""}`}>Inscription</p>
              </button>
              <button onClick={() => showCart()}>
                <p>Panier</p>
              </button>
            </div>
          }
        </div>

        <div className='sm-bloc'>
          <p className='title'>Informations légales</p>
          <div>
            <button onClick={() => { navigateTo("/legal/tos"); }}>
              <p>Conditions d'utilisation</p>
            </button>
            <button onClick={() => { navigateTo("/legal/pp"); }}>
              <p>Politique de confidentialité</p>
            </button>
          </div>
        </div>

        <div className='sm-bloc-row'>
          <div className='sm-left-side'>
            <p className='title'>Soyez à l'avant-guard !</p>
            <p>Entrez votre email et recevez nos meilleurs offres.</p>
            <div className='email-container'>
              <img src={require('../../static/img/success.png')} alt="success" className='sm-email-icon' />
              <input placeholder="Votre email ici..." />
            </div>
          </div>
          <div className='sm-right-side'>
            <a href="https://fr-fr.facebook.com/frenchinnov.france/" className='sm'>
              <img src={require('../../static/img/facebook.png')} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/french_innov/?hl=fr" className='sm'>
              <img src={require('../../static/img/instagram.png')} alt="instagram" />
            </a>
            <a href="https://fr.linkedin.com/company/frenchinnov" className='sm'>
              <img src={require("../../static/img/linkedin.png")} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
      <div className='dummy-view'></div>
    </div>
  );
}

export default withNavigateHook(SideMenu);