import React, { useState } from 'react'

import Address from '../../../../components/Address';

import { isInLocationRange } from '../../../../Utils/Filters'

import './style.scss'

export const AddressField = ({ fieldValue, setFieldValue, bounds, isRanged, deleteAddress, addressInput, setAddressInput }) => {
  const [error, setError] = useState("")
  const [place, setPlace] = useState(fieldValue)


  const validateLocation = (place) => {   
    const currentLocation = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    }
   
    const isInRange = isInLocationRange(
      bounds.startLocation,
      currentLocation,
      bounds.Radius
    )

    if (!isInRange) {
      isRanged(false);
      return "Le restaurant choisi ne livre pas à cette Adresse"
    }

    isRanged(true)

    return null
  }

  const validateAddressFormat = (place) => {   
    if (!place.address_components.find(adressComponent => adressComponent.types.find(value => value === "administrative_area_level_1"))) {
      return "Adresse incomplète : il faut taper la zone administrative"
    }
    if (!place.address_components.find(adressComponent => adressComponent.types.find(value => value === "locality"))) {
      return "Adresse incomplète : il faut taper la localité"
    }
    if (!place.address_components.find(adressComponent => adressComponent.types.find(value => value === "route"))) {
      return "Adresse incomplète: il faut taper la route"
    }
    if (!place.address_components.find(adressComponent => adressComponent.types.find(value => value === "street_number"))) {
      return "Adresse incomplète : il faut taper le numéro de rue"
    }
    if (!place.address_components.find(adressComponent => adressComponent.types.find(value => value === "postal_code"))) {
      return "Adresse incomplète : il faut taper le code postal"
    }

    return null
  }

  const onPlaceChanged = async () => {
    if (place === null)
      return

    const newPlace = place.getPlace()

    const formatError = validateAddressFormat(newPlace)

    const locationError = validateLocation(newPlace)

    if (formatError)
      setError(formatError)
    else if (locationError)
      setError(locationError)
    else
      setError("")
    
    setAddressInput(newPlace.formatted_address)
    setFieldValue(newPlace)
  }

  return (
    <div className='if-field'>
      <p className='if-fieldLabel'>Autre adresse</p>

      <Address
        onPlaceChanged={() => onPlaceChanged()}
        placeValue={addressInput}
        setPlaceValue={(value) => setAddressInput(value)}
        onLoad={(initialPlace) => setPlace(initialPlace)}
        deleteAddress={() => deleteAddress()}
      />

      {error && <p className='if-fieldError'>{error}</p>}
    </div>
  )
}

export default AddressField;