import React, { useState } from 'react'

import './style.scss'

export const PhoneNumber = ( { fieldValue, setFieldValue, editable } ) => {
  const [error, setError] = useState("")


  const validate = (value) => {
    if (!value) {
      return "Ce champ est obligatoire"
    } else if (!/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i.test(value)) {
      return "Numéro de téléphone invalide"
    }

    return null
  }

  const setPhoneNumber = (value) => {
    const error = validate(value)

    if (error)
      setError(error)
    else
      setError("")

    setFieldValue(value)
  }

  return (
    <div className='if-field'>
      <p className='if-fieldLabel'>Numéro de téléphone</p>

      <input
        readOnly={!editable}
        value={fieldValue}
        placeholder={"Entrez votre numéro de téléphone"}
        onChange={(evt) => setPhoneNumber(evt.target.value)}
        className={`${editable ? "if-fieldInput": "if-fieldInput if-fieldInputDisabled"}`}
      />

      {error && <p className='if-fieldError'>{error}</p>}
    </div>
  )
}

export default PhoneNumber;