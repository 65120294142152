import React, { useState } from 'react'

import './style.scss'

export const Email = ( { fieldValue, setFieldValue } ) => {
  const [error, setError] = useState("")

  const validate = (value) => {
    if (!value) {
      return "Ce champ est obligatoire"
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return "Adresse email invalide"
    }

    return null
  }

  const setEmail = (value) => {   
    const error = validate(value)

    if (error)
      setError(error)
    else
      setError("")

    return setFieldValue(value)
  }

  return (
    <div className='if-field'>
      <p className='if-fieldLabel'>Email</p>

      <div className='if-fieldInputContainer'>
        <input
          value={fieldValue}
          placeholder={"Entrez votre email"}
          onChange={(evt) => setEmail(evt.target.value)}
          className="if-fieldInput"
        />
      </div>

      {error && <p className='if-fieldError'>{error}</p>}
    </div>
  )
}

export default Email;