import React from "react";

import { MenuContext } from "./context";
import { traverseObject, getObjectNames } from "../Utils/RestaurantUtils";

import { toast } from 'react-toastify';

import { BasicUserInfoHttpService } from "../apps/User/HttpServices"

import { getAuthToken, validateJWT } from "../Utils/Auth";

import { BootstrapState } from "./Bootstrap";

import {
  normalizeIntervals,
  getFutureIntervals,
} from "../apps/Restaurant/Modules/Shifts";

import {
  UpdateCartHttpService,
  AddCartHttpService,
  DeleteCartHttpService,
} from "../apps/Cart/HttpServices";
import moment from "moment";

export class MenuProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModeSelected: "",
      DINING_MODES: [
        {
          id: "onspot",
          label: "Sur place",
          logo: require("../static/img/sur_place.png"),
          disabled: false,
          selected: true,
        },
        {
          id: "takeaway",
          label: "A emporter",
          logo: require("../static/img/emporter.png"),
          disabled: false,
          selected: false,
        },
        {
          id: "delivery",
          label: "Livraison",
          logo: require("../static/img/livraison.png"),
          disabled: false,
          selected: false,
        },
      ],
      restaurant: {
        name: "",
        region1: "",
        region2: "",
        region3: "",
        region4: "",
        image: "",
        headerFrColor: "",
        restaurantInfoFrColor: "",
        familiesFrColor: "",
        address: "",
        phoneNumber: "",
        id: "",
        publicStripeKey: "",
        latitude: "",
        longitude: "",
        idMachine: "",
        idPv: "",
        idRestau: "",
        idUser: "",
        deliveryRadius: "",
        menu: null,
        deliveryMenu: null,
        globalMenu: null,
        menuNavigator: [],
        activeMenuNode: [],
        WorkHours: [],
        decalage: null,
        decalageDelivery: null,
        cartThreshold: null
      },
      displayCart: false,
      userMenuVisible: false,
      userPlace: null,
      autocomplete: null,
      activeCartId: null,
      cart: [],
      remise: {
        IdPromo: 0,
        CodePromo: "",
        IsPct: null,
        Mnt: null,
        Pct: null
      },
      cartId: null,
      diningMode: "onspot",
      jwt: "",
      uesrEmail: null,
      isRestaurantAvailable: () => this.isRestaurantAvailable(),
      setActiveRestaurant: (restaurant) => this.setActiveRestaurant(restaurant),
      activeFamily: () => this.activeFamily(),
      getActiveFamily: () => this.getActiveFamily(),
      menuActiveNode: () => this.menuActiveNode(),
      pushNavigation: (node) => this.pushNavigation(node),
      resetNavigation: (initial) => this.resetNavigation(initial),
      verboseNavigation: () => this.verboseNavigation(),
      popNavigation: (index) => this.popNavigation(index),
      addCartEntry: (cart) => this.addCartEntry(cart),
      addCartEntryFromOrders: (cart) => this.addCartEntryFromOrders(cart),
      addCartToDbFromOrders: async () => this.addCartToDbFromOrders(),
      saveCartEntry: (cart) => this.saveCartEntry(cart),
      saveCartEntryFromOrders: (cart) => this.saveCartEntryFromOrders(cart),
      cartCount: () => this.cartCount(),
      removeComponentFromCart: (cartId, componentId, callback) =>
        this.removeComponentFromCart(cartId, componentId, callback),
      resetCart: (callback) => this.resetCart(callback),
      decrementCartEntryQuantity: (cartId) =>
        this.decrementCartEntryQuantity(cartId),
      deleteCartEntry: (cartId) =>
        this.deleteCartEntry(cartId),
      incrementCartEntryQuantity: (cartId) =>
        this.incrementCartEntryQuantity(cartId),
      applyLoyalty: (cartId, loyaltyPoints) =>
        this.applyLoyalty(cartId, loyaltyPoints),
      disApplyLoyalty: (cartId) => this.disApplyLoyalty(cartId),
      applyComponentLoyalty: (cartId, loyaltyPoints) =>
        this.applyComponentLoyalty(cartId, loyaltyPoints),
      disApplyComponentLoyalty: (cartId) =>
        this.disApplyComponentLoyalty(cartId),
      getCartEntryTotalCost: (cartId) => this.getCartEntryTotalCost(cartId),
      getCartEntryTotalRemise: (cartId) => this.getCartEntryTotalRemise(cartId),
      getCartEntryTotalCostWithoutRemise: (cartId) => this.getCartEntryTotalCostWithoutRemise(cartId),
      getCartTotalRemise: () => this.getCartTotalRemise(),
      getCartTotalCost: () => this.getCartTotalCost(),
      getCartTotalPointsApplyed: () => this.getCartTotalPointsApplyed(),
      getCartEntryLoyaltyPoints: (cartId) =>
        this.getCartEntryLoyaltyPoints(cartId),
      getCartTotalLoyaltyPoints: () => this.getCartTotalLoyaltyPoints(),
      applyPromotion: (promotion) => this.applyPromotion(promotion),
      unApplyPromotion: () => this.unApplyPromotion(),
      updateUserPlace: (formattedAddress, coords, callback) =>
        this.updateUserPlace(formattedAddress, coords, callback),
      authenticate: (token) => this.authenticate(token),
      socialAuthenticate: (token) => this.socialAuthenticate(token),
      hydrateAuthentication: async () => await this.hydrateAuthentication(),
      logout: () => this.logout(),
      isAuthenticated: (token) => this.isAuthenticated(token),
      asyncIsAuthenticated: async () => this.asyncIsAuthenticated(),
      setDiningMode: (mode) => this.setDiningMode(mode),
      setPaymentMethod: (method) => this.setPaymentMethod(method),
      toggleUserMenu: () => this.toggleUserMenu(),
      toggleCart: (Id) => this.toggleCart(Id),
      setActiveCartId: (Id, callback) =>
        this.setState({ activeCartId: Id }, () => callback()),
    };
  }

  async componentDidMount() {
    // Context initialization from localstorage
    const initialState = await BootstrapState();

    this.setState(
      {
        ...initialState,
      },
      () => {
        if (initialState.restaurant) {
          this.setState({
            restaurant: {
              ...this.state.restaurant,
              menu: this.filterFamiliesBasedOnDiningMode(this.state.restaurant.globalMenu)
            }
          }, () => {
            this.setActiveNodeChildren();
          })
        }
      }
    );
  }

  toggleUserMenu() {
    this.setState({
      userMenuVisible: !this.state.userMenuVisible,
    });
  }

  toggleCart(Id) {
    this.setState({
      displayCart: !this.state.displayCart,
      activeCartId: Id,
    });
  }

  setDiningMode(mode) {
    this.setState(
      {
        diningMode: mode,
        restaurant: {
          ...this.state.restaurant,
          menuNavigator: [{ index: 0 }],
        },
        DINING_MODES: [
          {
            id: "onspot",
            label: "Sur place",
            logo: require("../static/img/sur_place.png"),
            disabled: this.state.DINING_MODES[0].disabled,
            selected: mode === "onspot", //toggle logic
          },
          {
            id: "takeaway",
            label: "A emporter",
            logo: require("../static/img/emporter.png"),
            disabled: this.state.DINING_MODES[1].disabled,
            selected: mode === "takeaway",
          },
          {
            id: "delivery",
            label: "Livraison",
            logo: require("../static/img/livraison.png"),
            disabled: this.state.DINING_MODES[2].disabled,
            selected: mode === "delivery",
          },
        ],
      },
      () => {
        // dialog only appears if the selected mode is not already selected
        if (this.state.restaurant.id) this.resetNavigation({ index: 0 });
        this.resetCart();
      }
    );
  }

  setPaymentMethod(method) {
    this.setState({
      paymentMethod: method,
    });
  }

  updateUserPlace(formattedAddress, coords, callback) {
    this.setState(
      {
        userPlace: {
          formattedAddress: formattedAddress,
          coords: coords,
        },
      },
      () => callback()
    );
  }

  async hydrateAuthentication(cb) {
    const token = await localStorage.getItem("jwt");

    if (token) await this.authenticate(token);

    cb && cb();
  }

  authenticate(token) {
    localStorage.setItem("jwt", token)
    
    return BasicUserInfoHttpService().then((user) => {
      return new Promise((resolve, reject) => {
        this.setState(
          {
            jwt: token,
            user: user,
          },
          () => resolve(token)
        );
      });
    });
  }

  socialAuthenticate(token) {
    localStorage.setItem("jwt", token)
    localStorage.removeItem("sut")

    return BasicUserInfoHttpService().then((user) => {
      this.setState({
        jwt: token,
        user: user,
      });
    });
  }

  logout() {
    localStorage.removeItem("jwt")
    
    return this.setState({
      jwt: null,
    })
  }

  async asyncIsAuthenticated() {
    const token = await getAuthToken();
    // TODO: Fix race condition with the token prefilling from localstorage
    let baseToken = this.state.jwt;

    if (!token) return validateJWT(baseToken);

    return validateJWT(token);
  }

  isAuthenticated(token) {
    // TODO: Fix race condition with the token prefilling from localstorage
    let baseToken = this.state.jwt;

    if (!token) return validateJWT(baseToken);

    return validateJWT(token);
  }

  setActiveRestaurant(restaurant) {
    let diningMode = "onspot";

    if (
      !restaurant.SupportsOnSpot &&
      !restaurant.SupportsPickUp &&
      !restaurant.SupportsDelivery
    )
      diningMode = "";
    if (
      !restaurant.SupportsOnSpot &&
      restaurant.SupportsPickUp &&
      !restaurant.SupportsDelivery
    )
      diningMode = "takeaway";
    if (
      !restaurant.SupportsOnSpot &&
      !restaurant.SupportsPickUp &&
      restaurant.SupportsDelivery
    )
      diningMode = "delivery";
    if (
      restaurant.SupportsOnSpot &&
      !restaurant.SupportsPickUp &&
      !restaurant.SupportsDelivery
    )
      diningMode = "onspot";

    if (
      restaurant.SupportsOnSpot &&
      restaurant.SupportsPickUp &&
      restaurant.SupportsDelivery
    ) {
      if (this.state.diningMode === "delivery") diningMode = "delivery";
      else if (this.state.diningMode === "takeaway") diningMode = "takeaway";
      else diningMode = "onspot";
    }

    if (
      restaurant.SupportsOnSpot &&
      !restaurant.SupportsPickUp &&
      restaurant.SupportsDelivery
    )
      this.state.diningMode === "delivery"
        ? (diningMode = "delivery")
        : (diningMode = "onspot");
    if (
      restaurant.SupportsOnSpot &&
      restaurant.SupportsPickUp &&
      !restaurant.SupportsDelivery
    )
      this.state.diningMode === "takeaway"
        ? (diningMode = "takeaway")
        : (diningMode = "onspot");
    if (
      !restaurant.SupportsOnSpot &&
      restaurant.SupportsPickUp &&
      restaurant.SupportsDelivery
    )
      this.state.diningMode === "delivery"
        ? (diningMode = "delivery")
        : (diningMode = "takeaway");

    if (this.state.cartDiningMode) {
      diningMode = this.state.cartDiningMode;
    }

    this.setState(
      {
        restaurant: {
          name: restaurant.Name,
          region1: restaurant.Region1,
          region2: restaurant.Region2,
          region3: restaurant.Region3,
          region4: restaurant.Region4,
          image: restaurant.Image,
          headerFrColor: restaurant.HeaderFrColor,
          restaurantInfoFrColor: restaurant.RestaurantInfoFrColor,
          familiesFrColor: restaurant.FamiliesFrColor,

          address: restaurant.Address,
          phoneNumber: restaurant.PhoneNumber,
          IsOpen: restaurant.IsOpen,
          IsStripeActive: restaurant.IsStripeActive,
          IsOnSpotPaymentActive: restaurant.IsOnSpotPaymentActive,
          activeMenuNode: [],
          id: restaurant.Id,
          publicStripeKey: restaurant.StripePublicApiKey,
          latitude: restaurant.Latitude,
          longitude: restaurant.Longitude,
          idMachine: restaurant.IdMachine,
          idPv: restaurant.IdPv,
          idRestau: restaurant.IdRestau,
          idUser: restaurant.IdUser,
          deliveryRadius: restaurant.DeliveryRadius,
          menu: restaurant.MenuJSON ? JSON.parse(restaurant.MenuJSON) : null,
          deliveryMenu: restaurant.MenuJSON
            ? JSON.parse(restaurant.MenuJSON).filter(
              (item) => item.IsDelivrable
            )
            : null,
          globalMenu: restaurant.MenuJSON ? JSON.parse(restaurant.MenuJSON) : null,
          menuNavigator: [{ index: 0 }],
          WorkHours: restaurant.WorkHours,
          decalage: restaurant.Decalage,
          decalageDelivery: restaurant.DecalageDelivery,
          cartThreshold: restaurant.CartThreshold
        },
        DINING_MODES: [
          {
            id: "onspot",
            label: "Sur place",
            logo: require("../static/img/sur_place.png"),
            disabled: !restaurant.SupportsOnSpot,
            selected: diningMode === "onspot",
          },
          {
            id: "takeaway",
            label: "A emporter",
            logo: require("../static/img/emporter.png"),
            disabled: !restaurant.SupportsPickUp,
            selected: diningMode === "takeaway",
          },
          {
            id: "delivery",
            label: "Livraison",
            logo: require("../static/img/livraison.png"),
            disabled: !restaurant.SupportsDelivery,
            selected: diningMode === "delivery",
          },
        ],
        diningMode: diningMode,
      },
      () => {
        if (this.state.restaurant.menu) {
          if (this.state.restaurant.menu.length > 0) {
            this.setState({
              restaurant: {
                ...this.state.restaurant,
                menu: this.filterFamiliesBasedOnDiningMode(this.state.restaurant.globalMenu)
              }
            },
              () => {
                this.setActiveNodeChildren();
              });
          }
        }
      }
    );
  }

  filterFamiliesBasedOnDiningMode(menu) {
    const diningModeMap = {
      onspot: 1,
      takeaway: 2,
    };

    let newMenu = [];

    newMenu = menu.filter(
      (item) =>
        item.TypeEmporteOuSurPlace === 0 ||
        item.TypeEmporteOuSurPlace === diningModeMap[this.state.diningMode]
    )

    return newMenu;
  }

  isRestaurantAvailable() {
    // Not guaranteed it will always be an array, so null/undefined tests first
    if (!this.state.restaurant.WorkHours) return false;

    if (this.state.restaurant.WorkHours.length === 0) return false;

    const intervals = getFutureIntervals(
      normalizeIntervals(this.state.restaurant.WorkHours),
      moment().add(30, "m")
    );

    return intervals.length > 0;
  }

  popNavigation(index) {
    let menuNavigator = this.state.restaurant.menuNavigator;

    const startIndex = index * 2 + 1;

    menuNavigator.splice(startIndex, menuNavigator.length);

    this.setState(
      {
        restaurant: {
          ...this.state.restaurant,
          menu: this.filterFamiliesBasedOnDiningMode(this.state.restaurant.globalMenu),
          menuNavigator: menuNavigator,
        },
      },
      () => this.setActiveNodeChildren()
    );
  }

  resetNavigation(initial) {
    const menuNavigator = [initial];

    this.setState(
      {
        restaurant: {
          ...this.state.restaurant,
          menu: this.filterFamiliesBasedOnDiningMode(this.state.restaurant.globalMenu),
          menuNavigator: menuNavigator,
        },
      },
      () => this.setActiveNodeChildren()
    );
  }

  pushNavigation(node) {
    let menuNavigator = this.state.restaurant.menuNavigator;

    menuNavigator.push(node);
    this.setState(
      {
        restaurant: {
          ...this.state.restaurant,
          menu: this.filterFamiliesBasedOnDiningMode(this.state.restaurant.globalMenu),
          menuNavigator: menuNavigator,
        },
      },
      () => this.setActiveNodeChildren()
    );
  }

  verboseNavigation() {
    if (this.state.diningMode === "delivery") {
      if (this.state.restaurant.deliveryMenu === null) return [""];
      return getObjectNames(
        this.state.restaurant.menuNavigator,
        this.state.restaurant.deliveryMenu
      );
    } else {
      if (this.state.restaurant.menu === null) return [""];
      return getObjectNames(
        this.state.restaurant.menuNavigator,
        this.state.restaurant.menu
      );
    }
  }

  menuActiveNode() {
    if (this.state.diningMode === "delivery") {
      if (
        !this.state.restaurant.deliveryMenu ||
        this.state.restaurant.deliveryMenu.length === 0
      )
        return;
      return traverseObject(
        this.state.restaurant.menuNavigator,
        this.state.restaurant.deliveryMenu
      );
    } else {
      return traverseObject(
        this.state.restaurant.menuNavigator,
        this.state.restaurant.menu
      );
    }
  }

  activeFamily() {
    if (this.state.diningMode === "delivery") {
      return traverseObject(
        [this.state.restaurant.menuNavigator[0]],
        this.state.restaurant.deliveryMenu
      ).Id;
    } else {
      return traverseObject(
        [this.state.restaurant.menuNavigator[0]],
        this.state.restaurant.menu
      ).Id;
    }
  }

  getActiveFamily() {
    const activeFamilyId = this.activeFamily();

    let activeFamily = null;
    if (this.state.diningMode === "delivery") {
      this.state.restaurant.deliveryMenu.forEach((family) => {
        if (family.Id === activeFamilyId) {
          activeFamily = {
            Id: family.Id,
            Name: family.Name,
            Image: family.Image,
          };
        }
      });
    } else {
      this.state.restaurant.menu.forEach((family) => {
        if (family.Id === activeFamilyId) {
          activeFamily = {
            Id: family.Id,
            Name: family.Name,
            Image: family.Image,
          };
        }
      });
    }

    return activeFamily;
  }

  setActiveNodeChildren() {
    let currentMenuNode = this.menuActiveNode();
    let nextMenuNode = [];
    let menuNavigator = this.state.restaurant.menuNavigator;

    if (!currentMenuNode) return;

    if (currentMenuNode.Categories && currentMenuNode.Categories.length) {
      nextMenuNode = currentMenuNode.Categories;
      menuNavigator.push({ key: "Categories" });
    } else if (currentMenuNode.Products && currentMenuNode.Products.length) {
      menuNavigator.push({ key: "Products" });
      nextMenuNode = currentMenuNode.Products;
    } else if (
      currentMenuNode.Components &&
      currentMenuNode.Components.length
    ) {
      menuNavigator.push({ key: "Components" });
      nextMenuNode = currentMenuNode.Components;
    }

    // TODO: do not remove out of stock items, just disable them and display an out of stock icon
    this.setState({
      restaurant: {
        ...this.state.restaurant,
        activeMenuNode: this.filterOufOfStock(
          this.filterMenuBasedOnDiningMode(nextMenuNode)
        ),
        menuNavigator: menuNavigator,
      },
    });
  }

  filterOufOfStock(menu) {
    return menu.filter((item) => {
      return !item.IsOutOfStock;
    });
  }

  filterMenuBasedOnDiningMode(menu) {
    const diningModeMap = {
      onspot: 1,
      takeaway: 2,
    };

    let newMenu = [];

    if (this.state.diningMode === "delivery") {
      newMenu = menu.filter((item) => item.IsDeliverable === true);

      newMenu.map((item) => {
        item.Price = item.PriceDelivery;
        item.LoyaltyPointsPrice = item.LoyaltyPointsPriceDelivery;
        item.LoyaltyPointsProd = item.LoyaltyPointsProdDelivery;
        item.LoyaltyPointsRetrait = item.LoyaltyPointsRetraitDelivery;
        return item;
      });
    } else {
      newMenu = menu.filter(
        (item) =>
          item.TypeEmporteOuSurPlace === 0 ||
          item.TypeEmporteOuSurPlace === diningModeMap[this.state.diningMode]
      );
    }

    return newMenu;
  }

  incrementCartEntryQuantity(cartId) {
    const newCart = this.state.cart.map((entry) => {
      if (entry.Id === cartId) {
        //Disapply loyalty (supports single and composable product)
        if (entry.IsLoyaltyApplyed && entry.IsLoyaltyApplyed === true) {
          this.disApplyLoyalty(cartId);
        }
        if (entry.Components) {
          entry.Components.forEach((item) => {
            if (item.IsLoyaltyApplyed && item.IsLoyaltyApplyed === true)
              this.disApplyComponentLoyalty(item.Id);
          });
        }

        entry.Quantity += 1;
        entry.totalCost = this.getCartEntryTotalCost(entry);
      }

      return entry;
    });

    this.setState(
      {
        cart: newCart,
      },
      async () => {
        await UpdateCartHttpService(
          this.state.cartId,
          newCart,
          this.state.diningMode,
          this.state.remise.IdPromo,
          this.state.remise.CodePromo
        );
        this.applyOffert()
        this.applyComposableOffert()
      }
    );
  }

  applyOffert() {
    const newCart = this.state.cart
    var offers = []

    this.state.cart.forEach(entry => {
      if (entry.OfIsApply) {
        var findItem = offers.find(x => x.IdPcAchatOffert === entry.IdPcAchatOffert === true);
        if (!findItem) {
          offers.push({ IdPcAchatOffert: entry.IdPcAchatOffert, OfNbAc: entry.OfNbAc, OfNbOf: entry.OfNbOf })
        }
      }
    })

    offers.forEach(item => {
      var categoryNbAchete = item.OfNbAc;
      var categoryNbOfr = item.OfNbOf;
      var productOffered = this.state.cart
        .filter((p) => p.IdPcAchatOffert === item.IdPcAchatOffert)
        .sort((entry, nextEntry) => nextEntry.Price - entry.Price)

      var indexNbAchete = 0;
      var indexNbOfr = 0;

      productOffered.forEach(pItem => {
        newCart.map(entry => {
          if (entry.Id === pItem.Id) {
            entry.nbOffered = 0
            entry.totalCost = this.getCartEntryTotalCost(entry);
          }
          return entry;
        })
        var loop = 0;
        var offered = 0;
        while (loop < pItem.Quantity) {
          loop += 1;
          var canSetPromo = false;

          if (indexNbAchete < categoryNbAchete) {
            //
          }
          else {
            if (indexNbOfr < categoryNbOfr) {
              canSetPromo = true;
              offered += 1;
            }
            else {
              //
            }
          }

          if (canSetPromo) {
            newCart.map(entry => {
              if (entry.Id === pItem.Id) {
                entry.nbOffered = offered
                entry.totalCost = this.getCartEntryTotalCost(entry);
              }
              return entry;
            })
          }

          //Increment
          if (indexNbAchete < categoryNbAchete) {
            indexNbAchete += 1;
            indexNbOfr = 0;
          }
          else {
            if (indexNbOfr < categoryNbOfr) {
              indexNbOfr += 1;
            }
          }

          if (indexNbOfr === categoryNbOfr) {
            indexNbAchete = 0;
          }
        }

      })

    })

    this.setState(
      {
        cart: newCart,
      },
      async () => {
        return await UpdateCartHttpService(
          this.state.cartId,
          newCart,
          this.state.diningMode,
          this.state.remise.IdPromo,
          this.state.remise.CodePromo
        );
      }
    );
  }
  applyComposableOffert() {
    const newCart = this.state.cart
    var offers = []
    var productHasOffers = []
    var listProducts = []

    this.state.cart.forEach(entry => {
      if (entry.Type === "composable") {
        entry.Components.forEach(entryComponenent => {
          if (entryComponenent.OfIsApply === true) {
            productHasOffers.push({ ...entryComponenent, Parent: entry.Id })
            listProducts.push(entry)
          }
          var findItem = offers.find(x => x.IdPcAchatOffert === entryComponenent.IdPcAchatOffert && entryComponenent.OfIsApply === true);
          if (!findItem) {
            offers.push({ IdPcAchatOffert: entryComponenent.IdPcAchatOffert, OfNbAc: entryComponenent.OfNbAc, OfNbOf: entryComponenent.OfNbOf })
          }
        })
      }
    })

    newCart.forEach(entry => {
      if (entry.Type === "composable") {
        entry.Mntoffered = 0
        entry.totalCost = this.getCartEntryTotalCost(entry);
      }
    })
    offers.forEach(item => {

      if (item.IdPcAchatOffert === 0) {
        return
      }
      var categoryNbAchete = item.OfNbAc;
      var categoryNbOfr = item.OfNbOf;

      var productOffered = productHasOffers
        .filter((p) => p.IdPcAchatOffert === item.IdPcAchatOffert)
        .sort((entry, nextEntry) => nextEntry.Price - entry.Price)

      var indexNbAchete = 0;
      var indexNbOfr = 0;

      listProducts.forEach(pItem => {
        var mycomponenents = productOffered.filter(it => it.Parent === pItem.Id)
        var loop = 0;
        // var offered = 0;
        var Mntoffered = 0;
        while (loop < pItem.Quantity) {
          loop += 1;
          var canSetPromo = false;

          if (indexNbAchete < categoryNbAchete) {
            //
          }
          else {
            if (indexNbOfr < categoryNbOfr) {
              canSetPromo = true;
              // offered += 1;
            }
            else {
              //
            }
          }


          if (canSetPromo) {
            mycomponenents.forEach(item => {
              newCart.forEach(entry => {
                if (entry.Type === "composable") {
                  entry.Components.map(entryComponenent => {
                    if (item.Id == entryComponenent.Id) {
                      Mntoffered += entryComponenent.Price
                      entry.Mntoffered = Mntoffered * entryComponenent.Quantity
                      entry.totalCost = this.getCartEntryTotalCost(entry)
                    }
                  })
                }
              })
            })
          }

          //Increment
          if (indexNbAchete < categoryNbAchete) {
            indexNbAchete += 1;
            indexNbOfr = 0;
          }
          else {
            if (indexNbOfr < categoryNbOfr) {
              indexNbOfr += 1;
            }
          }

          if (indexNbOfr === categoryNbOfr) {
            indexNbAchete = 0;
          }
        }
      })
    })

    this.setState(
      {
        cart: newCart,
      },
      async () => {
        return await UpdateCartHttpService(
          this.state.cartId,
          newCart,
          this.state.diningMode,
          this.state.remise.IdPromo,
          this.state.remise.CodePromo
        );
      }
    );
  }

  decrementCartEntryQuantity(cartId) {
    // Using filter to support removing an entry
    const newCart = this.state.cart.filter((entry) => {
      // Remove the entry
      if (entry.Id === cartId && entry.Quantity === 1) return null;

      // Update the entry quantity
      if (entry.Id === cartId) {
        entry.Quantity -= 1;
        entry.totalCost = this.getCartEntryTotalCost(entry);
      }

      return entry;
    });

    this.setState(
      {
        cart: newCart,
      },
      async () => {
        await UpdateCartHttpService(
          this.state.cartId,
          newCart,
          this.state.diningMode,
          this.state.remise.IdPromo,
          this.state.remise.CodePromo
        );
        this.applyOffert()
        this.applyComposableOffert()
      }
    );
  }

  deleteCartEntry(cartId) {
    // Using filter to support removing an entry
    const newCart = this.state.cart.filter((entry) => {
      // Remove the entry
      if (entry.Id === cartId) return null;

      return entry;
    });

    this.setState(
      {
        cart: newCart,
      },
      async () => {
        await UpdateCartHttpService(
          this.state.cartId,
          newCart,
          this.state.diningMode,
          this.state.remise.IdPromo,
          this.state.remise.CodePromo
        );
        this.applyOffert()
        this.applyComposableOffert()
      }
    );
  }

  applyLoyalty(cartId, loyaltyPoints) {
    const newCart = this.state.cart.map((entry) => {
      if (entry.Id === cartId) {
        if (loyaltyPoints) {
          var total = this.getCartTotalPointsApplyed() + entry.LoyaltyPointsRetrait * entry.Quantity

          if (total > loyaltyPoints) {
            entry.IsLoyaltyApplyed = false;
          } else {
            if (
              entry.LoyaltyPointsPrice !== 0 &&
              entry.LoyaltyPointsRetrait !== 0
            ) {
              if (entry.LoyaltyPointsPrice >= entry.Price) {
                entry.PcRmsPc = 100;
                entry.PcRmsMt = entry.Price;
              } else {
                var pctCalc = (entry.LoyaltyPointsPrice / entry.Price) * 100
                var pctCalcRound = parseFloat(pctCalc.toFixed(2));
                entry.PcRmsPc = pctCalcRound;

                var reduction = (pctCalcRound * entry.Price) / 100;
                var reductionRound = parseFloat(reduction.toFixed(2))
                entry.PcRmsMt = reductionRound;
              }

              entry.Price -= entry.LoyaltyPointsPrice
              entry.CpFideliteRetrait = entry.LoyaltyPointsRetrait
              entry.CpFidelitePrice = entry.LoyaltyPointsPrice
              entry.CpFideliteProd = entry.LoyaltyPointsProd
              entry.IsLoyaltyApplyed = true
            }
            entry.PcTotalRemisePct = entry.PcRmsPc
            entry.PcTotalRemiseMt = entry.PcRmsMt

            entry.totalCost = this.getCartEntryTotalCost(entry)
          }
        }
      }

      return entry;
    });

    this.setState(
      {
        cart: newCart,
      },
      async () => {
        return await UpdateCartHttpService(
          this.state.cartId,
          newCart,
          this.state.cartDiningMode,
          this.state.remise.IdPromo,
          this.state.remise.CodePromo
        );
      }
    );
  }

  disApplyLoyalty(cartId) {
    const newCart = this.state.cart.map((entry) => {
      if (entry.Id === cartId) {
        entry.Price += entry.LoyaltyPointsPrice;
        entry.CpFideliteRetrait = 0;
        entry.CpFidelitePrice = 0;
        entry.CpFideliteProd = 0;
        entry.PcTotalRemisePct = 0;
        entry.PcTotalRemiseMt = 0;
        entry.PcRmsPc = 0;
        entry.PcRmsMt = 0;
        entry.IsLoyaltyApplyed = false;

        entry.totalCost = this.getCartEntryTotalCost(entry);
      }
      return entry;
    });

    this.setState(
      {
        cart: newCart,
      },
      async () => {
        return await UpdateCartHttpService(
          this.state.cartId,
          newCart,
          this.state.cartDiningMode,
          this.state.remise.IdPromo,
          this.state.remise.CodePromo
        );
      }
    );
  }

  applyComponentLoyalty(cartId, loyaltyPoints) {
    const newCart = this.state.cart.map((entry) => {
      if (entry.Components)
        entry.Components.forEach((item) => {
          if (item.Id === cartId) {
            var total = this.getCartTotalPointsApplyed() + item.LoyaltyPointsRetrait * item.Quantity * entry.Quantity
            if (loyaltyPoints) {
              if (total > loyaltyPoints) {
                entry.IsLoyaltyApplyed = false
              } else {
                if (
                  item.LoyaltyPointsPrice !== 0 &&
                  item.LoyaltyPointsRetrait !== 0
                ) {
                  if (item.LoyaltyPointsPrice >= item.Price) {
                    item.PcRmsPc = 100;
                    item.PcRmsMt = item.Price;
                  } else {
                    var pctCalc = (item.LoyaltyPointsPrice / item.Price) * 100
                    var pctCalcRound = parseFloat(pctCalc.toFixed(2))
                    item.PcRmsPc = pctCalcRound;

                    var reduction = (pctCalcRound * item.Price) / 100
                    var reductionRound = parseFloat(reduction.toFixed(2))
                    item.PcRmsMt = reductionRound
                  }

                  item.Price -= item.LoyaltyPointsPrice
                  item.CpFideliteRetrait = item.LoyaltyPointsRetrait
                  item.CpFidelitePrice = item.LoyaltyPointsPrice
                  item.CpFideliteProd = item.LoyaltyPointsProd
                  item.IsLoyaltyApplyed = true
                }
                item.PcTotalRemisePct = item.PcRmsPc
                item.PcTotalRemiseMt = item.PcRmsMt
              }
            }
          }
        });
      entry.totalCost = this.getCartEntryTotalCost(entry)

      return entry
    });

    this.setState(
      {
        cart: newCart,
      },
      async () => {
        return await UpdateCartHttpService(
          this.state.cartId,
          newCart,
          this.state.cartDiningMode,
          this.state.remise.IdPromo,
          this.state.remise.CodePromo
        );
      }
    );
  }

  disApplyComponentLoyalty(cartId) {
    const newCart = this.state.cart.map((entry) => {
      if (entry.Components)
        entry.Components.forEach((item) => {
          if (item.Id === cartId) {
            item.Price += item.LoyaltyPointsPrice
            item.CpFideliteRetrait = 0
            item.CpFidelitePrice = 0
            item.CpFideliteProd = 0
            item.PcTotalRemisePct = 0
            item.PcTotalRemiseMt = 0
            item.PcRmsPc = 0
            item.PcRmsMt = 0
            item.IsLoyaltyApplyed = false
          }
        });
      entry.totalCost = this.getCartEntryTotalCost(entry);

      return entry;
    });

    this.setState(
      {
        cart: newCart,
      },
      async () => {
        return await UpdateCartHttpService(
          this.state.cartId,
          newCart,
          this.state.cartDiningMode,
          this.state.remise.IdPromo,
          this.state.remise.CodePromo
        );
      }
    );
  }

  getCartTotalPointsApplyed() {
    let total = 0;

    this.state.cart.forEach(
      (entry) => (total += this.getCartEntryTotalApplyed(entry))
    );

    return total;
  }

  getCartEntryTotalApplyed(cart) {
    // Supports both single and composable products
    let total = cart.CpFideliteRetrait || 0;

    if (cart.Components)
      cart.Components.forEach((item) => {
        item.CpFideliteRetrait
          ? (total += item.CpFideliteRetrait * item.Quantity)
          : (total += 0);
      });

    total = total * cart.Quantity

    return total
  }

  applyPromotion(remise) {
    const newCart = this.state.cart.map((entry) => {

      if (remise && remise.IdPromo !== 0) {
        var produitReducion = 0
        if (entry.Price) {
          var reductionProduit = entry.Price * remise.Pct / 100
          var remiseProduitMt = reductionProduit

          entry.PcRmsPc = remise.Pct
          entry.PcRmsMt = remiseProduitMt
        }

        entry.PcTotalRemisePct = entry.PcRmsPc
        entry.PcTotalRemiseMt = entry.PcRmsMt

        entry.OriginalPrice = entry.Price
        produitReducion = entry.PcTotalRemiseMt
        entry.Price = entry.Price - produitReducion

        entry.IsPromoApplied = true

        if (entry.Components) {
          entry.Components.forEach((item) => {
            var componentReduction = 0
            var reductionComponent = item.Price * remise.Pct / 100
            var remiseComponentMt = reductionComponent

            item.PcRmsPc = null
            item.PcRmsMt = null

            item.PcTotalRemisePct = remise.Pct
            item.PcTotalRemiseMt = remiseComponentMt

            item.OriginalPrice = item.Price
            componentReduction = item.PcTotalRemiseMt
            item.Price = item.Price - componentReduction
            item.IsPromoApplied = true

            if (item.Compositions) {
              item.Compositions.forEach((cmp) => {
                if (!cmp.IsDefault) {
                  // var compositionReducion = 0
                  var reductionComposition = cmp.Price * remise.Pct / 100
                  var remiseCompositionMt = reductionComposition

                  cmp.PccTotalRemisePct = remise.Pct
                  cmp.PccTotalRemiseMt = remiseCompositionMt
                  cmp.OriginalPrice = cmp.Price
                  // compositionReducion = cmp.PcTotalRemiseMt
                  cmp.Price = cmp.Price - remiseCompositionMt
                  cmp.IsPromoApplied = true
                }
              });
            }
          });
        }

        if (entry.Compositions) {
          entry.Compositions.forEach((cmp) => {
            if (!cmp.IsDefault) {
              // var compositionReducion = 0
              var reductionComposition = cmp.Price * remise.Pct / 100
              var remiseCompositionMt = reductionComposition

              cmp.PccTotalRemisePct = remise.Pct
              cmp.PccTotalRemiseMt = remiseCompositionMt
              cmp.OriginalPrice = cmp.Price
              // compositionReducion = cmp.PcTotalRemiseMt
              cmp.Price = cmp.Price - remiseCompositionMt
              cmp.IsPromoApplied = true
            }
          });
        }
        entry.totalCost = this.getCartEntryTotalCost(entry)
      }

      return entry
    })

    this.setState(
      {
        cart: newCart,
        remise: remise
      },
      async () => {
        return await UpdateCartHttpService(
          this.state.cartId,
          newCart,
          this.state.cartDiningMode,
          this.state.remise.IdPromo,
          this.state.remise.CodePromo
        );
      }
    );
    return true
  }

  unApplyPromotion() {
    if (this.state.remise && this.state.remise.IdPromo) {
      const newCart = this.state.cart.map((entry) => {
        entry.PcTotalRemisePct = 0
        entry.PcTotalRemiseMt = 0
        entry.PcRmsPc = 0
        entry.PcRmsMt = 0

        entry.Price = entry.OriginalPrice
        entry.IsPromoApplied = false

        if (entry.Components) {
          entry.Components.forEach((item) => {
            item.PcTotalRemisePct = 0
            item.PcTotalRemiseMt = 0
            item.PcRmsPc = 0
            item.PcRmsMt = 0

            item.Price = item.OriginalPrice
            item.IsPromoApplied = false

            if (item.Compositions) {
              item.Compositions.forEach((cmp) => {
                cmp.PccTotalRemiseMt = 0
                cmp.PccTotalRemisePct = 0
                cmp.PcRmsPc = 0
                cmp.PcRmsMt = 0

                cmp.Price = cmp.OriginalPrice
                cmp.IsPromoApplied = false
              });
            }
          });
        }
        if (entry.Compositions) {
          entry.Compositions.forEach((cmp) => {
            cmp.PccTotalRemiseMt = 0
            cmp.PccTotalRemisePct = 0
            cmp.PcRmsPc = 0
            cmp.PcRmsMt = 0

            cmp.Price = cmp.OriginalPrice
            cmp.IsPromoApplied = false
          });
        }
        entry.totalCost = this.getCartEntryTotalCost(entry)

        return entry;
      });


      var remise = {
        IdPromo: 0,
        CodePromo: "",
        IsPct: false,
        Mnt: 0,
        Pct: 0
      }

      this.setState(
        {
          cart: newCart,
          remise: remise
        },
        async () => {
          return await UpdateCartHttpService(
            this.state.cartId,
            newCart,
            this.state.cartDiningMode,
            this.state.remise.IdPromo,
            this.state.remise.CodePromo
          );
        }
      );
    }
  }

  addCartEntry(cartEntry) {
    let updated = false;

    if (!this.isRestaurantAvailable()) return;


    // Increment quantity if the menu already exists
    // TIP: this will fail
    this.state.cart.forEach((entry) => {
      if (entry.Id === cartEntry.Id) {
        updated = true;
        return this.incrementCartEntryQuantity(entry.Id);
      }
    });
    //this.regulariseAchatOffertList(cartEntry);
    //this.regulariseNbOffert();

    if (!updated) {
      const newCart = [
        ...this.state.cart,
        {
          ...cartEntry,
          family: this.getActiveFamily(),
          RestaurantId: this.state.restaurant.id,
          Quantity: 1,
          totalCost: this.getCartEntryTotalCost({ ...cartEntry, Quantity: 1 }),
        },
      ];

      this.setState(
        {
          cart: newCart,
        },
        async () => {
          if (this.state.cartId) {
            await UpdateCartHttpService(
              this.state.cartId,
              newCart,
              this.state.diningMode,
              this.state.remise.IdPromo,
              this.state.remise.CodePromo
            );
            this.applyOffert()
            this.applyComposableOffert()
          }
          else {
            const response = await AddCartHttpService(
              newCart,
              this.state.restaurant.id,
              this.state.diningMode
            );

            if (!response.Id) return;

            this.setState(
              {
                cartDiningMode: this.state.diningMode,
                cartId: response.Id,
                cartRestaurantId: this.state.restaurant.id,
              },
              () => {
                localStorage.setItem("cartId", response.Id)
                this.applyOffert()
                this.applyComposableOffert()

              }
            );
          }
        }
      )
      toast.success('Article ajouté au panier')
    }
  }

  addCartEntryFromOrders(cartEntry) {
    let updated = false;

    if (!this.isRestaurantAvailable()) return;

    // Increment quantity if the menu already exists
    // TIP: this will fail
    this.state.cart.forEach((entry) => {
      if (entry.Id === cartEntry.Id) {
        updated = true;
        return this.incrementCartEntryQuantity(entry.Id);
      }
    });

    if (!updated) {
      const newCart = [
        ...this.state.cart,
        {
          ...cartEntry,
          RestaurantId: this.state.restaurant.id,
          Quantity: 1,
          totalCost: this.getCartEntryTotalCost({ ...cartEntry, Quantity: 1 }),
        },
      ];

      this.setState(
        {
          cart: newCart,
        }
      )
    }
  }

  async addCartToDbFromOrders() {
    const response = await AddCartHttpService(
      this.state.cart,
      this.state.restaurant.id,
      this.state.diningMode
    );

    if (!response.Id) return;

    this.setState(
      {
        cartDiningMode: this.state.diningMode,
        cartId: response.Id,
        cartRestaurantId: this.state.restaurant.id,
      },
      () => localStorage.setItem("cartId", response.Id)
    );
  }

  saveCartEntry(cartEntry) {
    let updated = false;

    if (!this.isRestaurantAvailable()) return;

    let newCart = this.state.cart.map((entry) => {
      if (cartEntry.Id === entry.Id) {
        updated = true;
        return cartEntry;
      }

      return entry;
    });

    if (!updated) {
      newCart = [...this.state.cart, cartEntry];
    }

    this.setState(
      {
        cart: newCart,
      },
      async () => {
        if (this.state.cartId) {
          await UpdateCartHttpService(
            this.state.cartId,
            newCart,
            this.state.diningMode,
            this.state.remise.IdPromo,
            this.state.remise.CodePromo
          )
          this.applyComposableOffert()
        }
        else {
          const response = await AddCartHttpService(
            newCart,
            this.state.restaurant.id,
            this.state.diningMode
          );

          if (!response.Id) return;

          this.setState(
            {
              cartId: response.Id,
              cartRestaurantId: this.state.restaurant.id,
            },
            () => {
              localStorage.setItem("cartId", response.Id)
              this.applyComposableOffert()
            }
          );
        }
      }
    );
  }

  saveCartEntryFromOrders(cartEntry) {
    let updated = false;

    if (!this.isRestaurantAvailable()) return;

    let newCart = this.state.cart.map((entry) => {
      if (cartEntry.Id === entry.Id) {
        updated = true;
        return cartEntry;
      }

      return entry;
    });

    if (!updated) {
      newCart = [...this.state.cart, cartEntry];
    }

    this.setState(
      {
        cart: newCart,
      }
    );
  }

  getCartTotalCost() {
    let cost = 0;

    this.state.cart.forEach(
      (entry) => (cost += this.getCartEntryTotalCost(entry))
    );

    return Math.round(cost * 100) / 100;
  }

  getCartEntryTotalCost(cart) {
    // Supports both single and composable products
    let cost = cart.Price || 0;
    let nbOffered = cart.nbOffered || 0;
    let Mntoffered = cart.Mntoffered || 0;

    if (cart.Components)
      cart.Components.forEach((item) => (cost += item.Price * item.Quantity));

    if (cart.Compositions)
      cart.Compositions.forEach((item) => (cost += item.Price * item.Quantity));

    if (nbOffered !== 0) {
      cost = cost * (cart.Quantity - nbOffered);
    }
    else {
      cost = (cost * cart.Quantity) - Mntoffered;
    }

    // Fix precision
    return Math.round(cost * 100) / 100;
  }

  getCartEntryTotalRemise(cart) {
    // Supports both single and composable products
    let remise = cart.PcTotalRemiseMt || 0;

    if (cart.Components)
      cart.Components.forEach((item) => (remise += item.PcTotalRemiseMt * item.Quantity));

    if (cart.Compositions)
      cart.Compositions.forEach((item) => (remise += item.PccTotalRemiseMt * item.Quantity));

    remise = remise * cart.Quantity;

    // Fix precision
    return Math.round(remise * 100) / 100;
  }

  getCartEntryTotalCostWithoutRemise(cart) {
    // Supports both single and composable products
    let cost = cart.OriginalPrice || 0;

    if (cart.Components)
      cart.Components.forEach((item) => (cost += item.OriginalPrice * item.Quantity));

    if (cart.Compositions)
      cart.Compositions.forEach((item) => (cost += item.OriginalPrice * item.Quantity));

    cost = cost * cart.Quantity;

    // Fix precision
    return Math.round(cost * 100) / 100;
  }

  getCartTotalRemise() {
    let remise = 0;

    this.state.cart.forEach(
      (entry) => (remise += this.getCartEntryTotalRemise(entry))
    );

    return Math.round(remise * 100) / 100;
  }

  getCartTotalLoyaltyPoints() {
    let points = 0;

    this.state.cart.forEach(
      (entry) => (points += this.getCartEntryLoyaltyPoints(entry))
    );

    return points;
  }

  getCartEntryLoyaltyPoints(cart) {
    // Supports both single and composable products
    let points = cart.LoyaltyPointsProd || 0;

    if (cart.Components)
      cart.Components.forEach(
        (item) => (points += item.LoyaltyPointsProd * item.Quantity)
      );

    points = points * cart.Quantity;

    return points;
  }

  cartCount() {
    let count = 0;

    if (this.state.cart.length)
      this.state.cart.forEach((entry) => {
        count += entry.Quantity;
      });

    return count;
  }

  removeComponentFromCart(cartId, componentId, callback) {
    const newCart = this.state.cart.map((entry) => {
      if (entry.Id === cartId) {
        entry.Components = entry.Components.filter(
          (component) => component.Id !== componentId
        );
        entry.Compositions = entry.Compositions.filter(
          (composition) => composition.ComponentId !== componentId
        );
        entry.totalCost = this.getCartEntryTotalCost(entry);

        return {
          ...entry,
        };
      }

      return entry;
    });

    this.setState(
      {
        cart: newCart,
      },
      async () => {
        if (callback) callback(this.state)

        this.applyComposableOffert()

        return await UpdateCartHttpService(
          this.state.cartId,
          newCart,
          this.state.diningMode,
          this.state.remise.IdPromo,
          this.state.remise.CodePromo
        );
      }
    );
  }

  resetCart(callback) {
    this.setState(
      {
        cart: [],
        cartId: null,
        cartRestaurantId: null,
        cartDiningMode: null,
        remise: {
          IdPromo: 0,
          CodePromo: "",
          IsPct: null,
          Mnt: null,
          Pct: null
        }
      },
      async () => {
        if (callback) callback(this.state);

        const cartId = await localStorage.getItem("cartId") ?? "";
        await DeleteCartHttpService(cartId);

        await localStorage.removeItem("cartId");
      }
    );
  }

  render() {
    return (
      <MenuContext.Provider value={this.state}>
        {this.props.children}
      </MenuContext.Provider>
    );
  }
}
