import React from 'react';

import { v4 as uuid } from 'uuid'
import moment from "moment"

import { MenuContext } from '../../../../../context'
import LoadingComponent from '../../../../../components/utils/withLoading'
import { toast } from 'react-toastify'
import Modal from "../../../../../components/Modal"

import { createIntentHttp, confirmIntentHttp, findPromotion, verifPromotionsExistance } from '../../../HttpServices'
import { generateCommand, buildOrderObject } from '../../../HttpServices/Helpers'

import {
  TimeField,
  AddressField,
  EmailField,
  FirstNameField,
  LastNameField,
  DoorField,
  IntercomField,
  DoorCodeField,
  BuildingField,
  StairCaiseField,
  PhoneNumberField,
  PromotionCodeField
} from '../../../Components/InputFields'
import StripeForm from '../../../Components/Stripe'

import { GetAddressHttpService } from '../../../../User/HttpServices';
import { isInLocationRange } from '../../../../../Utils/Filters';
import withNavigateHook from '../../../../../components/WithNavigation';

import CloseIcon from '../../../../../static/img/letter-xblack.svg';

import './style.scss';

// TODO: Add errors object initialization
class DeliveryForm extends React.Component {
  static contextType = MenuContext
  
  constructor(props) {
    super(props);


    this.state = {
      error: "",
      addressError: false,
      commandId: null,
      addressInput: "",
      firstName: "",
      lastName: "",
      email: "",
      addresses: [],
      userAddressSelected: {},
      showAddresses: false,
      address: "",
      phoneNumber: "",
      promotionCode: "",
      promotionExist: false,
      deliveryTime: moment(),
      paymentMethod: "",
      triggerGetPaymentMethod: false,
      clientSecret: null,
      paymentMethodConfirmed: false,
      triggerGetPaymentConfirmation: false,
      success: false,
      submitting: false,
      isInRange: true,
      hover: {
        bool: false,
        buttonId: ""
      },
      addressBorder: {
        addressId: "",
        bool: false
      },
      displayModal: false,
      modalAddress: {},
      defaultAddress: false,
      loyaltySecretKey: "",
      qrCode:""
    }
  }

  componentWillUnmount() {
    if (this.context.remise && this.context.remise.IdPromo) {
      this.cancelPromotion()
    }
  }

  // Prefill user data
  async componentDidMount() {
    verifPromotionsExistance(this.context.cartRestaurantId)
      .then(exist => {
        this.setState({
          promotionExist: exist
        })
      })
    this.setState({
      firstName: this.context.user.firstName,
      lastName: this.context.user.lastName,
      email: this.context.user.email,
      phoneNumber: this.context.user.phoneNumber,
      loyaltySecretKey:this.context.user.loyaltySecretKey,
      qrCode:this.context.user.qrCode
    })

    const address = await GetAddressHttpService();

    if(address.length > 0) {
      this.setState({
        addresses: address,
        showAddresses: true
     })
    }
  }

  setPaymentError(error) {
    return this.setState({
      submitting: false,
      triggerGetPaymentMethod: false,
      triggerGetPaymentConfirmation: false
    })
  }

  validatePhone() {
    if (!this.state.phoneNumber) {
      return "Le numero de telephone est obligatoire"
    } else if (!/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i.test(this.state.phoneNumber)) {
      return "Numéro de téléphone invalide"
    }

    return null
  }

  async validateForm() {
    const phoneErr = this.validatePhone()

    if (phoneErr)
      return phoneErr

    if (!this.state.firstName)
      return "Le prénom est obligatoire"

    if (!this.state.lastName)
      return "Le nom est obligatoire"

    if (!this.state.deliveryTime)
      return "Veuillez choisir quand vous voulez recevoir votre commande"

    if (!this.state.isInRange)
      return "Adresse invalide"

    if (Object.keys(this.state.userAddressSelected).length === 0) {

      if(!this.state.address)
        return "Veuillez saisir une adresse valide"

    } else {

      if(!this.state.userAddressSelected.address)
        return "Veuillez saisir une adresse valide"
        
    }

    var minDeliveryTime = moment().add(this.context.restaurant.decalageDelivery ,"m")    
    if (moment(minDeliveryTime).isAfter(this.state.deliveryTime, 'minutes'))
      return "Temps d'attente dépassé, veuillez modifier l'heure de récupération ou bien rafraichir la page"

    return null
  }

  // Step 1: Create payment method through Stripe
  async initiatePayment() {
    if (this.context.restaurant.cartThreshold > this.context.getCartTotalCost()) {
      return this.setState({
        error: `Le total de la commande doit être supérieur à ${this.context.restaurant.cartThreshold}`
      })
    }

    const error = await this.validateForm()

    if (error)
      return this.setState({
        submitting: false,
        error: error
      })

    this.setState({
      submitting: true,
      error: null,
      triggerGetPaymentMethod: true,
      idempotencyKey: uuid()
    })
  }

  // Step 1.1:
  async storePaymentMethod(paymentMethod) {
    this.setState({
      paymentMethod: paymentMethod,
      triggerGetPaymentMethod: false
    }, async () => await this.initiatePaymentBackchannel())
  }

  isDeliverable(bool) {
    this.setState({ isInRange: bool })
  }

  // Step 2: Initiaite payment from backend and store payment method server side
  async initiatePaymentBackchannel() {
    let remise = this.context.remise.IdPromo !== 0? JSON.stringify({
      IsPct: this.context.remise.IsPct,
      Mnt: this.context.remise.Mnt
    }): null;

    const intentObject = {
      remise: remise,
      paymentMethodId: this.state.paymentMethod.id,
      address: Object.keys(this.state.userAddressSelected).length === 0? this.state.address.formatted_address: this.state.userAddressSelected.address.formatted_address,
      phone: this.state.phoneNumber,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      loyaltySecretKey: this.state.loyaltySecretKey,
      qrCode: this.state.qrCode,
      email: this.state.email,
      PrepareFor: this.state.deliveryTime.toDate(),
      DiningMode: this.context.diningMode,
      restaurantId: this.context.restaurant.id,
      orderSummary: buildOrderObject(this.context.cart),
      orderDetailed: JSON.stringify(this.context.cart),
      codePromo: this.context.remise.CodePromo,
      idempotencyKey: this.state.idempotencyKey,
      // TEMPORARY
      command: JSON.stringify(
        generateCommand(
          this.context.cart,
          this.context.restaurant,
          this.context.remise,
          this.context.getCartTotalCost(),
          this.context.getCartTotalLoyaltyPoints(),
          this.context.getCartTotalPointsApplyed(),
          this.context.diningMode,
          // Client
          {
            FirstName: this.state.firstName.substring(0, 20),
            LastName: this.state.lastName.substring(0, 20),
            Email: this.state.email,
            Phone: this.state.phoneNumber,
            LoyaltySecretKey: this.state.loyaltySecretKey,
            QrCode: this.state.qrCode
          },
          // Address
          Object.keys(this.state.userAddressSelected).length === 0?
          {
            ...this.state.address,
            building: this.state.building,
            intercom: this.state.intercom,
            doorCode: this.state.doorCode,
            stairCaise: this.state.stairCaise,
            door: this.state.door
          }:
          {
            ...this.state.userAddressSelected.address,
            building: this.state.userAddressSelected.building,
            intercom: this.state.userAddressSelected.intercom,
            doorCode: this.state.userAddressSelected.doorCode,
            stairCaise: this.state.userAddressSelected.stairCaise,
            door: this.state.userAddressSelected.door
          }
          ,
          // Decalage
          this.state.deliveryTime.toDate().toLocaleString("en-US")
        )
      )
    }
    const response = await createIntentHttp(intentObject)

    if (response.status === "succeeded") {
      return this.setState({
        paymentMethodConfirmed: true,
        success: true
      }, async () => await this.redirectToSuccessView())
    }

    if (response.error)
      return this.setState({
        error: "Une erreur s'est produite",
        submitting: false
      })

    return this.setState({
      clientSecret: response.clientSecret,
      commandId: response.commandId,
      triggerGetPaymentConfirmation: true
    })
  }

  // Step 3: Confirm payment intent with stripe 3d secure
  async userConfirmPayment(paymentIntentId) {
    return this.setState({
      triggerGetPaymentConfirmation: false
    }, async () => await this.confirmPaymentBackchannel(paymentIntentId))
  }

  // Step 4: Confirm payment intent server side
  async confirmPaymentBackchannel(paymentIntentId) {
    const response = await confirmIntentHttp({ CommandId: this.state.commandId, PaymentIntentId: paymentIntentId, IdempotencyKey: uuid() })

    if (response.error)
      return this.setState({
        error: "Une erreur s'est produite lors de la confirmation de votre paiement",
        submitting: false
      })

    return this.setState({
      paymentMethodConfirmed: true,
      success: true
    }, async () => await this.redirectToSuccessView())
  }

  // Step 5: Redirect to success view
  async redirectToSuccessView() {
    this.context.resetCart()
    return this.props.navigate("/payment/success")
  }

  findPromotionByCode(code) {
    var pctRemiseAllCommand
    return findPromotion(this.context.cartRestaurantId, code)
      .then(promo => {
        if (promo && promo.Id !== 0) {
          if (promo.IsMultiple === false && promo.IsUsed === true) {
            throw new Error('Offre de promotion déjà utilisée')
          }
          else if (promo.Gain === 0) {
            throw new Error('Montant remise incorrect')
          }
          else if (promo.Seuil > 0 && promo.Seuil > this.context.getCartTotalCost()) {
            throw new Error('Pour bénificier de cette promotion, le total de la commande doit être supérieur à ' + promo.Seuil + '€.')
          }
          else if (promo.IsPourcentage === true && promo.Gain > 100) {
            throw new Error('Montant remise supérieur à 100%')
          }
          else if (promo.IsPourcentage === false && promo.Gain > this.context.getCartTotalCost()) {
            throw new Error('Montant remise supérieur au total')
          }

          var totalSansRemise = this.context.getCartTotalCost()
          if (promo.IsPourcentage === true) {
            pctRemiseAllCommand = promo.Gain
          } else {
            pctRemiseAllCommand = (promo.Gain / totalSansRemise) * 100
            pctRemiseAllCommand = Math.round(pctRemiseAllCommand * 100) / 100
          }
          var remise = {
            IdPromo: promo.Id,
            CodePromo: code,
            IsApplied: true,
            IsPct: promo.IsPourcentage,
            Mnt: promo.Gain,
            Pct: pctRemiseAllCommand
          }

          var success = this.context.applyPromotion(remise)
          if (success) {
            toast.success("promotion appliquée avec succès")
          }

        }
        else {
          throw new Error('Code de promotion non valide')
        }
      })
  }

  cancelPromotion() {
    this.context.unApplyPromotion()
    toast.info("promotion inappliquée")
  }

  changeAddress(address) {

    this.setState({
      addressInput: "",
      address: "",
      stairCaise: "",
      door: "",
      doorCode: "",
      intercom: "",
      building: "",
      userAddressSelected: {
        address: JSON.parse(address.Address),
        intercom: address.Interphone !== ""? address.Interphone: "",
        building: address.Batiment !== ""? address.Batiment: "",
        stairCaise: address.Escalier !== ""? address.Escalier: "",
        door: address.Porte !== ""? address.Porte: "",
        doorCode: address.Code !== ""? address.Code: ""
      }
    })
    
    const startLocation = {
      latitude: this.context.restaurant.latitude,
      longitude: this.context.restaurant.longitude
    };
    const currentLocation = {
      latitude: address.GoogleLatitude,
      longitude: address.GoogleLongitude
    };
    const Radius = this.context.restaurant.deliveryRadius;
    const isInRange = isInLocationRange(startLocation, currentLocation, Radius);

    if(!isInRange) {
      this.setState({
        addressError: true,
        isInRange: false
      })
    } else {
      this.setState({
        addressError: false,
        isInRange: true
      })
    }

    this.setState({
      addressBorder: {
        addressId: address.Id,
        bool: true
      }
    })
  }

  DetailsValue(field) {
    return this.state.modalAddress[field] !== undefined && this.state.modalAddress[field] !== ""? this.state.modalAddress[field]: "non renseigné"
  }

  render() {
    return (
      <div className='delivery-form-container'>
        
        {/*************** Modal ****************/}
        <Modal
          isOpen={this.state.displayModal}
          id={"addressDetails"}
        >
          <div onClick={() => this.setState({displayModal: false})} className="delivery-form-modal-container">
            <div className='delivery-form-dialog-container'>
              <button onClick={() => this.setState({displayModal: false})} className='delivery-form-close-container'>
                <img src={CloseIcon} alt="close" className='delivery-form-close-button-icon' />
              </button>
              {Object.keys(this.state.modalAddress).length !== 0 &&
                <div className='delivery-form-modal-details'>
                  <p className='delivery-form-address-name'>{this.DetailsValue("AddressName")}</p>
                  <div>
                    <p><span className='bold'>Adresse : </span> { JSON.parse(this.state.modalAddress.Address).formatted_address }</p>
                    <p><span className='bold'>Bâtiment : </span> {this.DetailsValue("Batiment")}</p>
                    <p><span className='bold'>Interphone : </span> {this.DetailsValue("Interphone")}</p>
                    <p><span className='bold'>Escalier : </span> {this.DetailsValue("Escalier")}</p>
                    <p><span className='bold'>Porte : </span> {this.DetailsValue("Porte")}</p>
                    <p><span className='bold'>Code porte : </span> {this.DetailsValue("CodePorte")}</p>
                  </div>
                </div>
              }
            </div>
          </div>  
        </Modal>
        {/*************** ModalEnd ****************/}

        <StripeForm
          publicApiKey={this.context.restaurant.publicStripeKey}
          setPaymentError={(e) => this.setPaymentError(e)}
          triggerGetPaymentMethod={this.state.triggerGetPaymentMethod}
          getPaymentMethod={(paymentMethod) => this.storePaymentMethod(paymentMethod)}

          triggerGetPaymentConfirmation={this.state.triggerGetPaymentConfirmation}
          confirmPaymentMethod={(paymentIntentId) => this.userConfirmPayment(paymentIntentId)}

          clientSecret={this.state.clientSecret}
        />

        <div className='delivery-form-fieldset'>
          <div className='delivery-form-infos-container'>
            <LastNameField fieldValue={this.state.lastName} setFieldValue={(value) => this.setState({ lastName: value })} />
            <FirstNameField fieldValue={this.state.firstName} setFieldValue={(value) => this.setState({ firstName: value })} />
          </div>
          <EmailField fieldValue={this.state.email} setFieldValue={(value) => this.setState({ email: value })} />
        </div>

        <div className='delivery-form-fieldset'>
          <p className='delivery-form-fieldset-header'>Informations de livraison</p>

          { this.state.showAddresses &&
            <div className='delivery-form-address-cards-container'>
              { this.state.addresses.map( a => {
                return (
                  <div className={`delivery-form-address-card-container ${this.state.addressBorder.addressId === a.Id && this.state.addressBorder.bool && (this.state.isInRange? "address-card-green-border": "address-card-red-border")}`}> 
                    <button
                      onClick={() => this.changeAddress(a)}
                      title="Choisir cette adresse"
                      onHoverStart={() => this.setState({hover: {buttonId: a.Id, bool: true}})}
                      onHoverEnd={() => this.setState({hover: {buttonId: a.Id, bool: false}})}
                    >
                      {a.AddressName !== undefined && a.AddressName !== ""?
                        <p className='address-card-title'> { a.AddressName } </p> :
                        <p className='address-card-title'> Pas de titre </p>
                      }
                      <p> { JSON.parse(a.Address).formatted_address } </p>
                    </button>
                    <button onClick={() => this.setState({displayModal: true, modalAddress: a})} className="delivery-form-details-container">
                      <p className='details'>Plus de details</p>
                    </button>
                  </div>
                )})
              }
              { this.state.addressError &&
                <p className='address-card-error'>Le restaurant choisi ne livre pas à cette adresse</p>
              }
            </div> 
          }

          <div className='delivery-form-address-container'>
            <AddressField
              addressInput={this.state.addressInput}
              setAddressInput={(value) => this.setState({addressInput: value})}
              fieldValue={this.state.address}
              setFieldValue={(value) => this.setState({ address: value })}
              bounds={{
                startLocation: {
                  latitude: this.context.restaurant.latitude,
                  longitude: this.context.restaurant.longitude
                },
                Radius: this.context.restaurant.deliveryRadius
              }}
              isRanged={(bool) => this.isDeliverable(bool)}
              deleteAddress={() => this.setState({addressError: false, address: null, userAddressSelected: {}, addressBorder: {}})}
            />
            
            <p className='delivery-form-details-header'>Informations complémentaires</p>

            <div className='delivery-form-address'>
              <BuildingField fieldValue={this.state.building} setFieldValue={(value) => this.setState({ building: value })} />
              <IntercomField fieldValue={this.state.intercom} setFieldValue={(value) => this.setState({ intercom: value })} />
            </div>
            <div className='delivery-form-address'>
              <DoorCodeField fieldValue={this.state.doorCode} setFieldValue={(value) => this.setState({ doorCode: value })} />
              <StairCaiseField fieldValue={this.state.stairCaise} setFieldValue={(value) => this.setState({ stairCaise: value })} />
              <DoorField fieldValue={this.state.door} setFieldValue={(value) => this.setState({ door: value })} />
            </div>
          </div>

          <div className='delivery-form-phone'>
            <PhoneNumberField fieldValue={this.state.phoneNumber} setFieldValue={(value) => this.setState({ phoneNumber: value })} editable={!this.context.user.phoneNumber} />
            <div className='delivery-form-change-num'>
              <p>Changer votre numéro de téléphone depuis</p>
              <p onClick={() => this.props.navigate("/user/change-phone/")} className="delivery-form-link">ce lien</p>
            </div>
          </div>

          <TimeField
            shifts={this.context.restaurant.WorkHours}
            decalage={this.context.restaurant.decalageDelivery}
            dispatch={(value) => this.setState({ deliveryTime: value })}
          />
        </div>

        {this.state.promotionExist &&
          <PromotionCodeField onValidate={(code) => this.findPromotionByCode(code)} onCancel={() => this.cancelPromotion()}
            fieldValue={this.state.promotionCode} setFieldValue={(value) => this.setState({ promotionCode: value })} editable={this.context.remise.IdPromo !== 0} />
        }

        <div className='form-errors'>
          {this.state.error && <p className='form-error'>{this.state.error}</p>}
        </div>

        <div className='form-controls'>
          <button onClick={(evt) => !this.state.submitting && this.initiatePayment()} disabled={this.state.submitting} className="form-submit-button">
            <LoadingComponent loading={this.state.submitting && !this.state.error}>
              <p className='form-submit-text'>Confirmer</p>
            </LoadingComponent>
          </button>
        </div>
      </div>
    )
  }
}

export default withNavigateHook(DeliveryForm);