import React from 'react';

import { MenuContext } from '../../context'

import './style.scss'

class CardPaymentMethod extends React.Component {
  static contextType = MenuContext

  constructor(props) {
    super(props);

    this.state = {
      hovering: false
    }
  }

  render() {
    return (
      <div className='card-payment-card'>
        <button
          id={this.props.id}
          onClick={this.props.onPress}
          className="cp-flex"
        >
          <div className='cp-infos-container'>
            <img src={this.props.image} alt="payment" className='cp-image' />
            <p className='cp-title'>{this.props.title}</p>
          </div>

          {this.props.text && (
            <p className='cp-desc-text'>
              {this.props.text}
            </p>
          )}

        </button>
      </div>
    );
  }
}

export default CardPaymentMethod;