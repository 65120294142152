import moment, { Moment } from "moment"

// TODO: Fix dining mode initialization logic ( check diningmode id mapping)

// old comments
// Case 1, offset in interval = return [offset, upperBoundary]
// Case 2, offset is lower boundary of an interval = return [offset + 1 hour, upperBoundary]
// Case 3, offset is upper boundary of an interval = return [lowerBoundary, offset - 1 hour]
// end old comments

/*
Case 1: The restaurant has no future hours 
Case 2: Time T is at the start of an interval [T, T1] // missed minutes for last hour 
  Case 2.1: Time T.Minutes is higher than the currentTime.Minutes + decalage *****UNHANDLED***** 
  Case 2.2: Time T.Minutes is higher than the currentTime.Minutes + decalage *****UNHANDLED***** 
Case 3: Time T1 is at the end of an interval [T, T1] // T1 + 1 hour ?? 
  Case 3.1: Time T1.Minutes is higher than the current time's minutes + decalage 
  Case 3.2: Time T1.Minutes is lower than the current time's minutes + decalage *****UNHANDLED***** 
Case 4: Time T2 is in the middle of an interval [T, T1] // bad default time + previous disabled minutes for current hour are enabled? 
*/

// Shifts are saved in different dates, rendering the comparison between moment objects with equality operators not operational.
// This will normalize all dates to today, so moment equality operators work.
export function normalizeIntervals (intervals) {
  const today = moment()
  return intervals.map(interval => ({
    Start: moment(interval.StartTime).year(today.year()).month(today.month()).date(today.date()),
    End: moment(interval.EndTime).year(today.year()).month(today.month()).date(today.date())
  }))
}

// Returns [future, upperBoundary]
export function getFutureIntervals (intervals, offset) {
  // now in [T, T1]
  const currentInterval = intervals.find(interval => interval.Start < offset && offset < interval.End)

  // now lower than [T, T1]
  const futureIntervals = intervals.filter(interval => interval.Start > offset && interval.End > offset)

  // Overrides the current interval's lower boundary
  // T < Now, interval becomes [Now, T1]
  // TODO: Handle case where Now === T1
  if (currentInterval) {
    // We do not handle same hour intervals
    if (offset.hour() === currentInterval.End.hour())
      return [
        ...futureIntervals
      ]

    return [
      {
        ...currentInterval,
        Start: offset
      },
      ...futureIntervals
    ]
  }

  return futureIntervals
}

// Try to get the ongoing
// Fallback to a future interval (if no ongoing interval, there has to be a future one, or else crash)
export function getNearestValidTime (intervals, filter) {
  const result = intervals.find(interval => {
    return (filter >= interval.Start && filter < interval.End)
  })

  if (!result)
    return intervals[0].Start

  return result.Start
}

export const getHours = (intervals) => {
  // Get hours from each interval
  return intervals
    // Map to an array of hours of that interval
    .map(interval => {
      const length = (interval.End.hour() - interval.Start.hour()) + 1
      return [...Array(length).keys()].map(h => h + interval.Start.hour())
    })
    // Reduce to a flat hours list
    .reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
}

export const getMinutes = (intervals, filter) => {
  const allMinutes = [...Array(60).keys()]
  const borderInterval = intervals.find(interval => interval.Start.hour() === filter || interval.End.hour() === filter)

  if (borderInterval && borderInterval.Start.hour() === filter)
    return allMinutes.filter(minute => minute >= borderInterval.Start.minute())

  if (borderInterval && borderInterval.End.hour() === filter)
    return allMinutes.filter(minute => minute < borderInterval.End.minute())

  return allMinutes
}

export function isInIntervals (intervals, value) {
  const interval = intervals.find(interval => interval.Start < value && value < interval.End)

  return interval ? true : false
}
