import React, { useContext } from 'react'
import Modal from '../../../../components/Modal'

import Cart from './Cart'

import { MenuContext } from '../../../../context'

export default (props) => {
  const context = useContext(MenuContext);
  
  return (
    <Modal
      isOpen={context.displayCart}
      id="Panier"
    >
      <Cart {...props} />
    </Modal>
  );
} 