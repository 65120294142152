import React from 'react'

import { v4 as uuid } from 'uuid'
import moment from "moment"

import { MenuContext } from '../../../../../context'
import LoadingComponent from '../../../../../components/utils/withLoading'
import { toast } from 'react-toastify'

import { createIntentHttp, confirmIntentHttp, findPromotion, verifPromotionsExistance } from '../../../HttpServices'
import { generateCommand, buildOrderObject } from '../../../HttpServices/Helpers'

import {
  EmailField,
  FirstNameField,
  LastNameField,
  PhoneNumberField,
  PromotionCodeField
} from '../../../Components/InputFields'
import { TimeField } from '../../../Components/InputFields'

import StripeForm from '../../../Components/Stripe'
import withNavigateHook from '../../../../../components/WithNavigation'

import './style.scss';

// TODO: Add errors object initialization
class TakeAwayForm extends React.Component {
  static contextType = MenuContext

  constructor(props) {
    super(props);

    this.state = {
      error: "",
      commandId: null,
      firstName: "",
      lastName: "",
      email: "",
      promotionCode: "",
      promotionExist: false,
      deliveryTime: moment(),
      paymentMethod: "",
      triggerGetPaymentMethod: false,
      clientSecret: null,
      paymentMethodConfirmed: false,
      triggerGetPaymentConfirmation: false,
      success: false,
      submitting: false,
      loyaltySecretKey: "",
      qrCode:""
    }
  }

  componentWillUnmount() {
    if (this.context.remise && this.context.remise.IdPromo) {
      this.cancelPromotion()
    }
  }

  // Prefill user data
  componentDidMount() {
    verifPromotionsExistance(this.context.cartRestaurantId)
      .then(exist => {
        this.setState({
          promotionExist: exist
        })
      })
    this.setState({
      firstName: this.context.user.firstName,
      lastName: this.context.user.lastName,
      email: this.context.user.email,
      phoneNumber: this.context.user.phoneNumber,
      loyaltySecretKey:this.context.user.loyaltySecretKey,
      qrCode:this.context.user.qrCode
    })
  }

  setPaymentError(error) {
    return this.setState({
      submitting: false,
      triggerGetPaymentMethod: false,
      triggerGetPaymentConfirmation: false
    })
  }

  validatePhone() {
    if (!this.state.phoneNumber) {
      return "Le numero de telephone est obligatoire"
    } else if (!/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i.test(this.state.phoneNumber)) {
      return "Numéro de téléphone invalide"
    }

    return null
  }

  async validateForm() {
    const phoneErr = this.validatePhone()

    if (phoneErr)
      return phoneErr

    if (!this.state.firstName)
      return "Le prénom est obligatoire"

    if (!this.state.lastName)
      return "Le nom est obligatoire"

    if (!this.state.deliveryTime)
      return "Veuillez choisir quand vous voulez recevoir votre commande"

    var minDeliveryTime = moment().add(this.context.restaurant.decalage ,"m")    
    if (moment(minDeliveryTime).isAfter(this.state.deliveryTime, 'minutes'))
      return "Temps d'attente dépassé, veuillez modifier l'heure de récupération ou bien rafraichir la page"

    return null
  }

  // Step 1: Create payment method through Stripe
  async initiatePayment() {
    const error = await this.validateForm()

    if (error)
      return this.setState({
        submitting: false,
        error: error
      })

    this.setState({
      submitting: true,
      error: null,
      triggerGetPaymentMethod: true,
      idempotencyKey: uuid()
    })
  }

  // Step 1.1:
  async storePaymentMethod(paymentMethod) {
    this.setState({
      paymentMethod: paymentMethod,
      triggerGetPaymentMethod: false
    }, async () => await this.initiatePaymentBackchannel())
  }

  // Step 2: Initiaite payment from backend and store payment method server side
  async initiatePaymentBackchannel() {
    let remise = this.context.remise.IdPromo !== 0? JSON.stringify({
      IsPct: this.context.remise.IsPct,
      Mnt: this.context.remise.Mnt
    }): null;

    const intentObject = {
      remise: remise,
      paymentMethodId: this.state.paymentMethod.id,
      phone: this.state.phoneNumber,
      loyaltySecretKey: this.state.loyaltySecretKey,
      qrCode: this.state.qrCode,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      PrepareFor: this.state.deliveryTime.toDate(),
      DiningMode: this.context.diningMode,
      restaurantId: this.context.restaurant.id,
      orderSummary: buildOrderObject(this.context.cart),
      orderDetailed: JSON.stringify(this.context.cart),
      codePromo: this.context.remise.CodePromo,
      idempotencyKey: this.state.idempotencyKey,
      // TEMPORARY
      command: JSON.stringify(
        generateCommand(
          this.context.cart,
          this.context.restaurant,
          this.context.remise,
          this.context.getCartTotalCost(),
          this.context.getCartTotalLoyaltyPoints(),
          this.context.getCartTotalPointsApplyed(),
          this.context.diningMode,
          // Client
          {
            FirstName: this.state.firstName.substring(0, 20),
            LastName: this.state.lastName.substring(0, 20),
            Email: this.state.email,
            Phone: this.state.phoneNumber,
            LoyaltySecretKey: this.state.loyaltySecretKey,
            QrCode: this.state.qrCode
          },
          // Address
          null,
          // Decalage
          this.state.deliveryTime.toDate().toLocaleString("en-US")
        )
      )
    }
    const response = await createIntentHttp(intentObject)

    if (response.status === "succeeded") {
      return this.setState({
        paymentMethodConfirmed: true,
        success: true
      }, async () => await this.redirectToSuccessView())
    }

    if (response.error)
      return this.setState({
        error: "Une erreur s'est produite",
        submitting: false
      })

    return this.setState({
      clientSecret: response.clientSecret,
      commandId: response.commandId,
      triggerGetPaymentConfirmation: true
    })
  }

  // Step 3: Confirm payment intent with stripe 3d secure
  async userConfirmPayment(paymentIntentId) {
    return this.setState({
      triggerGetPaymentConfirmation: false
    }, async () => await this.confirmPaymentBackchannel(paymentIntentId))
  }

  // Step 4: Confirm payment intent server side
  async confirmPaymentBackchannel(paymentIntentId) {
    const response = await confirmIntentHttp({ CommandId: this.state.commandId, PaymentIntentId: paymentIntentId, IdempotencyKey: uuid() })

    if (response.error)
      return this.setState({
        error: "Une erreur s'est produite lors de la confirmation de votre paiement",
        submitting: false
      })

    return this.setState({
      paymentMethodConfirmed: true,
      success: true
    }, async () => await this.redirectToSuccessView())
  }

  // Step 5: Redirect to success view
  async redirectToSuccessView() {
    this.context.resetCart()
    return this.props.navigate("/payment/success")
  }

  findPromotionByCode(code) {
    var pctRemiseAllCommand
    return findPromotion(this.context.cartRestaurantId, code)
      .then(promo => {
        if (promo && promo.Id !== 0) {
          if (promo.IsMultiple === false && promo.IsUsed === true) {
            throw new Error('Offre de promotion déjà utilisée')
          }
          else if (promo.Gain === 0) {
            throw new Error('Montant remise incorrect')
          }
          else if (promo.Seuil > 0 && promo.Seuil > this.context.getCartTotalCost()) {
            throw new Error('Pour bénificier de cette promotion, le total de la commande doit être supérieur à ' + promo.Seuil + '€.')
          }
          else if (promo.IsPourcentage === true && promo.Gain > 100) {
            throw new Error('Montant remise supérieur à 100%')
          }
          else if (promo.IsPourcentage === false && promo.Gain > this.context.getCartTotalCost()) {
            throw new Error('Montant remise supérieur au total')
          }

          var totalSansRemise = this.context.getCartTotalCost()
          if (promo.IsPourcentage === true) {
            pctRemiseAllCommand = promo.Gain
          } else {
            pctRemiseAllCommand = (promo.Gain / totalSansRemise) * 100
            pctRemiseAllCommand = Math.round(pctRemiseAllCommand * 100) / 100
          }
          var remise = {
            IdPromo: promo.Id,
            CodePromo: code,
            IsApplied: true,
            IsPct: promo.IsPourcentage,
            Mnt: promo.Gain,
            Pct: pctRemiseAllCommand
          }

          var success = this.context.applyPromotion(remise)
          if (success) {
            toast.success("promotion appliquée avec succès")
          }

        }
        else {
          throw new Error('Code de promotion non valide')
        }
      })
  }

  cancelPromotion() {
    this.context.unApplyPromotion()
    toast.info("promotion inappliquée")
  }

  render() {
    return (
      <div className='takeaway-form-container'>

        <StripeForm
          publicApiKey={this.context.restaurant.publicStripeKey}
          setPaymentError={(e) => this.setPaymentError(e)}
          triggerGetPaymentMethod={this.state.triggerGetPaymentMethod}
          getPaymentMethod={(paymentMethod) => this.storePaymentMethod(paymentMethod)}

          triggerGetPaymentConfirmation={this.state.triggerGetPaymentConfirmation}
          confirmPaymentMethod={(paymentIntentId) => this.userConfirmPayment(paymentIntentId)}

          clientSecret={this.state.clientSecret}
        />

        <div className='takeaway-form-fieldset'>
          <div className='takeaway-form-infos-container'>
            <LastNameField fieldValue={this.state.lastName} setFieldValue={(value) => this.setState({ lastName: value })} />
            <FirstNameField fieldValue={this.state.firstName} setFieldValue={(value) => this.setState({ firstName: value })} />
          </div>
          <EmailField fieldValue={this.state.email} setFieldValue={(value) => this.setState({ email: value })} />
        </div>

        <div className='takeaway-form-fieldset'>
          <p className='fieldset-header'>Informations de service</p>

          <div className='takeaway-form-phone-container'>
            <PhoneNumberField fieldValue={this.state.phoneNumber} setFieldValue={(value) => this.setState({ phoneNumber: value })} editable={!this.context.user.phoneNumber} />
            <div className='change-num'>
              <p className='change-num-text'>Changer votre numéro de téléphone depuis{" "}</p>
              <p onClick={() => this.props.navigate("/user/change-phone/")} className="onspot-form-link">ce lien</p>
            </div>
          </div>
          <TimeField
            shifts={this.context.restaurant.WorkHours}
            decalage={this.context.restaurant.decalage}
            dispatch={(value) => this.setState({ deliveryTime: value })}
          />
        </div>

        {this.state.promotionExist &&
          <PromotionCodeField onValidate={(code) => this.findPromotionByCode(code)} onCancel={() => this.cancelPromotion()}
            fieldValue={this.state.promotionCode} setFieldValue={(value) => this.setState({ promotionCode: value })} editable={this.context.remise.IdPromo !== 0} />
        }

        <div className='form-errors'>
          {this.state.error && <p className='form-error'>{this.state.error}</p>}
        </div>

        <div className='form-controls'>
          <button onClick={(evt) => !this.state.submitting && this.initiatePayment()} disabled={this.state.submitting} className="form-submit-button">
            <LoadingComponent loading={this.state.submitting && !this.state.error}>
              <p className='form-submit-text'>Confirmer</p>
            </LoadingComponent>
          </button>
        </div>
      </div>
    )
  }
}

export default withNavigateHook(TakeAwayForm);