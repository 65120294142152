import React from 'react';

import { useContext } from 'react';
import withNavigateHook from '../../../components/WithNavigation';
import { MenuContext } from '../../../context';

import './style.scss'

const ProfileNavigation = ({ navigate, locationPathName }) => {
  const redirectTo = (location) => {
    navigate(location)
  }

  const context= useContext(MenuContext);

  return (
    <div className='u-Navigation'>

      {context.jwt && 
        <div className='u-Title'>
          <p className='u-welcomeText'>Bienvenue {context.user.firstName} !</p>
        </div>
      }

      <div className='user-pn'>
        <button onClick={() => redirectTo("/user")} id="go-to-general" className='u-NavigationItem'>
          <p className={locationPathName === "/user" || locationPathName === "/user/" ? "u-NavigationItemLabelActive": "u-NavigationItemLabel"}>Mes informations</p>
        </button>

        <button onClick={() => redirectTo("/user/password/change") } id="go-to-reset-pwd" className='u-NavigationItem'>
          <p className={locationPathName === "/user/password/change" || locationPathName === "/user/password/change/" ? "u-NavigationItemLabelActive": "u-NavigationItemLabel"}>Modifier mon mot de passe</p>
        </button>

        <button onClick={() => redirectTo("/user/email/change")} id="go-to-update-email" className='u-NavigationItem'>
          <p className={locationPathName === "/user/email/change" || locationPathName === "/user/email/change/" ? "u-NavigationItemLabelActive": "u-NavigationItemLabel"}>Modifier mon Email</p>
        </button>
        <button onClick={() => redirectTo("/user/fidelite")} id="go-to-update-email" className='u-NavigationItem'>
          <p className={locationPathName === "/user/fidelite" || locationPathName === "/user/fidelite/"? "u-NavigationItemLabelActive": "u-NavigationItemLabel"}>Fidélité</p>
        </button>
        <button onClick={() => redirectTo("/logout/")} id="logout" className='u-NavigationItem'>
          <p className='u-NavigationItemLabel'>Se déconnecter</p>
        </button>
      </div>
    </div>
  )
}

export default withNavigateHook(ProfileNavigation);
