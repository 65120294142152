import React from "react";

import { Navigate } from "react-router";
import {
  GoogleButton,
  FacebookButton,
} from "../../../../components/SocialButtons";

import { loginHttp } from "../../HttpServices";

import { SignInFormModel } from "../../Models";
import { MenuContext } from "../../../../context";

import { generatePKCE } from "../../../../Utils/generatePKCE";
import { __AuthAPI__ } from "../../../../Infra/HttpServices";
import withNavigateHook from "../../../../components/WithNavigation";

import './style.scss';

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.props.initialState,
      focusedField: this.props.focusedField,
      sut: generatePKCE(),
    };
  }

  setFieldValue(field, newValue) {
    const newState = {};
    newState[field] = newValue;

    this.setState(newState);
  }

  login() {
    this.props.onSubmit(this.state);
  }

  initiateBackchannel() {
    return localStorage.setItem("sut", this.state.sut);
  }

  render() {
    return (
      <div className="signin-container">
        <div className="form">
          {/* Form Header */}
          <div className="formHeader">
            <p className="formHeaderText">Connectez-vous pour profiter de nos meilleures offres de restaurants.</p>
          </div>

          {/* Form body */}
          <div onKeyDown={(e) => e.key === 'Enter' && this.login()} tabIndex="0">
            {/* Fieldset */}
            {this.props.fieldsets.map((fieldset) => (
              <div key={fieldset.title}>
                {/* Fieldset Body */}
                {fieldset.fields.map((field) => (
                  <div className="field" key={field.id}>
                    {this.state[field.id] && (
                      <p className="bold text">{field.label}</p>
                    )}

                    <input
                      className="input"
                      placeholder={field.label}
                      type={field.type}
                      onChange={(evt) =>
                        this.setFieldValue(field.id, evt.target.value)
                      }
                      onFocus={() => this.setState({ focusedField: field.id })}
                    />
                  </div>
                ))}
              </div>
            ))}

            <div className="pwd-container">
              <p className="forgot">
                Mot de passe oublié ?
              </p>
              <button
                onClick={() => this.props.navigate("/user/forgot-password")}
              >
                <p className="underline">
                  Réinitialisez votre mot de passe
                </p>
              </button>
            </div>

            <div>
              {this.props.error && (
                <p
                  className="error"
                  key={this.props.error}
                >
                  {this.props.error}
                </p>
              )}
            </div>
            {this.props.promptResendConfirmationEmail && (
              <div className="mail-container">
                <p className="ta-center">
                  {"Votre compte n'est pas confirmé"}
                </p>
                <button
                  onClick={() => this.props.navigate("/login/resend")}
                >
                  <p className="resend-mail">
                    renvoyer le mail de confirmation?
                  </p>
                </button>
              </div>
            )}
          </div>

          {/* Form controls */}
          <div className="formControls">
            <button
              onClick={() => this.login()}
              className="submitButton"
            >
              <p className="bold">SE CONNECTER</p>
            </button>
            <div className="no-acc-container">
              <p className="text">
                Vous n'avez pas de compte ?
              </p>
              <button
                onClick={() => {
                  // this.props.location.state
                  //   ? this.props.navigate(
                  //       `/signup?redirect=${this.props.location.state.redirect} `,
                  //       { redirect: this.props.location.state.redirect }
                  //     )
                  //   : this.props.navigate("/signup");
                  this.props.navigate("/signup");
                }}
              >
                <p className="underline bold">
                  Rejoignez-nous !
                </p>
              </button>
            </div>
          </div>

          {/* Social login section*/}
          <div className="methodsContainer">
            <div className="fieldSetHeader">
              <p>
                {"Autres méthodes d'inscription"}
              </p>
            </div>

            <div className="auth-container">
              <FacebookButton
                onPress={() => {
                  this.initiateBackchannel();
                  window.open(
                    `${__AuthAPI__}/auth/social/challenge?provider=Facebook&sut=${this.state.sut}`
                  );
                  window.close();
                }}
              />

              <GoogleButton
                onPress={() => {
                  this.initiateBackchannel();
                  window.open(
                    `${__AuthAPI__}/auth/social/challenge?provider=Google&sut=${this.state.sut}`
                  );
                  window.close();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function SignInWithFields(WrappedComponent) {
  return class extends React.Component {
    render() {
      return <WrappedComponent {...this.props} fieldsets={SignInFormModel} />;
    }
  };
}

function SignInWithInitialState(WrappedComponent) {
  return class extends React.Component {
    render() {
      let initialState = {};
      this.props.fieldsets.forEach((fieldset) =>
        fieldset.fields.forEach((field) => (initialState[field.id] = ""))
      );

      return (
        <WrappedComponent
          {...this.props}
          initialState={initialState}
          focusedField={this.props.fieldsets[0].fields[0].id}
        />
      );
    }
  };
}

function SignInWithFetch(WrappedComponent) {
  return class extends React.Component {
    static contextType = MenuContext;

    constructor(props) {
      super(props);

      this.state = {
        redirect: false,
        redirectTo: "/",
        error: "",
      };
    }

    submitData(state) {
      // ToDo: disable form submit until post complete
      let postData = {};

      this.props.fieldsets.forEach((fieldset) =>
        fieldset.fields.forEach(
          (field) => (postData[field.id] = state[field.id])
        )
      );

      return loginHttp(postData)
        .then((data) => {
          // let redirectParam = "";

          if (data.PromptResendConfirmationEmail)
            return this.setState({
              promptResendConfirmationEmail: true,
            });

          // if (this.props.location.state)
          //   redirectParam = this.props.location.state.redirect;

          this.context.authenticate(data.token);
          // this.setState({
          //   redirect: true,
          //   redirectTo: redirectParam,
          // });

          this.props.navigate("/")
        })
        .catch((e) => {
          this.setState({
            error: "Email ou Mot de passe incorrecte",
          });
        });
    }

    render() {
      return this.state.redirect ? (
        <Navigate to={this.state.redirectTo} />
      ) : (
        <WrappedComponent
          {...this.props}
          error={this.state.error}
          promptResendConfirmationEmail={
            this.state.promptResendConfirmationEmail
          }
          onSubmit={(state) => this.submitData(state)}
        />
      );
    }
  };
}

export default withNavigateHook(
  SignInWithFields(
    SignInWithInitialState(SignInWithFetch(SignIn))
  )
);
