import React, { useState, useEffect } from "react"

import moment from "moment"
import TimePicker from "antd/lib/time-picker"
import "antd/lib/time-picker/style/css"

import { normalizeIntervals, getFutureIntervals, getNearestValidTime, getHours, getMinutes, isInIntervals } from '../../../Restaurant/Modules/Shifts'

import './style.scss'

export const Time = ({dispatch, decalage = 15, shifts, format = "HH:mm"}) => {
  const safeDecalage = decalage + 5
  const [error, setError] = useState("")
  const [time, setTime] = useState(moment().add(safeDecalage,"m"))
  const [intervals, setIntervals] = useState([])

  useEffect(() => {
    let normalizedIntervals = normalizeIntervals(shifts)

    let futureIntervals = getFutureIntervals(normalizedIntervals, time)

    const futureTime = getNearestValidTime(futureIntervals, time)

    setIntervals(futureIntervals)
    setTime(futureTime)
    dispatch(futureTime)
  }, [])

  const validate = (value) => {
    if (!isInIntervals(intervals, value))
      throw new Error("Veuillez choisir un décalage dans les temps fournis")

    return value
  }

  const setTimePicker = (value) => {
    try {
      validate(value)
      setTime(value)
      dispatch(value)
      setError("")
    } catch (e) {
      setError(e.message)
    }
  }

  const getDisabledHours = () => {
    const allHours = [...Array(24).keys()]
    const activehours = getHours(intervals)

    // Filter disabled hours
    return allHours.filter(disabledHour => !activehours.includes(disabledHour))
  }

  const getDisabledMinutes = (hour) => {
    const allMinutes = [...Array(60).keys()]
    const activeMinutes = getMinutes(intervals, hour)

    return allMinutes.filter(minute => !activeMinutes.includes(minute))
  }
  return (
    <div className="if-field if-fieldTiny">
      <p className="if-fieldLabel">Récupérer à</p>
      <TimePicker
        defaultValue={time}
        value={time}
        onChange={(value) => setTimePicker(value)}
        onSelect={(value) => setTimePicker(value)}
        format={format}
        minuteStep={10}
        disabledHour={getDisabledHours()}
        disa
        disabledTime={() => {
          return {
            disabledHours: () => getDisabledHours(),
            disabledMinutes: () => getDisabledMinutes()
          }
        }}
        hideDisabledOptions={true}
        allowClear={false}
        suffixIcon={
          <img
            src={require("../../../../static/img/down-arrow.png")}
            alt="arrow"
            className="if-img"
          />
        }
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          border: "none",
          cursor: "pointer"
        }}
        showNow={false}
        
      />
      {error && <p className="if-fieldError">{error}</p>}
    </div>
  )
}

export default Time;