import React from 'react'

import { MenuContext } from '../../../../context'

import LoadingComponent from '../../../../components/utils/withLoading'

import { getLegalDocs } from '../../HttpServices'

import './style.scss';

class PPDocument extends React.Component {
  render() {
    return (
      <div className='legal-container'>
        <iframe id="pp" title="pp" srcDoc={this.props.document} />
      </div>
    )
  }
}

function FetchToSDocument(WrappedComponent) {
  return class extends React.Component {
    static contextType = MenuContext
    constructor(props) {
      super(props)

      this.state = {
        document: null,
        loading: true,
        error: undefined,
      }
    }

    componentDidMount() {
      this.getDocument()
        .then(document => this.setState({
          document: document,
          loading: false,
          error: null
        }))
        .catch(err => this.setState({error: err.message}))
    }
    async getDocument() {
      const legalDocs = await getLegalDocs()

      return legalDocs.PrivacyPolicy
    }

    render() {
      return (
        <LoadingComponent loading={this.state.loading}>
          <WrappedComponent
            {...this.props}
            error={this.state.error}
            document={this.state.document}
          />
        </LoadingComponent>
      )
    }
  }
}

export default FetchToSDocument(PPDocument);