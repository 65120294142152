import React from 'react'

import Delivery from './Delivery/Delivery'
import OnSpot from './OnSpot/OnSpot'
import TakeAway from './TakeAway/TakeAway'


export default (props) => {
  if (props.order.DiningMode === 0)
    return <TakeAway order={props.order} />
  
  if (props.order.DiningMode === 1)
    return <OnSpot order={props.order} />

  if (props.order.DiningMode === 2)
    return <Delivery order={props.order} />

  return null
}