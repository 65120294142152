import React, { useContext } from 'react'

import { MenuContext } from '../../../../context'

import AnonymousPaymentView from './Anonymous'
import AuthenticatedPaymentView from './Authenticated'

export default (props) => {
  const context = useContext(MenuContext)
  const authenticated = context.isAuthenticated()

  if (!authenticated)
    return <AnonymousPaymentView {...props} />

  return <AuthenticatedPaymentView {...props} />
}