export default {
  fieldsets: [
    {
      title: "Changer vote adresse Email",
      fields: [
        {
          id: "NewEmail",
          label: "Nouveau Email *",
        },
        {
          id: "Password",
          label: "Mot de passe *",
          type: "password"
        }
      ]
    }
  ]
}