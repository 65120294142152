import React from 'react';

import './style.scss'

class PasswordResetCallback extends React.Component {
  render() {
    return (
      <div className='um-container'>
        <p className='um-text'>Votre mot de passe a été réinitialisé.</p>
      </div>
    );
  }
}

export default PasswordResetCallback
