import React from 'react';

import { MenuContext } from '../../../../../context'
import LoadingComponent from '../../../../../components/utils/withLoading'
import { toast } from 'react-toastify'

import { v4 as uuid } from 'uuid'

import { createIntentHttp, findPromotion, verifPromotionsExistance } from '../../../HttpServices'
import { generateCommand, buildOrderObject } from '../../../HttpServices/Helpers'

import {
  EmailField,
  FirstNameField,
  LastNameField,
  PhoneNumberField,
  PromotionCodeField
} from '../../../Components/InputFields'
import withNavigateHook from '../../../../../components/WithNavigation';

import './style.scss';

// TODO: Add errors object initialization
class CashForm extends React.Component {
  static contextType = MenuContext
  /**
   *
   */
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      commandId: null,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      promotionCode: "",
      promotionExist: false,
      success: false,
      submitting: false,
      idempotencyKey: uuid(),
      loyaltySecretKey: "",
      qrCode:""
    }
  }

  componentWillUnmount() {
    if (this.context.remise && this.context.remise.IdPromo) {
      this.cancelPromotion()
    }
  }

  // Prefill user data
  componentDidMount() {
    verifPromotionsExistance(this.context.cartRestaurantId)
      .then(exist => {
        this.setState({
          promotionExist: exist
        })
      })
    this.setState({
      firstName: this.context.user.firstName,
      lastName: this.context.user.lastName,
      email: this.context.user.email,
      phoneNumber: this.context.user.phoneNumber,
      loyaltySecretKey:this.context.user.loyaltySecretKey,
      qrCode:this.context.user.qrCode
    })
  }

  validatePhone() {
    if (!this.state.phoneNumber) {
      return "Le numero de telephone est obligatoire"
    } else if (!/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i.test(this.state.phoneNumber)) {
      return "Numéro de téléphone invalide"
    } else return this.setState({
      error: ""
    })
  }
  async validateForm() {
    const phoneErr = this.validatePhone()

    if (phoneErr)
      return phoneErr

    if (!this.state.firstName)
      return "Le prénom est obligatoire"

    if (!this.state.lastName)
      return "Le nom est obligatoire"

    return null
  }

  // Step 2: Initiaite payment from backend and store payment method server side
  async initiatePayment() {
    const error = await this.validateForm()

    if (error)
      return this.setState({
        submitting: false,
        error: error
      })
    this.setState({
      submitting: true,
      idempotencyKey: uuid()
    }, async () => {
      const command = generateCommand(
        this.context.cart,
        this.context.restaurant,
        this.context.remise,
        this.context.getCartTotalCost(),
        this.context.getCartTotalLoyaltyPoints(),
        this.context.getCartTotalPointsApplyed(),
        this.context.diningMode,
        // Client
        {
          FirstName: this.state.firstName.substring(0, 20),
          LastName: this.state.lastName.substring(0, 20),
          Email: this.state.email,
          Phone: this.state.phoneNumber,
          LoyaltySecretKey:this.state.loyaltySecretKey,
          QrCode:this.state.qrCode
        },
        // Address
        null,
        // Decalage
        new Date(this.state.deliveryTime).toLocaleString("en-US")
      )

      let remise = this.context.remise.IdPromo !== 0? JSON.stringify({
        IsPct: this.context.remise.IsPct,
        Mnt: this.context.remise.Mnt
      }): null;

      const intentObject = {
        remise: remise,
        phone: this.state.phoneNumber,
        firstName: this.context.user.firstName,
        lastName: this.context.user.lastName,
        email: this.state.email,
        DiningMode: this.context.diningMode,
        restaurantId: this.context.restaurant.id,
        orderSummary: buildOrderObject(this.context.cart),
        orderDetailed: JSON.stringify(this.context.cart),
        payLater: true,
        loyaltySecretKey: this.state.loyaltySecretKey,
        qrCode: this.state.qrCode,
        idempotencyKey: this.state.idempotencyKey,
        // TEMPORARY
        command: JSON.stringify(
          {
            ...command,
            Sp: true
          }
        )
      }
      const response = await createIntentHttp(intentObject)

      if (response.QrCode) {
        return this.setState({
          success: true
        }, async () => await this.redirectToSuccessView())
      }

      if (response.error)
        return this.setState({
          error: response.error,
          submitting: false
        })
    })
  }

  // Step 3: Redirect to success view
  async redirectToSuccessView() {
    this.context.resetCart()
    return this.props.navigate("/payment/cash/success")
  }

  findPromotionByCode(code) {
    var pctRemiseAllCommand
    return findPromotion(this.context.cartRestaurantId, code)
      .then(promo => {
        if (promo && promo.Id !== 0) {
          if (promo.IsMultiple === false && promo.IsUsed === true) {
            throw new Error('Offre de promotion déjà utilisée')
          }
          else if (promo.Gain === 0) {
            throw new Error('Montant remise incorrect')
          }
          else if (promo.Seuil > 0 && promo.Seuil > this.context.getCartTotalCost()) {
            throw new Error('Pour bénificier de cette promotion, le total de la commande doit être supérieur à ' + promo.Seuil + '€.')
          }
          else if (promo.IsPourcentage === true && promo.Gain > 100) {
            throw new Error('Montant remise supérieur à 100%')
          }
          else if (promo.IsPourcentage === false && promo.Gain > this.context.getCartTotalCost()) {
            throw new Error('Montant remise supérieur au total')
          }


          var totalSansRemise = this.context.getCartTotalCost()
          if (promo.IsPourcentage === true) {
            pctRemiseAllCommand = promo.Gain
          } else {
            pctRemiseAllCommand = (promo.Gain / totalSansRemise) * 100
            pctRemiseAllCommand = Math.round(pctRemiseAllCommand * 100) / 100
          }
          var remise = {
            IdPromo: promo.Id,
            CodePromo: code,
            IsApplied: true,
            IsPct: promo.IsPourcentage,
            Mnt: promo.Gain,
            Pct: pctRemiseAllCommand
          }

          var success = this.context.applyPromotion(remise)
          if (success) {
            toast.success("promotion appliquée avec succès")
          }

        }
        else {
          throw new Error('Code de promotion non valide')
        }
      })
  }

  cancelPromotion() {
    this.context.unApplyPromotion()
    toast.info("promotion inappliquée")
  }

  render() {
    return (
      <div onKeyPress={event => event.key === 'Enter' && this.initiatePayment()} className="cash-form-container">

        <div className='cash-fieldset'>
          <p className='cash-fieldset-header'>Informations pour récupérer votre commande</p>
          <div className='cash-infos-container'>
            <LastNameField fieldValue={this.state.lastName} setFieldValue={(value) => this.setState({ lastName: value })} />
            <FirstNameField fieldValue={this.state.firstName} setFieldValue={(value) => this.setState({ firstName: value })} />
          </div>
          <EmailField fieldValue={this.state.email} setFieldValue={(value) => this.setState({ email: value })} />
          <div className='cash-phone-container'>
            <PhoneNumberField fieldValue={this.state.phoneNumber} setFieldValue={(value) => this.setState({ phoneNumber: value })} editable={!this.context.user.phoneNumber} />

            <div className='change-num-container'>
              <p className='text'>Changer votre numéro de téléphone depuis </p>
              <p onClick={() => this.props.navigate("/user/change-phone/")} className="link">ce lien</p>
            </div>
          </div>

          {this.state.promotionExist &&
            <PromotionCodeField onValidate={(code) => this.findPromotionByCode(code)} onCancel={() => this.cancelPromotion()}
              fieldValue={this.state.promotionCode} setFieldValue={(value) => this.setState({ promotionCode: value })} editable={this.context.remise.IdPromo !== 0} />
          }
        </div>

        <div className='form-errors'>
          {this.state.error && <p className='form-error'>{this.state.error}</p>}
        </div>

        <div className='form-controls'>
          <button onClick={(evt) => !this.state.submitting && this.initiatePayment()} disabled={this.state.submitting} className="form-submit-button">
            <LoadingComponent loading={this.state.submitting && !this.state.error}>
              <p className='form-submit-button-text'>Confirmer</p>
            </LoadingComponent>
          </button>
        </div>
      </div>
    )
  }
}

export default withNavigateHook(CashForm);