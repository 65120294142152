import React from 'react'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function RedirectAfter ({after, to}, WrappedComponent) {
  const HOC = (props) => {
    const navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
      let currentLocation = location

      setTimeout(() => {
        if (currentLocation === location)
          navigate(to)
      }, after)
    }, [])

    return (
      <WrappedComponent {...props} />
    )
  }

  return HOC;
}