/*
  A reducer that takes in an object and traverses it useing path instructions
  returns the last object after traversal
*/
export const traverseObject = (path, object) => {
  return path.reduce((accumulator, currentNode) => {
    if (currentNode.hasOwnProperty('index'))
      return accumulator[currentNode.index]

    if (currentNode.hasOwnProperty('key'))
      return accumulator[currentNode.key]
  }, object)
}

/*
  A reducer that takes in an object and traverses it useing path instructions
  returns traversed object Name properties
*/
export const getObjectNames = (path, object) => {
  let objAccumulator = object
  let names = []

  path.forEach((currentNode) => {

    if (currentNode.hasOwnProperty('index')) {
      objAccumulator = objAccumulator[currentNode.index]
      if (objAccumulator.Name)
        names.push(objAccumulator.Name)
    }

    if (currentNode.hasOwnProperty('key')) {
      objAccumulator = objAccumulator[currentNode.key]
      if (objAccumulator.Name)
        names.push(objAccumulator.Name)
    }

  })
  return names
}