import React from "react";
import FacebookIcon from '../../static/img/facebook.svg';
import GoogleIcon from '../../static/img/google.png';
import './style.scss';

export const GoogleButton = ({ onPress }) => (
  <button onClick={onPress} className="googleButton">
    <img src={GoogleIcon} alt="google-icon" />
    <p>
      Continuer avec Google
    </p>
  </button>
);

export const FacebookButton = ({ onPress }) => (
  <button onClick={onPress} className="facebookButton">
    <img src={FacebookIcon} alt="facebook-icon" />
    <p>
      Continuer avec Facebook
    </p>
  </button>
);
