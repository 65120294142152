import React, { useContext, useState } from 'react';

import { MenuContext } from '../../../../context'
import LoadingComponent from '../../../../components/utils/withLoading'

import Modal from "../../../../components/Modal";

import CartEntries from './CartEntries';
import { getLoyaltyDetails } from '../../HttpServices';

import { getRestaurant } from '../../../Restaurant/HttpServices';
import withNavigateHook from '../../../../components/WithNavigation';
import CloseIcon from '../../../../static/img/letter-xblack.svg'

import './style.scss';

const CartView = ({ loyaltyPoints, navigate }) => {
  const context = useContext(MenuContext)
  const [activeCartFamilyId, setActiveCartFamilyId] = useState(null)
  const [displayMessage, setDisplayMessage] = useState(false)
  const [cartThreshold, setCartThreshold] = useState("")
  const isAuthenticated = context.isAuthenticated()

  if (context.activeCartId && !activeCartFamilyId) {
    context.cart.find(entry => {
      if (entry.Id === context.activeCartId) {
        setActiveCartFamilyId(entry.family.Id)
      }
    })
  }

  const getVerboseTotalCost = () => {
    return `${context.getCartTotalCost()} €`
  }
  
  const getVerboseTotalPoints = () => {
    return `${context.getCartTotalPointsApplyed()} points`
  }

  const exitCart = () => {
    if (activeCartFamilyId) {
      return context.setActiveCartId(null, () => setActiveCartFamilyId(null))
    }

    context.toggleCart()
  }

  const payCart = () => {
    let totalCost = context.getCartTotalCost()

    if(context.diningMode === "delivery") {
      
      getRestaurant(context.cart[0].RestaurantId).then((restaurant) => {
        
        if(totalCost < restaurant.CartThreshold) {
          setCartThreshold(restaurant.CartThreshold)
          setDisplayMessage(true);
        }else {
          navigate("/payment/method")
          context.toggleCart()
        }

      })
    }else {

      navigate("/payment/method")
      context.toggleCart()

    }
  }
  
  return (
    <div className='cart-container'>
      <div className='cart-header'>
        <p className='header-title'>PANIER</p>
        <button
          onClick={() => {
            exitCart()
          }}
          id="exit-basket"
          className='header-button'
        >
          <img src={CloseIcon} alt="close" className='header-close-icon' />
          <p className='header-text'>Quitter le panier</p>
        </button>
        <button
          onClick={() => {
            exitCart()
          }}
          id="exit-basket"
          className='header-button-mb'
        >
          <img src={CloseIcon} alt="close" />
          <p className='header-text'>QUITTER</p>
        </button>
        {!loyaltyPoints &&
          <p className='loyalty-total'>Vous avez 0 points</p>
        }
        {loyaltyPoints > 0 &&
          <p className='loyalty-total'>Vous avez {loyaltyPoints} points</p>
        }
        {context.getCartTotalPointsApplyed() > 0 &&
          <p className='loyalty-total'>Utilisés : {getVerboseTotalPoints()}</p>
        }
        {!isAuthenticated &&
          <p className='connect'>Vous voulez gagner des points? connectez vous et profitez des remise !</p>
        }
      </div>

      {context.cart.length > 0 &&
        <div className='products-container'>
          <CartEntries setActiveCartFamilyId={setActiveCartFamilyId} loyaltyPoints={loyaltyPoints} />
        </div>
      }

      {context.cart.length > 0 &&
        <div className='cart-footer'>
          <button onClick={() => {context.resetCart(); exitCart()}} id="empty-basket" className='reset-cart-button'>
            <img src={require("../../../../static/img/empty_cart.png")} alt="empty" />
            <p>Vider le panier</p>
          </button>

          {context.getCartTotalCost() > 0 &&
            <p className='total-cost'>Total : {getVerboseTotalCost()}</p>
          }

          <button onClick={() => payCart()} id="confirm-order" className='pay-cart-button'>
            <p className='pay-cart-text'>VALIDER MA COMMANDE</p>
          </button>

          <button onClick={() => payCart()} id="confirm-order" className='pay-cart-button-mb'>
            <p className='pay-cart-text'>COMMANDER</p>
          </button>
        </div>
      }

      <Modal
        isOpen={displayMessage}
        id={"ThresholdMessage"}
      >
        <div onClick={() => setDisplayMessage(false)} className="message-container">
          <div className='dialog-container'>
            <button onClick={() => setDisplayMessage(false)}>
              <img src={CloseIcon} alt="close" />
            </button>
            <p className='message'>
              Le minimum pour la livraison est de : {cartThreshold} euros
            </p>
          </div>
        </div>  
      </Modal>

    </div>
  );
}

function getLoyalty(WrappedComponent) {
  return class extends React.Component {
    // Context must be passed down explicitly
    static contextType = MenuContext

    constructor(props) {
      super(props)
      this.state = {
        loading: true
      }
    }

    componentDidMount() {
      return getLoyaltyDetails(this.context.cartRestaurantId)
        .then(loyaltyPoints => {
          this.setState({
            loyaltyPoints: loyaltyPoints,
            loading: false
          })
        }
        )
        .catch(err =>
          this.setState({
            error: "Une erreur s'est produite lors de la connexion",
            loading: false
          })
        )
        .catch(err =>
          this.setState({
            error: "Une erreur s'est produite lors de la connexion",
            loading: false
          })
        )
    }

    render() {
      return (
        <LoadingComponent
          loading={this.state.loading}
        >
          <WrappedComponent
            loyaltyPoints={this.state.loyaltyPoints}
            loading={this.state.loading}
            error={this.state.error}
            {...this.props}
          />
        </LoadingComponent>
      )
    }
  }
}

export default withNavigateHook(
  getLoyalty(CartView)
);