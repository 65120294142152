import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useOutsideClick } from "../../../Utils/UseOutsideClick";
import Dialog from "../../Dialog";
import Modal from "../../Modal";
import './style.scss';

const DiningMode = ({ context, open, close }) => {
  const [bool, setBool] = useState(open);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [modeSelected, setModeSelected] = useState(null);
  const ref = useRef();

  useEffect(() => {
    setBool(open)
  }, [open]);

  useOutsideClick(ref, () => {
    if(bool) close()
  });

  //TODO: Adapt it
  const setDiningMode = (mode) => {
    if (!context.cartId) {
      close()
      return context.setDiningMode(mode.id);
    }
    
    if (!mode.selected && !mode.disabled) {
      setModeSelected(mode.id)
      setDialogOpen(true);
    }
  }

  return (
    bool && <div className={"dining-mode-selector"} ref={ref}>
      <div>
        <div>
          {context.DINING_MODES.map((mode, id) => (
            <button className="dining-mode-selector-btn" onClick={() => {setDiningMode(mode);}} id={mode.id} disabled={mode.disabled} key={id}>
              <img src={mode.logo} alt="dining-mode-logo" />
              <p className={mode.selected? "active": ""}>
                {mode.label}
              </p>
            </button>
          ))}
        </div>
      </div>

      <Modal
        isOpen={dialogOpen}
        id="Dialog"
      >
        <Dialog
          close={() => setDialogOpen(false)}
          context={context}
          onConfirm={() => context.setDiningMode(modeSelected)}
          message={
            "Certains produits ne sont pas disponibles dans le mode que vous avez choisi, videz le panier ?"
          }
        />
      </Modal>
    </div>
  )
}

export default DiningMode;
