import React from 'react';

import ComposableCartProduct from './ComposableProduct';
import SingleProduct from './SingleProduct';

const CartEntryWrapper = (props) => {  
  if (props.Type === "composable")
    return <ComposableCartProduct {...props} />

  if (props.Type === "single")
    return <SingleProduct {...props} />

  return null
}
CartEntryWrapper.displayName = 'CartEntryWrapper'

export default CartEntryWrapper