import React, { useContext } from 'react';
import withNavigateHook from '../../../../components/WithNavigation';

import { MenuContext } from '../../../../context'

import CartList from '../CartList'

import './style.scss'

const PaymentView = ({ navigate }) => {
  const context = useContext(MenuContext)

  return (
    <div className='pv-profile-container'>
      {/* Payment Form */}
      <div className='profile-section profile-section-center'>
        <p className='profile-text'>Veuillez vous connecter afin de passer une commande</p>
        <button onClick={() => navigate('/login?redirect=/payment', { redirect: "/payment" })} className="profile-signin-button">
          <p>Connexion</p>
        </button>
      </div>

      {/* Command List */}
      <div className='profile-section'>
        <CartList restaurant={context.restaurant} diningMode={context.diningMode} cart={context.cart} totalCost={context.getCartTotalCost()} />
      </div>
    </div>
  )
}

export default withNavigateHook(PaymentView);