import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { MenuContext } from '../../../context';
import './style.scss'

export default (props) => {
  const context = useContext(MenuContext)
  
  if (context.user && !context.user.emailConfirmed)
    return (
      <div className='as-container'>
        <p>Veuillez confirmer votre adresse mail depuis</p>
        <Link to="/login/resend" className='link'>ce lien</Link>
      </div>
    )
  
  return null
}
