import React, { useContext, useEffect, useState } from 'react';
import BurgerMenuLogo from '../../static/img/burger-menu.svg';
import HomeLogo from '../../static/img/home.svg';
import CartLogo from '../../static/img/cart.svg';
import DinigModeSelect from '../DiningModeControls/Select'
import { MenuContext } from '../../context';
import { getLoyaltyDetails } from '../../apps/Cart/HttpServices';
import { toast } from 'react-toastify';
import UserMenu from './UserMenu';
import withNavigateHook from '../WithNavigation';
import ChevronLogo from '../../static/img/chevron.svg';
import './style.scss';

const Navbar = ({ navigate, locationPathName, setShow }) => {
  const context = useContext(MenuContext);
  let id = context.restaurant.id;
  const [fidelitePts, setFidelitePts] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [font, setFont] = useState("#F25C00");
  const iconFont = "#F25C00";
  const pathname = "/restaurant/" + id;
  
  useEffect(() => {
    setFont(context.restaurant.headerFrColor)

    if (locationPathName === pathname || locationPathName === pathname + "/") {
      if (context.restaurant.headerFrColor && context.restaurant.headerFrColor !== undefined) {
        setFont(context.restaurant.headerFrColor)
      } else {
        setFont("#F25C00")
      }
    } else {
      setFont("#F25C00")
    }

    if (locationPathName === pathname || locationPathName === pathname + "/") {
      if (context.restaurant.region1 && context.restaurant.region1 !== undefined) {
        setBackgroundImage(context.restaurant.region1)
      } else {
        setBackgroundImage("")
      }
    } else {
      setBackgroundImage("")
    }
    setFidelitePts(0)
    
    if(context.idUser === "" || id === "")
      return;

    getLoyaltyDetails(id)
      .then(loyaltyPoints => {
        setFidelitePts(loyaltyPoints)
      })
  }, [id, locationPathName])

  const cancelPromotion = () => {
    if (context.remise && context.remise.IdPromo) {
      context.unApplyPromotion()
      toast.info("promotion inappliquée")
    }
  }

  const showCart = () => {
    cancelPromotion()
    context.toggleCart()
  }

  const redirectTo = (location) => {
    //TODO: ADAPT IT
    // RX.Modal.dismissAll();
    navigate(location)
  }

  return (
    //TODO: redo graphic chart
    <div className="navbar-container">
      <div className="navbar-main">
        {/* Simple links */}
        <div className='navGroup'>
          <button onClick={() => setShow(true)} className="navItem">
            <img src={BurgerMenuLogo} alt="burger-menu" className='mb-only menu-icon' />
          </button>
          <button onClick={() => redirectTo('/')} className="navItem">
            <img src={HomeLogo} alt="home" style={{fill: font}} />
          </button>
        </div>

        <div className='navGroup stretch'>
          <button onClick={() => navigate("/search")} className="large-only">
            <h3 className='bold'>Trouver un restaurant</h3>
            {/* TODO: Adapt it */}
          </button>
            {context.diningMode && <DinigModeSelect />}
        </div>
        
        {/* Cart and auth menu */}
        <div className='navGroup'>
          {context.jwt && (locationPathName === pathname || locationPathName === pathname+"/") &&
            <div className='fd-pts'>
              <p className='fd'>{fidelitePts}</p>
              <p className='pts bold'>Pts</p>
            </div>
          }
          
          {context.jwt
            ? <div className='navItem' onClick={() => {setUserMenuOpen(!userMenuOpen)}}>
                <img src={require("../../static/img/user.png")} className='user-icon' alt="user" />
                <div className='nav-element-container'>
                  <p id="current-user" className='navItem-text bold'>{context.user.firstName}</p>
                  <img src={ChevronLogo} alt="chevron" className='dropdown' />
                </div>

                <UserMenu open={userMenuOpen} close={() => setUserMenuOpen(false)} />
              </div>

            : <button onClick={() => redirectTo('/login/')} className="navItem loginButton">
                <p className='navItem-text bold'>Se connecter</p>
              </button>
          }

          {context.jwt &&
            <button onClick={() => redirectTo('/user/fidelite')} className="navItem">
              <img src={require("../../static/img/carte-fid.png")} className='user-icon' alt="cart-fid" />
              <div className='nav-element-container'>
                <p className='navItem-text bold'>Fidélité</p>
              </div>
            </button>
          }

          <button onClick={() => showCart()} id="basket-button" className='navItem'>
            <p className='cartCount'>{context.cartCount() || null}</p>
            <img src={CartLogo} alt="cart" className='cart-icon' />
            {context.remise && context.remise.IdPromo !== 0 && 
              <p className='cartPromo'>Promo</p>
            }
          </button>
        </div>
      </div>
    </div>
  );
}

export default withNavigateHook(Navbar);