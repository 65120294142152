/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react'

import { Elements, ElementsConsumer, injectStripe } from '@stripe/react-stripe-js'

import { CardNumberField, CardExpiryField, CardCVCField } from '../../Providers/Stripe'

import { loadStripe } from '@stripe/stripe-js';

import './style.scss';

const StripeForm = ({ 
  handleChange,
  stripe,
  clientSecret,
  setPaymentError,
  getPaymentMethod, triggerGetPaymentMethod,
  confirmPaymentMethod, triggerGetPaymentConfirmation,
  Style
}) => {
  const [error, setError] = useState("")

  const fetchPaymentMethod = async () => {
    const result = await stripe.createPaymentMethod('card')

    if (result.error) {
      setError("Une erreur c'est produite au moment du paiement")
      setPaymentError(result.error.message)
      return null
    }

    return getPaymentMethod(result.paymentMethod)
  }

  const fetchConfirmPaymentMethod = async () => {   
    const result = await stripe.handleCardAction(clientSecret)

    if (result.error) {
        setError("Une erreur c'est produite au moment du paiement")
      setPaymentError(result.error.message)
      return null
    }

    return confirmPaymentMethod(result.paymentIntent.id)
  }

  if (triggerGetPaymentMethod)
    fetchPaymentMethod()

  if (triggerGetPaymentConfirmation) {
    try {
      fetchConfirmPaymentMethod()
    } catch (e) {
        setError("Une erreur c'est produite au moment du paiement")
      setPaymentError(e.message)
    }
  }

  return (
    <div className='ps-fieldSet'>
      <p className='ps-header'>Informations de paiement</p>
      <div className='ps-formset'>
        <CardNumberField onChange={handleChange}/>
        <CardExpiryField onChange={handleChange} />
        <CardCVCField onChange={handleChange} />
      </div>

      <div className='ps-formErrors'>
        {error && <p className='ps-formError'>{error}</p>}
      </div>
    </div>
  )
}

const StripeProviderWrapper = (props) => {
  const stripePromise = loadStripe(props.publicApiKey);

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({stripe}) => (
          <StripeForm stripe={stripe} {...props} />
        )}
      </ElementsConsumer>
    </Elements>
  )
}

export default StripeProviderWrapper;