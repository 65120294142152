import React from 'react';
import Modal from '../Modal';
import CloseLogo from '../../static/img/letter-xblack.svg';
import './style.scss';

const Dialog = ({ message, context, onConfirm, close }) => {
  return (
    <div className='dialog-container'>
      <div id="change-mode-dialog" className='dialog'>
        <button
          className='close-button'
          onClick={() => close()}
        >
          <img src={CloseLogo} alt="close" />
        </button>
        <p className='dialog-text'>
          {message}
        </p>
        <div className='buttons-container'>
          <button
            id="cancel-mode"
            className='cancel-button'
            onClick={() => close()}
          >
            <p>
              Annuler
            </p>
          </button>
          <button
            id="confirm-mode-dialog"
            onClick={() => {
              context.resetCart(onConfirm)
              close()
            }}
            className="confirm-button"
          >
            <p>
              Valider
            </p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Dialog;