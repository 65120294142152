import { getStorageItem } from '../DeviceServices/Storage'
import { buildRequestParams } from '../../../Utils/Http'
import { __PublicAPI__ } from '../../../Infra/HttpServices'

export const GetOrders = async (params) => {
  // TODO: Move into an Http wrapper 
  const token = await getStorageItem("jwt")

  const getParams = buildRequestParams(params)

  let requestPath = `${__PublicAPI__}/api/order/list/${getParams}`

  const response = await fetch(
    requestPath,
    {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }
  )

  if (response.status !== 200)
    return []

  return await response.json()
}


export const GetOrder = async (id) => {
  const token = await getStorageItem("token")

  const response = await fetch(
    `${__PublicAPI__}/api/order/${id}`,
    {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }
  )

  if (response.status !== 200)
    return {}

  return await response.json()
}

export const ToggleFavorite = async (id, isfavorite) => {
  const token = await getStorageItem("jwt")  

  const response = await fetch(
    `${__PublicAPI__}/api/order/${id}/favorite`,
    {
      method: "POST",
      headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: isfavorite
    })

  if (response.status !== 200)
    return {}

  return await response.json()
}