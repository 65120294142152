// Helper function that handles network failure on API calls.
export const NetworkError = (networkCall) => async (params) => {
  try {
    const HttpResponse = await networkCall(params)

    if (HttpResponse.errors)
      return {
        errors: HttpResponse.errors
      }

    if (HttpResponse.error)
      return {
        error: HttpResponse.error
      }

    return HttpResponse
  } catch (e) {   
    return {
      error: "Une erreur s'est produite lors de la connexion"
    }
  }
}