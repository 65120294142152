import React from 'react'

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'

import './style.scss'

export const CardNumberField = ({ onChange }) => {
  return (
    <div className='sw-field'>
      <p className='sw-fieldLabel'>Numéro Carte de Crédit</p>
      <div className='sw-fieldInput sw-creditCardNumberField'>
        <CardNumberElement
          onChange={onChange}
          options={{ style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              letterSpacing: '0.025em',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#c23d4b',
            }
          }}}
        />
      </div>
    </div>
  )
}

export const CardExpiryField = ({ onChange }) => {
  return (
    <div className='sw-field'>
      <p className='sw-fieldLabel'>{'Date d\'expiration'}</p>
      <div className='sw-fieldInput sw-expiryDateField'>
        <CardExpiryElement
          onChange={onChange}
          id="card-expiration"
          options={{ style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              letterSpacing: '0.025em',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#c23d4b',
            }
          }}}
        />
      </div>
    </div>
  )
}

export const CardCVCField = ({ onChange }) => {
  return (
    <div className='sw-field'>
      <p className='sw-fieldLabel'>CVC/CCV</p>
      <div className='sw-fieldInput sw-ccvField'>
        <CardCvcElement
          onChange={onChange}
          id="card-cvc"
          options={{ style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              letterSpacing: '0.025em',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#c23d4b',
            }
          }}}
        />
      </div>
    </div>
  )
}