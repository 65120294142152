import React from 'react';
import { AutoComplete } from '../gmaps'
import './style.scss'

const Address = (props) => {
  return (
    <AutoComplete
      country={"fr"}
      onLoad={props.onLoad}
      onPlaceChanged={props.onPlaceChanged}
    >
      <div className='address-container'>
        <img src={require('../../static/img/loupe.png')} alt="search" />
        <input
          id="search-input"
          required
          type={"text"}
          value={props.placeValue}
          onChange={(event) => {
            props.hasOwnProperty('setPlaceValue') && props.setPlaceValue(event.target.value);
            props.hasOwnProperty('deleteAddress') && props.deleteAddress();
          }}
          placeholder={"Adresse, Nom du restaurant..."}
        />
      </div>
    </AutoComplete>
  )
}

export default Address;