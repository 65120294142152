export const buildOrderObject = (cart) => {
  let order = []

  cart.map(entry => {
    order.push({
      item: entry.Name,
      quantity: entry.Quantity,
      unitPrice: entry.Price || entry.totalCost,
      amount: entry.totalCost
    })
  })
  return order
}

const generateSingleProductCommand = (cartEntry) => {
  return {
    "Q": cartEntry.Quantity,
    "T": cartEntry.totalCost,
    "Pr": [{
      "Rf": cartEntry.ProductId,
      "Pr": cartEntry.IsLoyaltyApplyed ? cartEntry.Price + cartEntry.LoyaltyPointsPrice : 
            cartEntry.IsPromoApplied ? cartEntry.Price + cartEntry.PcTotalRemiseMt : cartEntry.Price,
      "Q": 1,
      "cfpp": cartEntry.CpFideliteProd,
      "cfpt": cartEntry.CpFideliteRetrait,
      "cfpr": cartEntry.CpFidelitePrice,
      "pcrmp": cartEntry.IsLoyaltyApplyed ? cartEntry.PcRmsPc : 0,
      "pcrm": cartEntry.IsLoyaltyApplyed ? cartEntry.PcRmsMt : 0,
      "pctrmp": cartEntry.PcTotalRemisePct,
      "pctrmm": cartEntry.PcTotalRemiseMt,
      "ofd": cartEntry.IdPcAchatOffert,
      "ona": cartEntry.OfNbAc,
      "onf": cartEntry.OfNbOf,
      "ofap": cartEntry.OfIsApply
    }]
  }
}

const generateComposableProductCommand = (cartEntry) => {
  let group = {
    "Q": cartEntry.Quantity,
    "T": cartEntry.totalCost,
    "Pr": [],
  }

  // Components are not grouped, submit one at a time with Quantity = 1
  cartEntry.Components.forEach(component => {
    const compositions = []

    cartEntry.Compositions.forEach(composition => {
      if (composition.ComponentId === component.Id) {
        if (composition.IsDefault)
        {
          compositions.push({
            "Rf": composition.Id,
            "Dft": true,
            "Ac": true
          })
        }
        else {
          compositions.push({
            "Rf": composition.Id,
            "Pr": composition.IsPromoApplied ? composition.Price + composition.PccTotalRemiseMt : composition.Price,
            "Q": composition.Price === 0 ? 0 : composition.Quantity,
            "Dft": false,
            "Ac": true,
            "pcctrmp": composition.PccTotalRemisePct,
            "pcctrmm": composition.PccTotalRemiseMt
          })
        }
      }
    });

    group.Pr.push({
      "Rf": component.Id,
      "Pr": component.IsLoyaltyApplyed ? component.Price + component.LoyaltyPointsPrice : 
            component.IsPromoApplied ? component.Price + component.PcTotalRemiseMt : component.Price,
      "Q": component.Quantity,
      "cfpp": component.CpFideliteProd,
      "cfpt": component.CpFideliteRetrait,
      "cfpr": component.CpFidelitePrice,
      "pcrmp": component.PcRmsPc,
      "pcrm": component.PcRmsMt,
      "pctrmp": component.PcTotalRemisePct,
      "pctrmm": component.PcTotalRemiseMt,
      "hsC": compositions.length > 0,
      "cmps": compositions,
      "ofd": component.IdPcAchatOffert,
      "ona": component.OfNbAc,
      "onf": component.OfNbOf,
      "ofap": component.OfIsApply
    })
  })

  return group
}


const buildAddress = (address) => {
  if (!address)
    return null

  try {
    return {
      "Glo": address.geometry.location.lng,
      "Gla": address.geometry.location.lat,
      "Gsn": address.address_components.find(adressComponent => adressComponent.types.find(value => value === "street_number")).long_name,
      "Gsr": address.address_components.find(adressComponent => adressComponent.types.find(value => value === "route")).long_name,
      "Gl": address.address_components.find(adressComponent => adressComponent.types.find(value => value === "locality")).long_name,
      "Gda": address.address_components.find(adressComponent => adressComponent.types.find(value => value === "locality")).long_name,
      "Gra": address.address_components.find(adressComponent => adressComponent.types.find(value => value === "administrative_area_level_1")).long_name,
      "Gca": "France",
      "Gcs": "FR",
      "C": address.address_components.find(adressComponent => adressComponent.types.find(value => value === "postal_code")).long_name,
      "fa": address.formatted_address,
      "lf": address.formatted_address,
      "sfa": "",
      "bui": address.building,
      "dr": address.door,
      "drc": address.doorCode,
      "stc": address.stairCaise,
      "intr": address.intercom
    }
  } catch (e) {
    throw new Error("Address incomplete")
  }
}

export const generateCommand = (cart, restaurant, remise, totalCost, totalPoints, totalPointsApplyed, diningMode, client, address, decalage) => { 

  let command = {
    "Em": diningMode === "takeaway" || diningMode === "delivery",
    "Lv": diningMode === "delivery",

    "G": [],

    "Mc": restaurant.idMachine,
    "Pv": restaurant.idPv,
    "Rs": restaurant.idRestau,
    "Us": restaurant.idUser,
    "Tt": totalCost,
    "Tn": totalCost,
    "fpt": totalPoints,
    "ftt": totalPointsApplyed,
    "Cl": {
      "Fn": client.FirstName,
      "Ln": client.LastName,
      "Em": client.Email,
      "PhN": client.Phone,
      "Sk": client.LoyaltySecretKey,
      "QCd": client.QrCode
    },
    "IdPr": remise.IdPromo,
    "Rm": {
      "IsP": remise.IsPct,
      "M": remise.Mnt,
      "V": true,
      "Min": 0
    },
    "Adr": buildAddress(address),
    "Dch": decalage
  }

  cart.forEach(entry => {
    if (entry.Type === "single") {
      command.G.push(generateSingleProductCommand(entry))
    }

    else {
      command.G.push(generateComposableProductCommand(entry))
    }
  })

  return command
}
