import React, { useState }  from 'react'

import Card from '../Card/Card'

import AllergenesView from '../AllergenesView'
import { __ImageHost__ } from '../../../../../Infra/HttpServices'
import './style.scss'
import { useRef } from 'react'

const SectionView = ({ components, addComponentToCart, getComponentQuantity }) => {
  const [allergenes, setAllergenes] = useState(null)
  const scrollToRef = (ref) => ref && window.scrollTo(0, ref.current.offsetTop)
  const scrollView = useRef();

  return (
    <div className='cart-section-container'>
      <div
        ref={scrollView}
        className="items"
      >
        <div className='items-container'>
          {components.map((component) =>
              <Card
                key={component.Id}
                title={component.Name}
                type={0}
                text={component.Description}
                image={component.Image}
                price={component.Price}
                allergens={component.Allergenes}
                loyaltyPointsProd={component.LoyaltyPointsProd}
                loyaltyPointsRetrait={component.LoyaltyPointsRetrait}
                loyaltyPointsPrice={component.LoyaltyPointsPrice}
                selectionCount={getComponentQuantity(component)}
                onPress={() => addComponentToCart(component)}
                isNext={component.IsNextButton}
                scrollToTop={() => {scrollToRef(scrollView); addComponentToCart(component);}}
                displayAllergenes={(allergenes) => setAllergenes(allergenes)}
            />
          )}
          {allergenes &&
            <AllergenesView 
              allergenes={allergenes}
              onClose={() => setAllergenes(null)}
            />
          }
        </div>
      </div>
    </div>
  )
}

export default SectionView;