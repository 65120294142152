import { __PublicAPI__ } from "../../../Infra/HttpServices";
import { getStorageItem } from "../../Order/DeviceServices/Storage";

export const getRestaurants = async (categories) => {
  let requestPath = `${__PublicAPI__}/api/restaurant`;
  const token = await getStorageItem("jwt");

  if(categories && categories.length > 0) {
    categories.map((c, i) => {
      if(i === 0) {
        return requestPath += `/?categories=${c}`
      }else {
        return requestPath += `&categories=${c}`
      }
    });
  }

  const response = await fetch(
    requestPath,
    {
      headers: {
      "Authorization": `Bearer ${token}`
      }
    }
  );

  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion");

  const body = await response.json();

  return body;
};

export const getFavoriteRestaurants = async ({categories, nameLike = ""}) => {
  let requestPath = `${__PublicAPI__}/api/restaurant/favorite`;
  const token = await getStorageItem("jwt");
  
  if(nameLike) {
    requestPath += `/?nameLike=${nameLike}`
  }

  if(categories && categories.length > 0) {
    categories.map((c, i) => {
      if(i === 0 && !nameLike) {
        return requestPath += `/?categories=${c}`
      }else {
        return requestPath += `&categories=${c}`
      }
    });
  }

  const response = await fetch(
    requestPath,
    {
      headers: {
      "Authorization": `Bearer ${token}`
      }
    }
  );

  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion");

  const body = await response.json();

  return body;
};

export const getRestaurant = async (id) => {
  const response = await fetch(`${__PublicAPI__}/api/restaurant/${id}/`);

  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion");

  const body = await response.json();
  

  return body;
}

export const getAds = async () => {
  let requestPath = `${__PublicAPI__}/api/ads`;
  const response = await fetch(requestPath);
  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion");

  const body = await response.json();

  return body;
};

export const getConfig = async () => {
  let requestPath = `${__PublicAPI__}/api/appconf`;
  const response = await fetch(requestPath);
  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion");
  const body = await response.json();
  return body;
}

export const getCategories = async () => {
  let requestPath = `${__PublicAPI__}/api/category`;
  const response = await fetch(requestPath);
  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion");
  const body = await response.json();
  return body;
}

export const toggleFavoriteRestaurant = async ({id, isFavorite}) => {
  let requestPath = `${__PublicAPI__}/api/userRestaurant/favorite/${id}`;
  const token = await getStorageItem("jwt");
  
  const response = await fetch(requestPath,
    {
      method: "POST",
      headers: {
      "Content-Type": 'application/json',
      "Authorization": `Bearer ${token}`
      },
      body: isFavorite
    }
  );

  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion");
  return response.json();
}

export const updateUserRestaurantScore = async ({id, score}) => {
  let requestPath = `${__PublicAPI__}/api/userRestaurant/score/${id}`;
  const token = await getStorageItem("jwt");
  
  const response = await fetch(requestPath,
    {
      method: "POST",
      headers: {
      "Content-Type": 'application/json',
      "Authorization": `Bearer ${token}`
      },
      body: score
    }
  );

  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion");
  return response.json();
}

export const getUserRestaurantScore = async (id) => {
  const token = await getStorageItem("jwt");
  const response = await fetch(
    `${__PublicAPI__}/api/userRestaurant/${id}/`,
    {
      headers: {
      "Authorization": `Bearer ${token}`
      }
    }
  );

  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion");

  const body = await response.json();
  

  return body;
}