export const SignInFormModel = [
  {
    title: "Infomations de connexion",
    fields: [
      {
        id: "Email",
        label: "Email *",
        type: "email"
      },
      {
        id: "Password",
        label: "Mot de passe *",
        type: "password"
      }
    ]
  }
]

export const SignUpFormModel = [
  {
    title: "Informations personnelles",
    fields: [
      {
        id: "LastName",
        label: "Nom *",
      },
      {
        id: "Email",
        label: "Email *",
        type: "email"
      },
      {
        id: "Password",
        label: "Mot de passe *",
        type: "password"
      },
    ]
  },
  {
    title: "Infomations de connexion",
    fields: [
      {
        id: "FirstName",
        label: "Prénom *",
      },
      {
        id: "PhoneNumber",
        label: "Téléphone *",
      },
      {
        id: "PasswordConfirm",
        label: "Confirmation mot de passe *",
        type: "password"
      }
    ]
  }
]
