import React from 'react';
import CloseIcon from "../../../static/img/letter-xblack.svg";
import './style.scss';

const CardDesc = ({ description }) => (
    <div className='card-desc-container'>
        <div className='dialog-container'>
            <button className='dialog-close-button'>
                <img src={CloseIcon} alt="close" className='dialog-close-icon' />
            </button>
            <p className='desc-text'>
                {description}
            </p>
        </div>
    </div>
)

export default CardDesc;