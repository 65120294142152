import React from "react"
import CloseIcon from "../../../static/img/letter-xblack.svg"
import "./style.scss"

const Dialog = ({ message, redirect, modes, context}) => {
  return (
    <div className="dmc-dialog-container">
      <div id="change-mode-dialog" className="dmc-dialog-container">
        <button className="dmc-dialog-close">
          <img src={CloseIcon} alt="close-icon" />
        </button>
        <p className="dmc-dialog-text">{message}</p>
        <div className="dmc-buttons-container">
          {modes.map(mode => !mode.disabled && (
              <button
                onClick={() => {
                  context.setDiningMode(mode.id)
                  redirect && redirect()
                }}
                className="dmc-mode-button"
              >
                <img src={mode.logo} alt="mode-logo" />
                <p>{mode.label}</p>
              </button>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Dialog;
