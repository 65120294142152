import React from 'react';

import './style.scss'

class EmailResetMessage extends React.Component {
  render() {
    return (
      <div className='um-container'>
        <img src={require("../../../../static/img/email.png")} alt="email" className='um-image' />
        <p className='um-text'>Un email a été envoyé a votre adresse, veuillez vérifier votre boîte de réception pour changer votre adresse email.</p>
      </div>
    );
  }
}

export default EmailResetMessage
