import React from 'react';

import CardImage from '../../../../../components/FavoriteCardImage'
import { __ImageHost__ } from '../../../../../Infra/HttpServices';
import { toggleFavoriteRestaurant } from '../../../HttpServices';

export default ({ restaurant, onPress, redirectTo, ReloadData }) => {
 
  return (
    <CardImage
      key={restaurant.Id}
      toggleFavoriteRestaurant={toggleFavoriteRestaurant}
      isFavorite={restaurant.IsFavorite}
      Id={restaurant.Id}
      title={restaurant.Name}
      text={restaurant.Address}
      disabled={!restaurant.IsOpen}
      image={`${__ImageHost__}/${restaurant.Image}` || ""}
      onPress={() => onPress(restaurant)}
      Shifts={restaurant.Shifts}
      redirectTo={redirectTo}
      ReloadData={ReloadData}
    />
  )
}