import React from 'react';
import './style.scss';

const FacebookIcon = () => (
  <div>
    <img />
    <p>Facebook</p>
    <button>
      <p>dissocier</p>
    </button>
  </div>
)

FacebookIcon.displayName = "FacebookIcon";

export default FacebookIcon;