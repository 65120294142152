import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { MenuContext } from '../../context'
import Modal from '../../components/Modal'
import { Widget } from '@typeform/embed-react'
import { Link } from 'react-router-dom'
import withNavigateHook from '../WithNavigation'
import CloseIcon from '../../static/img/letter-xblack.svg';

import './style.scss';

const Footer = ({ navigate }) => {
  const context = useContext(MenuContext);
  const [displayFeedback, setDisplayFeedback] = useState(false);

  const cancelPromotion = () => {
    if (context.remise && context.remise.IdPromo) {
      context.unApplyPromotion()
      toast.info("promotion inappliquée")
    }
  }

  const showCart = () => {
    cancelPromotion()
    context.toggleCart()
  }

  return (
    <div className='footer-container'>
      <div className='row'>
        <div>
          <p className='text'>Suivez-nous par ici !</p>
          <div className='RSContainer'>
            <a href='https://fr-fr.facebook.com/frenchinnov.france/'rel='noreferrer' target="_blank">
              <img src={require("../../static/img/facebook.png")} alt="fb" />
            </a>
            <a href='https://www.instagram.com/french_innov/?hl=fr' rel='noreferrer' target="_blank">
              <img src={require("../../static/img/instagram.png")} alt="ig" />
            </a>
            <a href='https://fr.linkedin.com/company/frenchinnov' rel='noreferrer' target="_blank">
              <img src={require("../../static/img/linkedin.png")} alt="linkedin" />
            </a>
          </div>
        </div>

        <div>
          <p className='title bold'>Nos services</p>
          <div className='column'>
            <button onClick={() => navigate("/search")}>
              <p>Trouver un restaurant</p>
            </button>
            <button onClick={() => navigate("/login")}>
              <p>Connexion</p>
            </button>
            <button onClick={() => navigate("/signup")}>
              <p>Inscription</p>
            </button>
            <button onClick={() => showCart()}>
              <p>Panier</p>
            </button>
          </div>
        </div>

        <div>
          <p className='title bold'>Informations légales</p>
          <div className='column'>
            <Link to="/legal/tos" target="_blank" className='link'>Conditions d'utilisation</Link>
            <Link to="/legal/pp" target="_blank" className='link'>Politique de confidentialité</Link>
          </div>
        </div>

        <div className='stretch'>
          <p className='exp-text'>Votre expérience vous a plu ?</p>
          <button onClick={() => setDisplayFeedback(true)} className="button">
            <p className='bold'>Feed Back</p>
          </button>
        </div>
      </div>

      <div className='ppPhone'>
        <Link to="/legal/tos" target="_blank" className='text link'>Conditions d'utilisation</Link>
        <p className='verticalBar text'>|</p>
        <p className='ta-center text'>Copyrights &copy; 2022, FRENCHINNOV.FR</p>
        <p className='verticalBar text'>|</p>
        <Link to="/legal/pp" target="_blank" className='text link'>Politique de confidentialité</Link>
      </div>

      <p className='ta-center cpr'>Copyrights &copy; 2022, FRENCHINNOV.FR</p>

      <Modal
        isOpen={displayFeedback}
        id="Feedback"
      >
        <div onClick={() => setDisplayFeedback(false)} className="feedback-container">
          <button onClick={() => setDisplayFeedback(false)}>
            <img src={CloseIcon} alt="close" />
          </button>
          <Widget id="QdTdE74v" className='widget' />
        </div>
      </Modal>

    </div>
  )
}

export default withNavigateHook(Footer);