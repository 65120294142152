import React from "react"
// import 'swiper/swiper-bundle.css'

// Store
import { MenuContext } from "../../../../context"
import { MenuProvider } from "../../../../context/Provider"
// Cross-cutting imports
import Jumbotron from "../../../../components/Jumbotron"
import LoadingComponent from "../../../../components/utils/withLoading"
import DiningFilter from "../../../../components/DiningModeControls/Buttons"
import AutoComplete from "../../../../components/AutoComplete"
import Dialog from "../../../../components/DiningModeControls/Dialog"
import Swiper from "../../../../components/Swiper"
import Modal from "../../../../components/Modal/index"

// Local imports specific to the restaurant module
import Thumbnail from "../Thumbnail"
import { getRestaurants ,getAds,getConfig, getCategories} from "../../HttpServices"
import { isInLocationRange } from "../../../../Utils/Filters"
import withNavigateHook from "../../../../components/WithNavigation"

import './style.scss';

class RestaurantListView extends React.Component {
  static contextType = MenuContext

  constructor(props) {
    super(props)

    this.state = {
      restaurants: props.restaurants || [],
      error: props.error || null,
      ShowDialog: false,
      ads : [],
      AppConf : {},
      open: false,
      categories: [],
      openDialog: false,
      restaurantId: null,
      newDiningModes: [],
      newDisabledMode: {}
    }
  }
  redirectToRestaurant = (id) => {
    this.props.navigate(`/restaurant/${id}/`)
  }

  componentDidMount() {
    this.fetchAppConf()
  }

  toggleOpen() {
    return this.setState({
      open: !this.state.open
    })
  }

  fetchAds () {
    return getAds()
    .then((ads) =>
      this.setState({
        ads: ads,
        loading: false,
      })
    )
    .catch((err) =>
      this.setState({
        error: "Une erreur s'est produite lors de la connexion",
        loading: false,
      })
    )
    .catch((err) =>
      this.setState({
        error: "Une erreur s'est produite lors de la connexion",
        loading: false,
      })
    )
  }

  resetFilters() {
    this.setState({
      categories: []
    }, () => {
      this.props.handleSelectedCategories(this.state.categories)
    })
  }

  fetchAppConf(){
    return getConfig()
    .then((AppConf) =>
      this.setState({
        AppConf: AppConf,
        loading: false,
      })
    ).then(() => {
      this.fetchAds()
    })
    .catch((err) =>
      this.setState({
        error: "Une erreur s'est produite lors de la connexion",
        loading: false,
      })
    )
    .catch((err) =>
      this.setState({
        error: "Une erreur s'est produite lors de la connexion",
        loading: false,
      })
    )
  }

  onPress = (restaurant) => {
    const { SupportsOnSpot, SupportsPickUp, SupportsDelivery } = {
      ...restaurant,
    }

    // Create the new state objects and check if the new state requires changing the dining mode
    let newDiningModes = this.context.DINING_MODES.map(mode => {
      if (mode.id === "onspot")
        mode.disabled = !SupportsOnSpot

      if (mode.id === "takeaway")
        mode.disabled = !SupportsPickUp
      
      if (mode.id === "delivery")
        mode.disabled = !SupportsDelivery

      return mode
    })

    const newDisabledMode = newDiningModes.find(mode => mode.selected && mode.disabled)

    if (newDisabledMode) {
      this.setState({
        restaurantId: restaurant.id,
        newDiningModes: newDiningModes,
        newDisabledMode: newDisabledMode,
        openDialog: true
      })
    }

    this.redirectToRestaurant(restaurant.Id)
  }

  handleCheckboxClick(id) {
    let categ = [...this.state.categories];

    if(!categ.includes(id)) {
      categ.push(id)
    }else {
      let i = categ.indexOf(id);

      if(i !== -1) {
        categ.splice(i, 1);
      }
    }

    this.setState({
      categories: categ
    });
  }

  render() {
    return (
      <div className="search-container">

        <p className="header bold">Cherchez un restaurant à proximité de vous !</p>

        <div className="body">
          {/* Filters */}
          <div className="filters">
            <div className="flex">
              <div className="filters-container">
                <div className="select-category">
                  <img src={require('../../../../static/img/category.png')} alt="category" />
                  <p className="normal-text">Spécialités</p>
                  <button
                    onClick={() => this.toggleOpen()}
                  >
                    <img src={require("../../../../static/img/dropdown.png")} alt="dropdown" />
                  </button>
                </div>
                <AutoComplete {...this.props} />
              </div>
              <div className="dummy">
                {this.state.open && <div className={"category-list"}>
                  <div className="categories-container">
                    {this.props.categories.length > 0 && this.props.categories.map(c => (
                      <div key={c.Id} className="category">
                        <input
                          onChange={() => this.handleCheckboxClick(c.Id)}
                          checked={this.state.categories.includes(c.Id)? true: false}
                          type="checkbox"
                          name="category"
                          value={c.Id}
                        />
                        <p>{c.Name}</p>
                      </div>
                    ))}
                    {this.props.categories.length <= 0 &&
                      <p className="normal-text">Pas de données à afficher</p>
                    }
                  </div>
                  <div className="button-container">
                    <button
                      onClick={() =>{
                        this.props.handleSelectedCategories(this.state.categories)
                        this.setState({open: false});
                      }}
                    >
                      <p>Valider</p>
                    </button>
                  </div>
                </div>}
              </div>
            </div>
            <div className="dining-filters">
              <DiningFilter
                setDiningMode={(value) => this.context.setDiningMode(value)}
                context={this.context}
              />
              <button
                onClick={() => {
                  this.resetFilters()
                }}
              >
                <p className="reset-filters bold">Tout afficher</p>
              </button>
            </div>
          </div>

          {/* Restaurant list */}
          <div className="restaurant-list" id="restaurant-list">
            {/* Restaurant list is not empty */}

            {this.props.restaurants.map((restaurant) => (
              <Thumbnail
                restaurant={restaurant}
                onPress={this.onPress}
                key={restaurant.Id}
                redirectTo={(location) => this.props.navigate(location)}
              />
            ))}

            {/* Restaurant list is empty */}
            {this.props.restaurants.length === 0 && !this.props.error && (
              <p className="text" id="no-restaurant">
                {" "}Veuillez insérer votre adresse{""}
              </p>
            )}

            {/* Restaurant list is empty mobile */}
            {this.props.restaurants.length === 0 && !this.props.error && (
              <div className="mb-results">
                <img src={require('../../../../static/img/noresults.png')} alt="no-results" />
                <p className="mb-title">Aucun résultat ne correspond à votre recherche.</p>
                <p className="mb-sub">Mais n'abandonnez pas si vite ! Vérifier votre adresse saisie ou changez les filtres de recherche.</p>
              </div>
            )}

            {/* Error message */}
            {this.props.error && (
              <p className="error text">
                {this.props.error}
              </p>
            )}
          </div>
        </div>

        <Modal
          id="dialog"
          isOpen={this.state.openDialog}
        >
          <MenuProvider>
            <Dialog
              message={`Ce restaurant ne supporte pas le mode ${this.state.newDisabledMode.label} vous devez choisir un autre mode`}
              context={this.context}
              redirect={() => this.redirectToRestaurant(this.state.restaurantId)}
              modes={this.state.newDiningModes}
              close={() => this.setState({openDialog: false})}
            />
          </MenuProvider>
        </Modal>
      </div>
    )
  }
}

function getRestaurantList(WrappedComponent) {
  return class extends React.Component {
    static contextType = WrappedComponent.contextType

    constructor(props) {
      super(props)

      this.state = {
        loading: true,
        error: "",
        restaurants: [],
        categories: []
      }
    }
    
    getRestaurants = (categories = []) => {
      return getRestaurants(categories)
      .then((restaurants) =>
      this.setState({
        restaurants: restaurants,
        loading: false,
      })
      )
      .catch((err) =>
      this.setState({
        error: "Une erreur s'est produite lors de la connexion",
        loading: false,
      })
      )
      .catch((err) =>
      this.setState({
        error: "Une erreur s'est produite lors de la connexion",
        loading: false,
      })
      )
    }

    getCategories() {
      return getCategories()
        .then((categories) =>
          this.setState({
            categories: categories
          })
        )
    }

    componentDidMount() {
      this.getRestaurants().then(() => this.getCategories())
    }

    render() {
      return (
        <LoadingComponent loading={this.state.loading}>
          <WrappedComponent
            restaurants={this.state.restaurants}
            categories={this.state.categories}
            loading={this.state.loading}
            handleSelectedCategories={this.getRestaurants}
            error={this.state.error}
            {...this.props}
          />
        </LoadingComponent>
      )
    }
  }
}

function filterRestaurantListByUserlocation(WrappedComponent) {
  return class extends React.Component {
    // Context must be passed down explicitly
    static contextType = WrappedComponent.contextType

    // NOT ENTERING FOR SOME REASON!
    applyLocationRangeFilter(userPlace, restaurants) {
      if (!userPlace) return []

      if (!restaurants) return []

      return restaurants.filter((restaurant) => {
        const userCoords = userPlace.coords

        const restaurantCoords = {
          latitude: restaurant.Latitude,
          longitude: restaurant.Longitude,
        }

        return isInLocationRange(
          userCoords,
          restaurantCoords,
          restaurant.DeliveryRadius
        )
      })
    }

    render() {
      const restaurants = this.applyLocationRangeFilter(
        this.context.userPlace,
        this.props.restaurants
      )

      return <WrappedComponent {...this.props} restaurants={restaurants} />
    }
  }
}

export default withNavigateHook(
  getRestaurantList(
    filterRestaurantListByUserlocation(RestaurantListView)
  )
);
