import React from 'react';

import { MenuContext } from '../../context';

import CardDesc from './CardDescription';
import AllergenList from './Allergens';
import InfoLogo from '../../static/img/more_info.svg';
import Modal from "../Modal/index";
import './style.scss';

class Card extends React.Component {
  static contextType = MenuContext

  constructor(props) {
    super(props);

    this.state = {
      hovering: false,
      openAllergen: false,
      descOpen: false
    }
  }

  showCardDescription() {
    this.setState({descOpen: true})
  }

  showCardAllergens() {
    this.setState({
      openAllergen: true
    });
  }

  render() {
    return (
      <div className='component-card-container'>
        <button
          onClick={this.props.onPress}
        >
          {/* TODO: Move image url rewriting to the menu fetching process @Frenchinnov.DataPipelines */}
          <img width="150px" src={this.props.image} alt={this.props.title} />

          {this.props.text &&
            <div onClick={(e) => {
              e.stopPropagation()
              this.showCardDescription(this.props.text)
              }}
              className="details-container"
            >
              <img  src={InfoLogo} alt="more info" />
            </div>
          }

          <div className='text-container'>
            <div>
              <p className='title'>{this.props.title}</p>
            </div>

            <div className='price-container'>
              {this.props.price > 0 && (
                <p className='price'>{this.props.price} €</p>
              )}
              {this.props.allergens &&
                <div className='allergen-container'>
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      this.showCardAllergens(this.props.allergens)
                      }}
                      className="card-allergen-button"
                    >
                    <p>allergènes</p>
                  </button>
                </div>
              }
              {this.props.selectionCount > 0 && (
                <p className='selection-count'>
                  {'x' + this.props.selectionCount}
                </p>
              )}
              {(this.props.loyaltyPointsRetrait > 0 && this.props.loyaltyPointsPrice > 0) && (
                <p className='loyalty'>{this.props.loyaltyPointsRetrait} pts = {(this.props.price - this.props.loyaltyPointsPrice).toFixed(2)} €</p>
              )}
            </div>
            
            {this.props.text &&
              <div onClick={(e) => {
                e.stopPropagation()
                this.showCardDescription(this.props.text)
                }}
                className="details-container-desk"
              >
                <p>Plus de détails</p>
              </div>
            }
          </div>
        </button>

        <Modal
          id="card-desc"
          isOpen={this.state.descOpen}
        >
          <CardDesc description={this.props.text} close={() => this.setState({descOpen: false})} />
        </Modal>

        <Modal
          id="allergen-list"
          isOpen={this.state.openAllergen}
        >
          <AllergenList list={this.props.allergens} close={() => this.setState({openAllergen: false})} />
        </Modal>
      </div>
    );
  }
}

export default Card;