import React from 'react';

import { MenuContext } from '../../../../context';

import RedirectAfter from '../../../../Utils/Router';
import './style.scss';

class SignOut extends React.Component {
  static contextType = MenuContext

  componentDidMount() {
    return this.context.logout()
  }

  render() {
    return (
      <div className='signout-container'>
        <img src={require("../../../../static/img/disconnected.png")} alt="disconnected" />
        <p>
          Vous avez été déconnecté de votre compte.
        </p>
      </div>
    )
  }
}

export default RedirectAfter(
  {
    after: 4000,
    to: "/"
  },
  SignOut
)
