import React, { useContext } from 'react';

import { MenuContext } from '../../../../context'

import CartList from '../CartList'
import PaymentForm from '../Forms'

import './style.scss'

const PaymentView = (props) => {
  const context = useContext(MenuContext)
  const { restaurant, diningMode, cart, paymentMethod } = context

  return (
    <div className='pv-profile-container'>
      <div className='profile-section'>
        <PaymentForm {...props} diningMode={diningMode} paymentMethod={paymentMethod} />
      </div>

      <div className='profile-section'>
        <CartList restaurant={restaurant} diningMode={diningMode} cart={cart} totalCost={context.getCartTotalCost()} totalPoints ={context.getCartTotalLoyaltyPoints()}/>
      </div>
    </div>
  )
}

export default PaymentView;