export const getDiningModeLabel = (diningMode) => {
  switch (diningMode) {
    case "delivery":
      return "Livraison"

    case "takeaway":
      return "A emporter"
    
    case "onspot":
      return "Sur place"
    
    default:
      return null
  }
}