export default [
  {
    title: "Entrer vote nouveau mot de passe",
    fields: [
      {
        id: "Password",
        label: "Mot de passe",
        type: "password"
      },
      {
        id: "PasswordConfirm",
        label: "Confirmer votre mot de passe",
        type: "password"
      }
    ]
  }
]