import React, { useContext } from 'react';

import { MenuContext } from '../../../../context'

import CartEntry from '../CartEntry'

import './style.scss'

export const cartModalId = 'Cart'

const CartEntries = ({ setActiveCartFamilyId, Style, loyaltyPoints }) => {
  const context = useContext(MenuContext)

  const getFamilies = () => {
    let families = []

    // Get the families from cart
    context.cart.forEach(entry => {
      const element = families.some(family => family.Id === entry.family.Id)

      if (element)
        return
        
      families.push(entry.family)
    })

    // Set the products count by family
    const activeFamilies = families.map(family => {
      let count = 0

      context.cart.filter(entry => {
        if (entry.family.Id === family.Id) {
          count+= entry.Quantity
          return entry
        }
      })

      return {
        id: family.Id,
        image: family.Image,
        name: family.Name,
        count: count
      }
    })

    return activeFamilies
  }

  const getProductsByFamily = () => {    
    const entries = context.cart

    if (entries.length === 0)
      setActiveCartFamilyId(null)

    return entries
  }

  return (
    <div className='cart-products'>
      {getProductsByFamily().map(entry => (
        <CartEntry
          key={entry.Id}
          {...entry}
          incrementQuantity={context.incrementCartEntryQuantity}
          decrementQuantity={context.decrementCartEntryQuantity}
          deleteEntry={context.deleteCartEntry}
          IsLoyaltyApplyed={entry.IsLoyaltyApplyed}
          loyaltyPoints={loyaltyPoints}
        />
      ))}
    </div>
  )
}

export default CartEntries;