import React from "react";

import { signUpHttp, getRecaptchaPk } from "../../HttpServices";
import { SignUpFormModel } from "../../Models";

import PhoneNumber from "../../../../components/Forms/PhoneNumber";

import {
  GoogleButton,
  FacebookButton,
} from "../../../../components/SocialButtons";
import LoadingComponent from "../../../../components/utils/withLoading";

import { generatePKCE } from "../../../../Utils/generatePKCE";

import ReCAPTCHA from "react-google-recaptcha";
import { MenuContext } from "../../../../context";

import { Autocomplete } from '@react-google-maps/api'
import { Link } from "react-router-dom";
import { __AuthAPI__ } from "../../../../Infra/HttpServices";
import withNavigateHook from "../../../../components/WithNavigation";

import './style.scss';

class SignUp extends React.Component {
  reRef;

  autoComplete;
  place;

  constructor(props) {
    super(props);
    this.reRef = React.createRef();
    this.state = {
      ...this.props.initialState,
      focusedField: this.props.focusedField,
      submitting: false,
      sut: generatePKCE(),
      RecaptchaToken: "",
      acceptNotifs: false,
      acceptCguPc: false,
      address: "",
      Address: {},
      addressError: false
    };
  }
  componentDidMount() {
    this.GetToken();
  }

  GetToken = async () => {
    const token = await this.reRef.current.executeAsync();
    this.reRef.current.reset();

    this.setState({
      RecaptchaToken: token,
    });
  };

  setFieldValue(field, newValue) {
    const newState = {};
    newState[field] = newValue;
    this.setState(newState);
  }

  onSubmit() {
    if (this.state.submitting === true) return;
    if (!/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i.test(this.state.PhoneNumber)) return;
    this.setState(
      {
        submitting: true,
      },
      () =>
        this.props
          .onSubmit(this.state)
          .then(() => this.setState({ submitting: false }))
    );
    this.GetToken();
  }

  setAddressValue(field, newValue) {
    const newFormState = {
      ...this.state.Address
    }

    newFormState[field] = newValue

    this.setState({ Address: newFormState })
  }

  onPlaceChanged () {
    if (this.autoComplete === null)
      return
   
    this.place = this.autoComplete.getPlace();
    
    this.setState({...this.state, address: this.place.formatted_address})
    
    const newFormState = {
      ...this.state.Address,
      "longitude": '' + this.place.geometry.location.lng(),
      "latitude": '' + this.place.geometry.location.lat(),
      "routeNumber": this.place.address_components.find(adressComponent => adressComponent.types.find(value => value === "street_number")).long_name,
      "department": this.place.address_components.find(adressComponent => adressComponent.types.find(value => value === "administrative_area_level_1")).long_name,
      "country": "France",
      "countryShortName": "FR",
      "codePostal": this.place.address_components.find(adressComponent => adressComponent.types.find(value => value === "postal_code")).long_name,
      "address": this.place.formatted_address,
      "route": this.place.address_components.find(adressComponent => adressComponent.types.find(value => value === "route")).long_name,

      "jsonAddress": JSON.stringify(this.place)
    }

    try {
      this.setState({ Address: newFormState })
      this.setState({addressError: false})
    }catch(e) {
      this.setState({addressError: true})
    }
  }

  changeAddressText(text) {

    this.setState({
      address: text
    })

    delete this.state.Address.longitude
    delete this.state.Address.latitude
    delete this.state.Address.route
    delete this.state.Address.department
    delete this.state.Address.country
    delete this.state.Address.countryShortName
    delete this.state.Address.codePostal
    delete this.state.Address.address
    delete this.state.Address.jsonAddress

    this.setState({
      addressError: false
    })

  }

  onAutoCompleteLoad(autocomplete){
    this.autoComplete = autocomplete
  }

  initiateBackchannel() {
    return localStorage.setItem("sut", this.state.sut);
  }

  render() {
    return (
      <div className="signup-container">
        <div className="z-index">
          <ReCAPTCHA
            sitekey={this.props.RecaptchaPk}
            size={"invisible"}
            ref={this.reRef}
          />
        </div>
        <div className="form">
          {/* Form Header */}
          <div className="formHeader">
            <p className="formHeaderText">
              Bienvenue !
            </p>
            <p className="subTitle">
              Veuillez entrer vos informations pour créer un compte.
            </p>
          </div>

          {/* Form body */}
          <div
            onKeyPress={(event) => event.key === 'Enter' && this.onSubmit()}
          >
            <div className="fieldsContainer">
              {/* Fieldset */}
              {this.props.fieldsets.map((fieldset) => (
                <div key={fieldset.title} className="fieldSet">
                  {/* Fieldset Body */}
                  {fieldset.fields.map((field) => (
                    <div key={field.id} className="field">
                      {this.state[field.id] && (
                        <p className="bold text">{field.label}</p>
                      )}
                      {field.id === "PhoneNumber" ? (
                        <PhoneNumber
                          noContainer={true}
                          setValue={(value) =>
                            this.setFieldValue(field.id, value)
                          }
                          placeholder={field.label}
                        />
                      ) : (
                        <input
                          type={field.type}
                          placeholder={field.label}
                          onChange={(evt) =>
                            this.setFieldValue(field.id, evt.target.value)
                          }
                          onFocus={() =>
                            this.setState({ focusedField: field.id })
                          }
                          className="input"
                        />
                      )}

                      {this.props.errors[field.id] && (
                        this.props.errors[field.id].map((error) => (
                          <p key={error} className="error">
                            {error}
                          </p>
                        ))
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div className="fieldsContainer">
              <div className="fieldSet">
                <div className="field">
                  <p className="bold addressTitle">Adresse de facturation</p>
                  <Autocomplete
                    restrictions={{ country: "fr" }}
                    onPlaceChanged={() => this.onPlaceChanged()}
                    onLoad={(autocomplete) => this.onAutoCompleteLoad(autocomplete)}
                  >
                    <input
                      value={this.state.address}
                      onChange={(evt) => this.changeAddressText(evt.target.value)}
                      className="inputAddress"
                    />
                  </Autocomplete>
                </div>
                <div className="field">
                  <p className="bold labelText">Bâtiment</p>
                  <input onChange={(evt) => this.setAddressValue("batiment", evt.target.value)} placeholder="Bâtiment" className="input" />
                </div>
                <div className="field">
                  <p className="bold labelText">Porte</p>
                  <input onChange={(evt) => this.setAddressValue("porte", evt.target.value)} placeholder="Porte" className="input" />
                </div>
              </div>
              <div className="fieldSet">
                <div className="field">
                  <p className="bold labelText">Interphone</p>
                  <input onChange={(evt) => this.setAddressValue("interphone", evt.target.value)} placeholder="Interphone" className="input" />
                </div>
                <div className="field">
                  <p className="bold labelText">Escalier</p>
                  <input onChange={(evt) => this.setAddressValue("escalier", evt.target.value)} placeholder="Escalier" className="input" />
                </div>
                <div className="field">
                  <p className="bold labelText">Code Porte</p>
                  <input onChange={(evt) => this.setAddressValue("codePorte", evt.target.value)} placeholder="Code Porte" className="input" />
                </div>
              </div>
            </div>
          </div>

          {/* Form controls */}
          <div className="formControls">
            <div className="column">
              <div className="checkboxContainer">
                <input type='checkbox' checked={this.state.acceptCguPc} onChange={(event) => this.setFieldValue("acceptCguPc", event.target.checked)} className="checkbox" />
                <p className="text">
                  En continuant, vous acceptez notre
                  <Link to="/legal/pp" className="bold link">{" "}Politique de confidentialité{" "}</Link>
                  et
                  <Link to="/legal/tos" className="bold link">{" "}Conditions d{"'"}utilisations.</Link>
                </p>
              </div>
              {this.props.errors["acceptCguPc"] && (
                this.props.errors["acceptCguPc"].map((error) => (
                  <p key={error} className="error">
                    {error}
                  </p>
                ))
              )}
            </div>
            
            <div className="column">
              <div className="checkboxContainer">
                  <input type='checkbox' checked={this.state.acceptNotifs} onChange={(event) => this.setFieldValue("acceptNotifs", event.target.checked)} className="checkbox" />
                  <p className="text">
                    Accepter les notifications par sms/mail
                  </p>
              </div>
            </div>

            <button
              onClick={() => this.onSubmit()}
              className="submitButton"
            >
              {this.state.submitting ? (
                <img
                  src={"/static/img/tail-loading.svg"}
                  alt="loading"
                />
              ) : (
                <p>
                  CRÉER MON COMPTE
                </p>
              )}
            </button>
            <div className="acc-container">
              <p className="forgot">
                Vous avez déjà un compte ?
              </p>
              <button
                onClick={() => {
                  this.props.navigate("/login");
                }}
              >
                <p className="connect bold">
                  Connectez-vous.
                </p>
              </button>
            </div>
          </div>

          {/* Social login section*/}
          <div className="methodsContainer">
            <div className="fieldSetHeader">
              <p>
                {"Autres méthodes d'inscription"}
              </p>
            </div>

            <div className="auth-container">
              <FacebookButton
                onPress={() => {
                  this.initiateBackchannel();
                  window.open(
                    `${__AuthAPI__}/auth/social/challenge?provider=Facebook&sut=${this.state.sut}`
                  );
                  window.close();
                }}
              />

              <GoogleButton
                onPress={() => {
                  this.initiateBackchannel();
                  window.open(
                    `${__AuthAPI__}/auth/social/challenge?provider=Google&sut=${this.state.sut}`
                  );
                  window.close();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function SignUpWithFields(WrappedComponent) {
  return class extends React.Component {
    static contextType = MenuContext;

    render() {
      return <WrappedComponent {...this.props} fieldsets={SignUpFormModel} />;
    }
  };
}

function SignUpWithInitialState(WrappedComponent) {
  return class extends React.Component {
    static contextType = MenuContext;

    render() {
      let initialState = {};
      this.props.fieldsets.forEach((fieldset) =>
        fieldset.fields.forEach((field) => (initialState[field.id] = ""))
      );

      return (
        <WrappedComponent
          {...this.props}
          initialState={initialState}
          focusedField={this.props.fieldsets[0].fields[0].id}
        />
      );
    }
  };
}

function SignUpWithFetch(WrappedComponent) {
  return class extends React.Component {
    static contextType = MenuContext;

    constructor(props) {
      super(props);

      this.state = {
        redirect: false,
        errors: [],
        // redirectTo: "/",
      };
    }
    // componentDidMount() {
    //   if (this.props.location.state)
    //     this.setState({
    //       redirectTo: this.props.location.state.redirect,
    //     });
    // }

    async submitData(state) {
      // ToDo: disable form submit until post complete
      if(!state.acceptCguPc)
        return this.setState({
          errors: {
            acceptCguPc: ["Vous devez accepter notre Politique de confidentialité et Conditions d'utilisations."]
          },
      });

      let postData = {};
  
      this.props.fieldsets.forEach((fieldset) =>
        fieldset.fields.forEach(
          (field) => (postData[field.id] = state[field.id])
        )
      );

      postData["Address"] = state["Address"]

      const signUpResponse = await signUpHttp({
        ...postData,
        RecaptchaToken: state.RecaptchaToken,
        AcceptNotifs: state.acceptNotifs,
      });

      if (signUpResponse.errors)
        return this.setState({
          errors: signUpResponse.errors,
        });

      if (signUpResponse.error)
        return this.setState({
          error: signUpResponse.error,
        });

      if (signUpResponse.data) {
        this.context.authenticate(signUpResponse.data.token);
        await localStorage.setItem("signupEmail", postData.Email);
        return this.props.navigate("/");

        // return this.setState({
        //   redirect: true,
        // });
      }
    }

    render() {
      // return this.state.redirect ? (
      //   <Navigate to={this.state.redirectTo} />
      // ) : (
      //   <WrappedComponent
      //     {...this.props}
      //     errors={this.state.errors}
      //     onSubmit={(state) => this.submitData(state)}
      //   />
      // );
      return (
        <WrappedComponent
          {...this.props}
          errors={this.state.errors}
          onSubmit={(state) => this.submitData(state)}
        />
      );
    }
  };
}

function withRecaptcha(WrappedComponent) {
  return class extends React.Component {
    static contextType = MenuContext;

    constructor(props) {
      super(props);

      this.state = {
        pk: null,
        loading: true,
        error: null,
      };
    }

    async componentDidMount() {
      await this.setRecaptchaPk();
    }

    async setRecaptchaPk() {
      try {
        const data = await getRecaptchaPk();

        this.setState({
          RecaptchaPk: data.Pk,
          loading: false,
        });
      } catch (error) {
        this.setState({
          loading: false,
          error: "une erreur s'est produite",
        });
      }
    }

    render() {
      return (
        <LoadingComponent loading={this.state.loading}>
          <WrappedComponent
            {...this.props}
            error={this.state.error}
            RecaptchaPk={this.state.RecaptchaPk}
          />
        </LoadingComponent>
      );
    }
  };
}

export default withNavigateHook(
  withRecaptcha(
    SignUpWithFields(
      SignUpWithInitialState(SignUpWithFetch(SignUp))
    )
  )
);
