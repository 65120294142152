import React, { useContext, useState } from 'react'

import './style.scss'

export const CodePromotion = ({ onValidate, onCancel, fieldValue, setFieldValue, editable }) => {
    const [error, setError] = useState("")

    const validate = (value) => {
        if (value === "") {
            return null
        }
        if (!/^[a-zA-Z0-9]*$/i.test(value)) {
            return "code de promotion invalide"
        }

        return null
    }

    const setPromotionCode = (value) => {
        const error = validate(value)

        if (error)
            setError(error)
        else
            setError("")

        setFieldValue(value)
    }
    
    return (
        <div className='if-field'>
            <p className='if-fieldLabel'>Code de promotion</p>

            <div className='if-cp-container'>
                <input
                    value={fieldValue}
                    placeholder={"Entrez votre code de promotion"}
                    onChange={(evt) => setPromotionCode(evt.target.value)}
                    className="if-fieldInput"
                />
                <button
                    onClick={() => onValidate(fieldValue).catch((e) => setError(e.message))}
                    disabled={editable || fieldValue === ""}
                    id="confirm-code"
                    className='if-validateCodeButton'
                >
                    <p className='if-validateCodeButtonText'>Valider</p>
                </button>
                <button
                    onClick={() => onCancel()} 
                    disabled={!editable}
                    id="cancel-code"
                    className='if-cancelCodeButton'
                >
                    <p className='if-cancelCodeButtonText'>Annuler</p>
                </button>
            </div>
            {error && <p className='if-fieldError'>{error}</p>}
        </div>
    )
}

export default CodePromotion;