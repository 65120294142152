import React, { createContext, useContext, useState } from "react";

import { MenuContext } from "../../../../context";

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import Breadcrumb from "../../../../components/Breadcrumb";
import CardList from "../../../../components/CardList/CardList";
import IconList from "../../../../components/IconList";
import LoadingComponent from "../../../../components/utils/withLoading";
import Dialog from "../../../../components/Dialog";

import moment from "moment";

import ConfigModal from "../MenuConfig";
import { MODAL_ID } from "../../Config";

import { getRestaurant, getUserRestaurantScore, updateUserRestaurantScore } from "../../HttpServices";
import { __ImageHost__ } from "../../../../Infra/HttpServices";
import withNavigateHook from "../../../../components/WithNavigation";
import ClockLogo from '../../../../static/img/clock.svg';

import './style.scss';
import Modal from "../../../../components/Modal";

require("moment/locale/fr");
moment().locale("fr");

const RestaurantProfileUnavailableView = () => (
  <div>
    <p>Ce restaurant est actuellement indisponible</p>
  </div>
);

const RestaurantProfileView = ({ navigate, userScore }) => {
  const context = useContext(MenuContext)
  const scoreItems = 5;
  const [notif, setNotif] = useState(Boolean)
  const [region2, setRegion2] = useState(context.restaurant.region2)
  const [region3, setRegion3] = useState(context.restaurant.region3)
  const [region4, setRegion4] = useState(context.restaurant.region4)
  const [score, setScore] = useState(userScore)
  const image = context.restaurant.image;
  const [font, setFont] = useState(context.restaurant.restaurantInfoFrColor)
  const [fontFamilies, setFontFamilies] = useState(context.restaurant.familiesFrColor)
  const [open, setOpen] = useState(false)
  const [func, setFunc] = useState(null)
  const [item, setItem] = useState(null)
  const [configOpen, setConfigOpen] = useState(false)
  
  const showDialog = (item) => {    
    setItem(item)
    setConfigOpen(true);
  }

  const showCartConfirmation = (callback) => {
    setFunc(() => callback)
    setOpen(true);
  }

  const cancelPromotion = () => {
    if (context.remise && context.remise.IdPromo) {
      context.unApplyPromotion()
      toast.info("promotion inappliquée")
    }
  }

  const addItem = (item, context) => {
    context.addCartEntry({
      Id: item.Id,
      ProductId: item.Id,
      Type: "single",
      Name: item.Name,
      Image: item.Image,
      Price: item.Price,
      Allergenes: item.Allergenes,
      LoyaltyPointsPrice: item.LoyaltyPointsPrice,
      LoyaltyPointsProd: item.LoyaltyPointsProd,
      LoyaltyPointsRetrait: item.LoyaltyPointsRetrait,
      IdPcAchatOffert : item.IdPcAchatOffert,
      OfIsApply: item.OfIsApply,
      OfNbAc: item.OfNbAc,
      OfNbOf: item.OfNbOf,
      OfIsPc : item.OfIsPc
    })
  }

  const onCardPress = (item, index) => {
    // Item is a product
    if (item.Type === 0) {
      if (
        context.cart.length > 0 &&
        context.restaurant.id !== context.cart[0].RestaurantId
      )
        return showCartConfirmation((context) => {
          addItem(item, context)
          cancelPromotion()
        });

      cancelPromotion()
      return addItem(item, context);
    }

    // Item is a Menu (composable product)
    if (item.Type === 2 && item.Products.length > 0) {
      if (
        context.cart.length > 0 &&
        context.restaurant.id !== context.cart[0].RestaurantId
      )
        return showCartConfirmation(() => {
          showDialog(item)
        cancelPromotion()
      });

      cancelPromotion()
      return showDialog(item);
    }

    // Item is an empty category/product
    if (
      item.Type === 2 &&
      (item.Categories.length === 0 || item.Products.length === 0)
    ) {
      return;
    }

    // Item is a category
    context.pushNavigation({ index: index });
    return;
  };

  const popNavigation = (index) => {
    context.popNavigation(index)
  };

  const UpperCaseFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  };

  const handleScoreClick = (i, side) => {
    let val = i;

    if(side === "left" && val > 1) {
      val = val - 0.5;
    }
    
    if(!context.jwt) {
      return toast.warning("Vous devez être connecté pour noter");
    }

    updateUserRestaurantScore({id: context.restaurant.id, score: val})
      .then((bool) => {
        if(!bool) {
          toast.warning("Pour pouvoir noter le restaurant, vous devez au moins avoir une commande");
        }else {
          setScore(val)
        }
      });
  };

  const scores = () => {
    var rows = [];

    for(let i = 1; i <= scoreItems; i++) {
      if(score % 1 === 0.5 && i === score + 0.5) {
        rows.push(
          <div className="score-container" key={i}>
            <button
              onClick={() => handleScoreClick(i, "left")}
            >
              <div className="side"></div>
            </button>
            <button
              onClick={() => handleScoreClick(i, "right")}
            >
              <div className="side">
                <img src={require('../../../../static/img/star.png')} alt="star" />
              </div>
            </button>
            <img src={require('../../../../static/img/star_full.png')} alt="star" />
          </div>
        );
      }else if(i <= score) {
        rows.push(
          <div className="score-container" key={i}>
            <button
              onClick={() => handleScoreClick(i, "left")}
            >
              <div className="side"></div>
            </button>
            <button
              onClick={() => handleScoreClick(i, "right")}
            >
              <div className="side"></div>
            </button>
            <img src={require('../../../../static/img/star_full.png')} alt="star" />
          </div>
        );
      }else {
        rows.push(
          <div className="score-container" key={i}>
            <button
              onClick={() => handleScoreClick(i, "left")}
            >
              <div className="side"></div>
            </button>
            <button
              onClick={() => handleScoreClick(i, "right")}
            >
              <div className="side"></div>
            </button>
            <img src={require('../../../../static/img/star.png')} alt="star" />
          </div>
        );
      }
    }

    return rows;
  };

  return (
    <div className="restaurant-container">
      <div className="back-container">
        <button
          onClick={() => {
            navigate("/")
          }}
          className="bold"
        >
          Retour
        </button>
      </div>
      <div className="header">
        <div className="dummy"></div>
        <div className="image-container">
          <img src={`${image && image !== undefined ? __ImageHost__ + "/" + image : "" }`} alt="logo" />
        </div>
        <div className="infos">
          <p className="title bold">{context.restaurant.name}</p>
          <div className="score-info-container">
            <div className="score">
              {scores()}
            </div>
            {context.restaurant.IsOpen &&
              <p className="open-text">Ouvert</p>
            }
          </div>
          <div className="text-info-container">
            <img src={require("../../../../static/img/pin.png")} alt="pin" className="image" />
            <p className="bold">{context.restaurant.address}</p>
          </div>
          { context.restaurant.WorkHours && context.restaurant.WorkHours.length !== 0  && 
          <div className="text-info-container">
            <img src={ClockLogo} alt="clock" className="image" />
            <p className="shifts-text bold">
              {UpperCaseFirstLetter(moment().format("dddd"))} :{" "}

              { context.restaurant.WorkHours.map((shift, index) => (
                `${moment(shift.StartTime).format("HH:mm")}-${moment(shift.EndTime).format("HH:mm")} ${context.restaurant.WorkHours.length !== index + 1? ",": ""}`
              ))}
            </p>
          </div>}
          {!context.restaurant.IsOpen && (
            <p className="disp-text">Ce restaurant est indisponible</p>
          )}
        </div>
      </div>

      <Breadcrumb
        sections={context.verboseNavigation()}
        onPress={popNavigation}
        font={font}
      />
            
      <div className="row-container">
        <div className="menu-image-container">
          <div className="menu-container">
            <IconList
              list={context.diningMode === 'delivery' ? context.restaurant.deliveryMenu :  context.restaurant.menu}
              onPress={context.resetNavigation}
              activeId={context.activeFamily()}
              background={region3}
              font={fontFamilies}
            />
          </div>
        </div>

        <div className="cards-background">
          <div className="cards-container">
            {/* Filter menu based on dining mode */}
            <CardList
              cards={context.restaurant.activeMenuNode}
              onCardPress={onCardPress}
            />
          </div>
        </div>
      </div>

      <Modal
        id="Dialog"
        isOpen={open}
      >
        <Dialog
          close={() => setOpen(false)}
          context={context}
          onConfirm={func}
          message={
            "Vous devez vider le panier car il contient des produits d'un autre restaurant"
          }
        />
      </Modal>

      <Modal
        isOpen={configOpen}
        id="Menu"
      >
        <ConfigModal activeChoice={item} context={context} closeMenu={() => setConfigOpen(false)} />
      </Modal>
    </div>
  );
};

const RestaurantProfile = (props) => {
  if (!props.available) return <RestaurantProfileUnavailableView />;

  return <RestaurantProfileView available={props.available} {...props} />;
};

function getRestaurantProfile(WrappedComponent) {
  return class extends React.Component {
    // Context must be passed down explicitly
    static contextType = WrappedComponent.contextType;

    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        userScore: 0
      };
    }

    getRestaurant() {
      return getRestaurant(this.props.URLParams.id)
        .then((restaurant) => {
          this.context.setActiveRestaurant(restaurant);
          this.setState({
            restaurant: restaurant,
          });
        })
        .catch(err =>
          this.setState({
            error: "Une erreur s'est produite lors de la connexion",
          })
        )
        .catch((err) =>
          this.setState({
            error: "Une erreur s'est produite lors de la connexion",
          })
        );
    }

    getUserScore() {
      getUserRestaurantScore(this.props.URLParams.id)
        .then((score) => {
          this.setState({
            userScore: score,
            loading: false,
          });
        })
        .catch(err =>
          this.setState({
            error: "Une erreur s'est produite lors de la connexion",
            loading: false,
          })
        )
        .catch((err) =>
          this.setState({
            error: "Une erreur s'est produite lors de la connexion",
            loading: false,
          })
        );
    }

    componentDidMount() {
      return this.getRestaurant().then(() => this.getUserScore())
    }

    render() {
      return (
        <LoadingComponent loading={this.state.loading}>
          <WrappedComponent
            restaurant={this.state.restaurant}
            userScore={this.state.userScore}
            loading={this.state.loading}
            error={this.state.error}
            {...this.props}
          />
        </LoadingComponent>
      );
    }
  };
}

function checkRestaurantAvailability(WrappedComponent) {
  return class extends React.Component {
    static contextType = MenuContext;

    isAvailable() {
      const menu = this.context.diningMode === "delivery" ? this.context.restaurant.deliveryMenu : this.context.restaurant.menu

      if (!menu)
        return false

      if (menu.length === 0)
        return false

      return true
    }

    render() {
      return (
        <WrappedComponent {...this.props} available={this.isAvailable()} />
      );
    }
  };
}

export default withNavigateHook(
  getRestaurantProfile(checkRestaurantAvailability(RestaurantProfile)
  )
);
