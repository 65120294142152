import React from 'react';
import { __ImageHost__ } from '../../Infra/HttpServices';

import Card from '../Card/Card';

import './style.scss';

const CardList = ({ cards, onCardPress }) => (
  <div className='card-items-container'>
    {cards.map((card, index) =>
      <Card
        {...card}
        title={card.Name}
        type={card.Type}
        text={card.Description}
        price={card.Price}
        loyaltyPointsProd={card.LoyaltyPointsProd}
        loyaltyPointsRetrait={card.LoyaltyPointsRetrait}
        loyaltyPointsPrice={card.LoyaltyPointsPrice}
        key={card.Id}
        allergens={card.Allergenes}
        image={`${card.Image}`}
        onPress={() => onCardPress(card, index)}
        selectionCount={card.selectionCount || 0}
      />
    )}
  </div>
)

export default CardList;