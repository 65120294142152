export default [
  {
    title: "Réinitialiser votre mot de passe a travers votre email",
    fields: [
      {
        id: "Email",
        label: "Adresse Email",
      }
    ]
  }
]