import React from 'react'

import { ResendConfirmationEmailHttp } from '../../HttpServices'
import LoadingComponent from '../../../../components/utils/withLoading'

import { MenuContext } from '../../../../context'

import './style.scss'

class ResendConfirmationView extends React.Component {
  static contextType = MenuContext

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      success: false
    }
  }

  componentDidMount() {
    ResendConfirmationEmailHttp()
      .then(response => {
        if (response.data && response.data.success)
          return this.setState({
            message: `Un email de confirmation vous a été envoyé, veuillez vérifier votre boîte de réception pour activer votre compte.`,
            success: true,
            loading: false
          })
        
        return this.setState({
          message: "Une erreur s'est produite lors de l'envoie du mail de confirmation, veuillez reesayer plus tard",
          loading: false
        })
      })
      .catch(err => this.setState({
        message: "Une erreur s'est produite lors de l'envoie du mail de confirmation, veuillez reesayer plus tard",
        loading: false
      }))
  }

  render() {
    return (
      <div className='confirmation-container'>
        <LoadingComponent loading={this.state.loading}>
          {this.state.success && <img src={"/static/img/email.png"} alt="email" />}
          <p>{this.state.message}</p>
        </LoadingComponent>
      </div>
    )
  }
}

export default ResendConfirmationView