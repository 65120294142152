import React, { useState } from 'react'
import DatePicker from 'react-date-picker'

import "react-date-picker/dist/DatePicker.css"
import "./style.scss"

// Cross-cutting imports
import LoadingComponent from '../../../../components/utils/withLoading'

// Local imports specific to the order module
import { GetOrders } from '../../HttpServices'
import Profile from '../Thumbnail'
import { useContext } from 'react'
import { MenuContext } from '../../../../context'

const Filters = ({ dispatch, Style }) => {
  const [selected, setSelected] = useState(undefined)

  const isSelected = (filter) => selected && selected?.value === filter.value

  const filterList = [
    {
      label: "Tout",
      value: "",
      icon: require("../../../../static/img/all.png"),
      iconWhite: require("../../../../static/img/all_white.png")
    },
    {
      label: "Payées en ligne",
      value: "paid",
      icon: require("../../../../static/img/stripe-payment.png"),
      iconWhite: require("../../../../static/img/stripe-payment_white.png")
    },
    {
      label: "Payées au comptoir",
      value: "cashPayment",
      icon: require("../../../../static/img/cash-payment.png"),
      iconWhite: require("../../../../static/img/cash-payment_white.png")
    }
  ]

  return <div className='filters-container'>
    {filterList.map((filter, i) => (
      <button
        key={i}
        id={filter.value}
        onClick={() => { dispatch(filter.value); setSelected(filter) }}
        className={isSelected(filter) ? "buttonContainer containerActive": "buttonContainer"}
      >
        <img src={isSelected(filter) ? filter.iconWhite : filter.icon} alt="filter" className='order-image' />
        <p className={isSelected(filter)? "filterText filterTextActive": "filterText"}>{filter.label}</p>
      </button>
    ))}
  </div>
}

const FiltersMb = ({ dispatch, Style }) => {
  const filterList = [
    {
      label: "Mode paiment",
      value: "",
      icon: require("../../../../static/img/all.png"),
      iconWhite: require("../../../../static/img/all_white.png")
    },
    {
      label: "Payées en ligne",
      value: "paid",
      icon: require("../../../../static/img/stripe-payment.png"),
      iconWhite: require("../../../../static/img/stripe-payment_white.png")
    },
    {
      label: "Payées au comptoir",
      value: "cashPayment",
      icon: require("../../../../static/img/cash-payment.png"),
      iconWhite: require("../../../../static/img/cash-payment_white.png")
    }
  ]

  return (
    <select name="filters" id="filters-select" onChange={(e) => dispatch(e.target.value)} className="select">
      {filterList.map((filter, i) => (
        <option value={filter.value} key={i}>
          {filter.label}
        </option>
      ))}
    </select>
  )
}

const DiningModeFilter = ({ dispatch, Style }) => {
  const [selected, setSelected] = useState(undefined)

  const isSelected = (filter) => selected && selected?.value === filter.value

  const diningModeFilter = [
    {
      label: "Tout",
      value: "",
      icon: require("../../../../static/img/all.png"),
      iconWhite: require("../../../../static/img/all_white.png")
    },
    {
      label: "Sur Place",
      value: "onspot",
      icon: require("../../../../static/img/sur_place.png"),
      iconWhite: require("../../../../static/img/sur_place_white.png")
    },
    {
      label: "A Emporter",
      value: "takeaway",
      icon: require("../../../../static/img/emporter.png"),
      iconWhite: require("../../../../static/img/emporter_white.png")
    },
    {
      label: "Livraison",
      value: "delivery",
      icon: require("../../../../static/img/livraison.png"),
      iconWhite: require("../../../../static/img/livraison_white.png")
    }
  ]

  return <div className='filters-container'>
    {diningModeFilter.map((filter, i) => (
      <button
        id={filter.value}
        key={i}
        onClick={() => { dispatch(filter.value); setSelected(filter) }}
        className={isSelected(filter)? "buttonContainer containerActive": "buttonContainer"}
      >
        <img src={isSelected(filter) ? filter.iconWhite : filter.icon} alt="filter" className='order-image' />
        <p className={isSelected(filter)? "filterText filterTextActive": "filterText"}>{filter.label}</p>
      </button>
    ))}
  </div>
}

const DiningModeFilterMb = ({ dispatch, Style }) => {
  const diningModeFilter = [
    {
      label: "Mode livraison",
      value: "",
      icon: require("../../../../static/img/all.png"),
      iconWhite: require("../../../../static/img/all_white.png")
    },
    {
      label: "Sur Place",
      value: "onspot",
      icon: require("../../../../static/img/sur_place.png"),
      iconWhite: require("../../../../static/img/sur_place_white.png")
    },
    {
      label: "A Emporter",
      value: "takeaway",
      icon: require("../../../../static/img/emporter.png"),
      iconWhite: require("../../../../static/img/emporter_white.png")
    },
    {
      label: "Livraison",
      value: "delivery",
      icon: require("../../../../static/img/livraison.png"),
      iconWhite: require("../../../../static/img/livraison_white.png")
    }
  ]

  return (
    <select name="dining-filters" id="dining-filters-select" onChange={(e) => dispatch(e.target.value)} className="select">
      {diningModeFilter.map((filter, i) => (
        <option value={filter.value} key={i}>
          {filter.label}
        </option>
      ))}
    </select>
  )
}

const FavoriteFilter = ({ dispatch, Style }) => {
  const [favorite, setFavorite] = useState("true")
  const [icon, setIcon] = useState("heart_orange")

  const diningModeFilter = [
    {
      label: "Favoris",
      value: favorite,
      icon: require(`../../../../static/img/${icon}.png`),
    }
  ]

  return <div className='favoriteContainer'>
    {diningModeFilter.map((filter, i) => (
      <button
        id={filter.value}
        key={i}
        onClick={() => { dispatch(filter.value); favorite === "true" ? setFavorite("false") : setFavorite("true"); icon === "heart_orange" ? setIcon("heart_full") : setIcon("heart_orange") }}
        className="buttonContainer"
      >
        <img src={filter.icon} alt="filter" className='order-image' />
        <p className='favoriteFilterText'>{filter.label}</p>
      </button>
    ))}
  </div>
}

const DateRangeFilter = ({ dispatch, Style }) => {
  const [dateRange, setDateRange] = useState({ startDate: new Date(), endDate: new Date() })

  return (
    <div className='DateContainer'>
      <DatePicker
        onChange={(date) => {
          const newRange = { ...dateRange, startDate: date, endDate: new Date() }
          setDateRange(newRange)
          dispatch(newRange)
        }}
        format="dd/MM/yyyy"
        value={dateRange.startDate}
        maxDate={new Date()}
        calendarIcon={<img src={require("../../../../static/img/calendar.png")} alt="calendar" className='calendarIcon' />} 
      />
      <DatePicker
        onChange={(date) => {
          const newRange = { ...dateRange, endDate: date }
          setDateRange(newRange)
          dispatch(newRange)
        }}
        format="dd/MM/yyyy"
        value={dateRange.endDate}
        maxDate={new Date()}
        minDate={dateRange.startDate}
        calendarIcon={<img src={require("../../../../static/img/calendar.png")} alt="calendar" className='calendarIcon' />}
      />
    </div>
  )
}

const DataFilters = ({ dispatch, Style }) => {
  const [state, setState] = useState({})
  const context= useContext(MenuContext);

  const updateState = (newState) => {
    setState(newState)
    dispatch(newState)
  }
  
  return (
    <>
      <div className='DataFilters'>
        <Filters dispatch={(value) => updateState({ ...state, payment: value })} Style={Style} />
        <DiningModeFilter dispatch={(value) => updateState({ ...state, diningMode: value })} Style={Style} />
        <FavoriteFilter dispatch={(value) => updateState({ ...state, favorite: value })} Style={Style} />
        <DateRangeFilter dispatch={(value) => updateState({ ...state, dateRange: value })} Style={Style} />
      </div>
      <div className='DataFiltersMb'>
        {context.jwt &&
            <p className='HeaderTitleMb'>Bienvenue {context.user.firstName} !</p>
          }
        <p className='HeaderTextMb'>Trouvez ici vos commandes passées</p>
        <DateRangeFilter dispatch={(value) => updateState({ ...state, dateRange: value })} Style={Style} />
        <div className='DataFiltersModes'>
          <FiltersMb dispatch={(value) => updateState({ ...state, payment: value })} Style={Style} />
          <DiningModeFilterMb dispatch={(value) => updateState({ ...state, diningMode: value })} Style={Style} />
        </div>
        <FavoriteFilter dispatch={(value) => updateState({ ...state, favorite: value })} Style={Style} />
      </div>
    </>
  )
}

const OrdersListView = ({ orders, error, loadData, Style }) => {
  const context= useContext(MenuContext);

  return (
    <div className='Page'>
      {/* Page header */}
      <div className='PageHeader'>
        {context.jwt &&
          <p className='HeaderTitle'>Bienvenue {context.user.firstName} !</p>
        }
        <p className='HeaderText'>Trouvez ici vos commandes passées</p>

        <div className='FiltersMb'>
          <DataFilters dispatch={(filterState) => loadData(filterState)} Style={Style} />
        </div>
      </div>

      {/* Page Body */}
      <div className='PageBody'>

        {/* Filters */}
        <div className='Filters'>
          <DataFilters dispatch={(filterState) => loadData(filterState)} Style={Style} />
        </div>

        {/* Order list */}
        <div className='RestaurantList'>
          {/* Order list is not empty */}
          {orders.map(order => <Profile order={order} key={order.Id} />)}

          {/* Order list is empty */}
          {(orders.length === 0 && !error) &&
            <p className='Text'>Aucune commande à afficher</p>}

          {/* Error message */}
          {error &&
            <p className='Error'>{error}</p>
          }
        </div>
      </div>
    </div>
  )
}

function GetOrdersList(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        loading: true,
        error: "",
        orders: []
      }
    }

    componentDidMount() {
      return this.loadData({})
    }

    transformFilters(filter) {
      return {
        ...filter,
        startDate: filter && filter.dateRange ? filter.dateRange.startDate : undefined,
        endDate: filter && filter.dateRange ? filter.dateRange.endDate : undefined
      }
    }

    loadData(filter) {

      if (filter) {
        filter = {
          ...filter,
          startDate: filter && filter.dateRange ? filter.dateRange.startDate.toLocaleString("en-US") : undefined,
          endDate: filter && filter.dateRange ? filter.dateRange.endDate.toLocaleString("en-US") : undefined
        }
        delete filter.dateRange
      }


      return GetOrders(filter)
        .then(orders => {

          this.setState({
            orders: orders.map((order) => {
              return {
                ...order,
                OrderDetailed: JSON.parse(order.OrderDetailed),
                Remise: order.Remise != null? JSON.parse(order.Remise): null
              }
            }),
            loading: false
          })
        }


        )
        .catch(err =>
          this.setState({
            error: "Une erreur s'est produite lors de la connexion",
            loading: false
          })
        )
        .catch(err =>
          this.setState({
            error: "Une erreur s'est produite lors de la connexion",
            loading: false
          })
        )
    }

    render() {
      return (
        <LoadingComponent
          loading={this.state.loading}
        >
          <WrappedComponent
            orders={this.state.orders}
            loading={this.state.loading}
            loadData={(filters) => this.loadData(filters)}
            error={this.state.error}
            {...this.props}
          />
        </LoadingComponent>
      )
    }
  }
}

function NormalizeOrdersList(WrappedComponent) {
  return class extends React.Component {
    // Renders date in user locale
    NormalizeDate(orders) {
      return orders.map(order => {
        return {
          ...order,
          CreatedAt: new Date(order.CreatedAt).toLocaleString()
        }
      })
    }

    Normalize(orders) {
      return this.NormalizeDate(orders)
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          orders={this.Normalize(this.props.orders)}
        />
      )
    }
  }
}

export default GetOrdersList(NormalizeOrdersList(OrdersListView));
