import React, { useState, useEffect } from 'react';
import Modal from "../../../../components/Modal";
import CloseLogo from '../../../../static/img/letter-xblack.svg';
import "./style.scss";

const OrderModal = (props) => {
  const [details, setDetails] = useState([])
  const [remiseApplied, setRemiseApplied] = useState(false)
  const [priceWithoutPromo, setPriceWithoutPromo] = useState(0)
  const [fidelite, setFidelite] = useState(0)

  useEffect(() => {
    setDetails([])
    let comp = []
    let totalPrice = 0
    let fdl = 0

    props.order.OrderDetailed.map(o => {
      let price = 0

      if (o.Type === "composable") {
        o.Components.map(c => {
          if (c.hasOwnProperty('OriginalPrice')) {
            price += c.OriginalPrice * c.Quantity
          } else {
            price += c.Price * c.Quantity
          }

          if (c.hasOwnProperty('LoyaltyPointsProd')) {
            fdl += c.LoyaltyPointsProd
          }
        })
        o.Compositions.map(cp => {
          if (cp.hasOwnProperty('OriginalPrice')) {
            price += cp.OriginalPrice * cp.Quantity
          } else {
            price += cp.Price * cp.Quantity
          }
        })
        price *= o.Quantity
      } else {
        if (o.hasOwnProperty('OriginalPrice')) {
          price += o.OriginalPrice * o.Quantity
        } else {
          price += o.Price * o.Quantity
        }

        if (o.hasOwnProperty('LoyaltyPointsProd')) {
          fdl += o.LoyaltyPointsProd
        }
      }

      totalPrice += price

      let newObj = { name: o.Name, quantity: o.Quantity, price: price, id: o.Id }

      if (o.hasOwnProperty('Components'))
        newObj.components = o.Components

      if (o.hasOwnProperty('Compositions')) {
        newObj.components.map(item => {
          let addedCompositions = []
          let removedCompositions = []
          o.Compositions.map(oc => {
            if (oc.ComponentId === item.Id) {
              if (oc.IsDefault)
                removedCompositions.push(oc)
              else
                addedCompositions.push(oc)
            }
          })
          item.addedCompositions = addedCompositions
          item.removedCompositions = removedCompositions
        })
      }
      comp.push(newObj)
    })
    setPriceWithoutPromo(totalPrice)
    setFidelite(fdl)

    if (props.order.Remise != null) {
      setRemiseApplied(true)
    }

    setDetails(comp)
  }, [])

  return (
    <Modal
      isOpen={props.openModal}
      id={"orderDetails"}
    >
      <div onClick={() => props.setOpenModal(false)} className="details-modal-container">
        <div className='dialog-container'>
          <div className='header'>
            <p>Récapitulatif :</p>
          </div>
          <button onClick={() => props.setOpenModal(false)} className="close-button">
            <img src={CloseLogo} alt="close" />
          </button>
          <div className='items-container'>
            {details.map((oc, index) =>
              <div key={index} className="recap-elems-container">
                <div className='section-container'>
                  <p className='bold'>{`${oc.quantity} x ${oc.name}`}</p>
                  {!oc.hasOwnProperty('components') &&
                    <p className='bold'>
                      {oc.hasOwnProperty('components') ? `${(oc.components[0].hasOwnProperty('OriginalPrice') ? oc.components[0].OriginalPrice * oc.quantity : oc.components[0].Price * oc.quantity).toFixed(1)} €` : `${(oc.price).toFixed(1)} €`}
                    </p>
                  }
                </div>
                {oc.hasOwnProperty('components') && oc.components.map((c, i) =>
                  <div key={i} className="comp-container">
                    <div className='section-container'>
                      {c.Quantity <= 1 ? <p>{`${c.Name}`}</p> : <p>{`${c.Quantity} x ${c.Name}`}</p>}
                      <p>{`${(c.hasOwnProperty('OriginalPrice') ? c.OriginalPrice * c.Quantity : c.Price * c.Quantity).toFixed(1)} €`}</p>
                    </div>
                    <div className='compo-container'>
                      {c.hasOwnProperty('addedCompositions') && c.addedCompositions.map((cmp, i) =>
                        <div key={i} className="price-compo-container">
                          <p>{`+ ${cmp.Quantity} x ${cmp.Name}`}</p>
                          <p>
                            {`${(cmp.hasOwnProperty('OriginalPrice') ? cmp.OriginalPrice * cmp.Quantity : cmp.Price * cmp.Quantity).toFixed(1)} €`}
                          </p>
                        </div>
                      )}
                      {c.hasOwnProperty('removedCompositions') && c.removedCompositions.map((cmp, i) =>
                        <p key={i} className="red-text">{`- ${cmp.Name}`}</p>
                      )}
                    </div>
                  </div>
                )}
                {oc.hasOwnProperty('components') && <p className='final-price'>{`${(oc.price).toFixed(1)} €`}</p>}
              </div>
            )}
            {fidelite !== 0 &&
              <div className='section-container'>
                <p className='bold pink-text'>Fidélité</p>
                <p className='bold pink-text'>{`+ ${fidelite} points`}</p>
              </div>
            }
            {remiseApplied !== true &&
              <div className='section-container'>
                <p className='bold'>Total</p>
                <p className='bold'>{`${(priceWithoutPromo).toFixed(1)} €`}</p>
              </div>
            }
            {remiseApplied === true &&
              <div className='before-remise'>
                <p className='bold'>{`${(priceWithoutPromo).toFixed(1)} €`}</p>
              </div>
            }
            {remiseApplied === true &&
              <div className='section-container'>
                <p className='bold'>Remise</p>
                <p className='bold red-text'>{`- ${props.order.Remise.Mnt} ${props.order.Remise.IsPct === true ? "%" : "€"}`}</p>
              </div>
            }
            {remiseApplied === true &&
              <div className='section-container'>
                <p className='bold'>Total</p>
                <p className='bold'>{`${(props.order.Price).toFixed(1)} €`}</p>
              </div>
            }
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default OrderModal;