import React, { useContext, useState } from 'react';
import Modal from '../.../../../../../components/Modal'

import AllergenesView from '../../../Restaurant/Views/MenuConfig/AllergenesView';
import { MenuContext } from '../../../../context';
import CartDialog from '../../../../components/CartDialog';
import { __ImageHost__ } from '../../../../Infra/HttpServices';
import TrashIcon from "../../../../static/img/trash.svg";
import './style.scss';

 const SingleProduct = ({ Style, Id, Name, Quantity, Image, totalCost, incrementQuantity,decrementQuantity, Allergenes, IsLoyaltyApplyed, LoyaltyPointsRetrait, loyaltyPoints, deleteEntry }) => {
  const [displayAllergenes, setDisplayAllergenes] = useState(false)
  const context = useContext(MenuContext)
  const [func, setFunc] = useState(null)
  const [open, setOpen] = useState(false)

  const showCartConfirmation = (callback) => {
    setFunc(() => callback)
    setOpen(true);
  }

  return (
    <div className='product-component-container'>
      {/* Product Header */}
      <div className="product-header">
        <p className="product-quantity" >{Quantity > 1 && Quantity}</p>
        <p className="product-title bold">{Name}</p>
        <p className="product-price">{`PRIX : ${totalCost} €`}</p>
        <div className="product-edit">
          <button onClick={() => showCartConfirmation(() => deleteEntry(Id))}>
            <img src={TrashIcon} alt="trash" />
          </button>
        </div>
      </div>

      {/* Product Components */}
      <div className="component-list">
        <div className="component">
          <img src={`${Image}`} width="150px" alt="product" className='component-image' />
          <p>{Name}</p>

          {(!IsLoyaltyApplyed) && LoyaltyPointsRetrait > 0 &&
            <button onClick={() => context.applyLoyalty(Id, loyaltyPoints)} className="compo-loyalty-button">
              <p>{LoyaltyPointsRetrait} pts</p>
            </button>
          }
          {(IsLoyaltyApplyed) && LoyaltyPointsRetrait > 0 &&
            <button onClick={() => context.disApplyLoyalty(Id)} className="used-loyalty-button">
              <p>{LoyaltyPointsRetrait} pts</p>
            </button>
          }
          {Allergenes &&
            <button
                onClick={(e) => {
                  e.stopPropagation()
                  setDisplayAllergenes(true)
                }}
                className="card-button-allergen"
              >
              <p>allergènes</p>
            </button>
          }
        </div>
      </div>

      {/* Product controls */}
      <div className="product-controls">
        <button
          onClick={() => decrementQuantity(Id)}
          className="product-quantity-button"
        >
          <p>-</p>
        </button>
        <button
          onClick={() => incrementQuantity(Id)}
          className="product-quantity-button"
        >
          <p>+</p>
        </button>
      </div>

      <Modal
        isOpen={displayAllergenes}
        id="Allergen"
      >
        <AllergenesView 
          allergenes={Allergenes}
          onClose={() => setDisplayAllergenes(false)}
        />
      </Modal>

      <Modal
        isOpen={open}
        id="Delete"
      >
        <CartDialog
          close={() => setOpen(false)}
          onConfirm={func}
          message={
            "Supprimer le produit ?"
          }
        />
      </Modal>
    </div>
  )
}

export default SingleProduct;