import React from "react"
import { Link } from "react-router-dom"
import withNavigateHook from "../../../components/WithNavigation"
import { MenuContext } from "../../../context"
import './style.scss';

class HomeView extends React.Component {
  static contextType = MenuContext

  OrderOnPress() {
    if (this.context.isAuthenticated()) {
      this.props.navigate("/user/commande")
    }
    else {
      this.props.navigate("/login")
    }
  }

  LoyaltyOnPress() {
    if (this.context.isAuthenticated()) {
      this.props.navigate("/user/fidelite")
    }
    else {
      this.props.navigate("/login")
    }
  }

  RsOnPress() {
    window.open('https://www.instagram.com/french_innov/?hl=fr', '_blank');
  }

  render() {
    return (
      <div className="page-container">
        {/*TODO: Redo graphic chart*/}
        <div className="banner"></div>
        <div className="page">
          <div className="bannerContainer">
            <div className="orderContainer">
              <p className="order-title">Cherchez un restaurant, commandez et gagnez des points avec FIOEAT !</p>
              <button onClick={() => this.props.navigate("/search")}>
                <p>Trouver un restaurant</p>
              </button>
            </div>
          </div>
          <div className="bodyContainer">
            <button onClick={() => this.OrderOnPress()}>
              <div className="textContainer">
                <p>
                  Vos commandes
                </p>
              </div>
              <img src={require("../../../static/img/commandes.png")} alt="order" />
            </button>
            <button>
              <div className="textContainer">
                <p>
                  Nos promotions
                </p>
              </div>
              <img src={require("../../../static/img/promo.png")} alt="promo" />
            </button>
            <button>
              <div className="textContainer">
                <p>
                  Nos nouveautés
                </p>
              </div>
              <img src={require("../../../static/img/nouveautes.png")} alt="nouveautés" />
            </button>
            <button onClick={() => this.LoyaltyOnPress()}>
              <div className="textContainer">
                <p>
                  Votre fidélité
                </p>
              </div>
              <img src={require("../../../static/img/loyalty.png")} alt="loyalty" />
            </button>
            <button className="alignItemsCenter">
              <div className="textContainer">
                <p>
                  Actualités
                </p>
              </div>
              <img src={require("../../../static/img/actualite.png")} alt="news" className="actuButtonImage" />
            </button>
            <button onClick={() => this.RsOnPress()} className="alignItemsCenter">
              <Link to="https://www.instagram.com/french_innov/?hl=fr" className="rsButton">
                <p>
                  Suivez-nous sur insta !
                </p>
              </Link>
              <img src={require("../../../static/img/instagram.png")} alt="insta" className="rsButtonImage" />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default withNavigateHook(HomeView);
