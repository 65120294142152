import React from "react";
import './style.scss';

const QrCode = ({ qrCode, close }) => {
  return (
    <div onClick={() => close()} className="qrcode-container">
      <div className="qrcode">
        <img src={`data:image/png;base64,${qrCode}`} alt="qrCode" />
      </div>
    </div>
  );
};

export default QrCode;
