import React from 'react';
import FormHOC from '../../../Utils/Forms'
import { LoyaltyPointsCustomerInfoHttpService, QrCodeAndSecretKeyUserInfoHttpService } from '../HttpServices'

import ProfileNavigation from './ProfileNavigation'
import { MenuContext } from '../../../context'
import QrCode from '../../Order/Views/Thumbnail/QrCode';
import withNavigateHook from '../../../components/WithNavigation';
import './style.scss';
import Modal from '../../../components/Modal';

class CustomerFidelite extends React.Component {
  static contextType = MenuContext

  constructor(props) {
    super(props);

    this.state = {
      focusedField: null,
      pv: "",
      formState:{
        qrCode: "",
        loyaltySecretKey: "",
      
      },
      open: false,
      loading: true,
      rows: []
    }
  }

  loadData(nameLike) {
    return LoyaltyPointsCustomerInfoHttpService({nameLike: nameLike}).then((response) => {
      if (response && response !== null)
        return this.setState({
          rows: response,
          loading: false,
        });
    });
  }

  componentDidMount() {
    return this.loadData("");
  }

  async componentDidUpdate(prevProps) {
    if (await this.context.asyncIsAuthenticated()) {
      if (this.props.formData !== prevProps.formData)
        this.setState({ formState: {...this.state.formState, ...this.props.formData} })
    }
    else {
      this.props.navigate("/login")
    }
  }

  inputChange(e) {
    this.setState({
      pv: e.target.value
    })
    this.loadData(e.target.value)
  }

  toggleQrCode(qrCode){
    this.setState({
      open: true
    })
  }
  render() {
    return this.state.loading ? (
      <p>loading....</p>
    ) : (
      <div className='u-Page'>
        <div className='u-Container'>
          <div className='u-NavContainerFidel'>
            <ProfileNavigation navigate={this.props.navigate} />
          </div>
          <div className='u-formContainer'>
            <div className='u-formFidel'>
              {/* Form body */}
                
              <div className='u-fieldSet'>
                {/* Fieldset Body */}
                <div className='u-fieldloyaltySetBody'>

                  <p className='u-subTitle'>Scannez le QR code pour profiter des avantages Fidélité!</p>
                
                  <div className='u-imageContainer'>
                    {this.state.formState.qrCode && this.state.formState.qrCode !== null?
                      <button onClick={() => this.toggleQrCode(this.state.formState.qrCode)}> 
                        <img src={`data:image/png;base64,${this.state.formState.qrCode}`} alt="qrcode" className='u-qrCodeIconMb' />
                        <img src={require("../../../static/img/qr-code-icon.png")} alt="qrcode" className='u-qrCodeIcon' />
                      </button>:
                      <p className='u-text'>Pas de QR code disponible</p>
                    }
                  </div>
                  <div className='u-fieldloyalty'>
                    <p className='u-title'>Code Secret</p>
                    {this.state.formState.loyaltySecretKey && this.state.formState.loyaltySecretKey !== "" ?
                      <p className='u-text'>{this.state.formState.loyaltySecretKey}</p>:
                      <p className='u-text'>Pas de code secret</p>
                    }
                  </div>

                  <input
                    className='u-search'
                    value={this.state.pv}
                    onChange={(e) => this.inputChange(e)}
                    placeholder="Vérifiez vos points de fidélité par point de vente..."
                  />
                
                  {this.state.rows && this.state.rows.length > 0?
                    <div className='u-table'>
                      {this.state.rows.map(r => {
                          return (
                            <div className='u-tableRow'>
                              <div className='u-restaurantColumn'>
                                <p className='u-subTitle'>{r.RestaurantName}</p>
                              </div>
                              <div className='u-pointColumn'>
                                <p className='u-fidelText'>{r.TotalPoints} Points</p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>:

                    <>
                      <p className='u-noFidel'>Vous n'avez pas encore de points de fidélité.</p>
                      <p className='u-noFidel'>Trouvez un restaurant, passez une commande et gagnez des points !</p>
                    </>
                  }

                </div>
              </div>
            </div>
          </div>
         
        </div>
        <Modal
          isOpen={this.state.open}
          id="qrCode"
        >
          <QrCode qrCode={this.state.formState.qrCode} close={() => this.setState({open: false})} />
        </Modal>
      </div>
    )
  }
}

export default FormHOC(
  {
    prefill: QrCodeAndSecretKeyUserInfoHttpService
  },
  withNavigateHook(CustomerFidelite)
);