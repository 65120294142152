import React from 'react';
import QueryString from "query-string";

import './style.scss';
import { MenuContext } from '../../../../../../context';

const SignUpSuccess = () =>
  <div className='signup-callback-container'>
    <p>Votre compte a été activé</p>
  </div>

const SignUpFailure = () =>
  <div className='signup-callback-container'>
    <p>{'Impossible d\'activer votre compte.'}</p>
  </div>


class SignupCallback extends React.Component {
  static contextType = MenuContext

  constructor(props) {
    super(props);

    this.state = {
      status: ""
    }
  }

  componentDidMount() {
    const status = QueryString.parse(this.props.location.search).status

    if (status === "success")
      this.context.hydrateAuthentication()

    this.setState({
      status: status
    })
  }

  render() {
    return this.state.status === "success"
      ? <SignUpSuccess />
      : <SignUpFailure />
  }
}

export default SignupCallback

