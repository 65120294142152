import React from 'react';

import FormHOC from '../../../Utils/Forms'

import { ChangeEmailHttpService } from '../HttpServices'
import EmailReset from '../Models/EmailReset'

import ProfileNavigation from './ProfileNavigation'
import AccountStatus from './AccountStatus';
import { MenuContext } from '../../../context';
import withNavigateHook from '../../../components/WithNavigation';
import './style.scss';

// TODO: Make a form renderer HOC, finally closer to a model first arch
class EmailChangeView extends React.Component {
  static contextType = MenuContext
  constructor(props) {
    super(props);

    this.state = {
      focusedField: null,
      formState: {}
    }
  }


  async componentDidMount() {
    if (await !this.context.asyncIsAuthenticated()) {
      this.props.navigate("/login")
    }
  }

  setFieldValue(field, newValue) {
    const newFormState = {
      ...this.state.formState
    }
    newFormState[field] = newValue

    this.setState({ formState: newFormState })
  }

  render() {
    return (
      <div>
        <AccountStatus />
        <div className='u-Container'>
          <div className='u-Header'>
            <div className='u-NavContainer'>
              <ProfileNavigation navigate={this.props.navigate} />
            </div>

            <div className='u-firstForm'>
              {/* Fieldset */}
              {EmailReset.fieldsets.map(fieldset =>
                <div key={fieldset.title} className="u-fieldSet">

                {/* Fieldset Body */}
                  {fieldset.fields.map(field =>
                    <div key={field.id} className="u-field">
                      <input
                        type={field.type}
                        placeholder={field.label}
                        value={this.state[field.id]}
                        onFocus={() => this.setState({ focusedField: field.id })}
                        onChange={(evt) => this.setFieldValue(field.id, evt.target.value)}
                        className="u-input"
                      />

                      {this.props.errors[field.id] &&
                        <div>
                          {this.props.errors[field.id].map((error) =>
                            <p key={error} className="u-error">{error}</p>
                          )}
                        </div>}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <button onClick={() => this.props.Submit(this.state.formState)} id="confirm-email" className='u-submitButtonMb'>
            {this.props.loading
              ? <img src={"/static/img/tail-loading.svg"} alt="loading" className='tl-img' />
              : <p className='u-submitButtonText'>CONFIRMER MA MODIFICATION</p>
            }
          </button>

          <div className='u-formContainerDesk'>
            <div className='u-form'>
              {/* Form body */}
              <div>
                {/* Fieldset */}
                {EmailReset.fieldsets.map(fieldset =>
                  <div key={fieldset.title} className="u-fieldSet">

                    {/* Fieldset Body */}
                    <div className='u-fieldSetBody'>
                      {fieldset.fields.map(field =>
                        <div key={field.id} className="u-field">
                          <p>{field.label}</p>
                          <input
                            type={field.type}
                            placeholder={field.label}
                            value={this.state[field.id]}
                            onFocus={() => this.setState({ focusedField: field.id })}
                            onChange={(evt) => this.setFieldValue(field.id, evt.target.value)}
                            className="u-input"
                          />

                          {this.props.errors[field.id] &&
                            <div>
                              {this.props.errors[field.id].map((error) =>
                                <p key={error} className="u-error">{error}</p>
                              )}
                            </div>}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {this.props.error &&
                <div>
                  <p key={this.props.error} className="u-error">{this.props.error}</p>
                </div>
              }

              <div className='u-formControls'>
                <button onClick={() => this.props.Submit(this.state.formState)} id="confirm-email" className='u-submitButton'>
                  {this.props.loading
                    ? <img src={"/static/img/tail-loading.svg"} alt="loading" className='tl-img' />
                    : <p className='u-submitButtonText'>CONFIRMER MA MODIFICATION</p>
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FormHOC(
  {
    successRedirect: "/user/email/change/message",
    action: ChangeEmailHttpService
  },
  withNavigateHook(EmailChangeView)
);