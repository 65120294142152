import React from 'react';
import LoadingIcon from '../../static/img/loading.svg'
import './style.scss';

const withLoading = (props) => (
  props.loading
    ? <div className="loading-container">
      <img src={LoadingIcon} alt="loading" />
    </div>
    : props.children
)

withLoading.displayName = 'withLoading';

export default withLoading;