import { getAuthToken } from '../Utils/Auth';
import { getRestaurant } from '../apps/Restaurant/HttpServices'
import { BasicUserInfoHttpService } from '../apps/User/HttpServices';
import { GetCartHttpService } from '../apps/Cart/HttpServices';

export const BootstrapState = async function() {
  let state = {}

  state = await InitializeAuth({})
  state = await InitializeCart(state)
  state = await InitializeRestaurant(state)

  return state
}

export const InitializeAuth = async function (state) {
  const jwt = await getAuthToken()
 
  if (!jwt)
    return {...state}

  const user = await BasicUserInfoHttpService()

  return {
    ...state,
    jwt: jwt,
    user: user
  }
}

// TODO: Implement logged in flow
export const InitializeCart = async function (state) {
  const cartId = await localStorage.getItem('cartId')
  let cart = undefined
 
  if (!cartId)
    return { ...state }

  // TODO: Handle case when cart no longer exists (delete cartId from localStorage)
  cart = await GetCartHttpService(cartId)  
  
  return {
    ...state,
    cart: JSON.parse(cart.Content),
    cartId: cartId,
    cartDiningMode: cart.DiningMode,
    diningMode: cart.DiningMode,
    cartRestaurantId: cart.RestaurantId,
    remise: {
      IdPromo: cart.PromotionId,
      CodePromo: cart.CodePromotion,
    }
  }
}

export const InitializeRestaurant = async function (state) {
  if (!state.cartRestaurantId)
    return { ...state }

  try {
    const restaurant = await getRestaurant(state.restaurant.id)    

    if (!restaurant)
      return { ...state }

    return {
      ...state,
      restaurant: {
        name: restaurant.Name,
        address: restaurant.Address,
        phoneNumber: restaurant.PhoneNumber,
        IsOpen: restaurant.IsOpen,
        IsStripeActive: restaurant.IsStripeActive,
        IsOnSpotPaymentActive: restaurant.IsOnSpotPaymentActive,
        id: restaurant.Id,
        publicStripeKey: restaurant.StripePublicApiKey,
        latitude: restaurant.Latitude,
        longitude: restaurant.Longitude,
        idMachine: restaurant.IdMachine,
        idPv: restaurant.IdPv,
        idRestau: restaurant.IdRestau,
        idUser: restaurant.IdUser,
        deliveryRadius: restaurant.DeliveryRadius,
        activeMenuNode: [],
        menu: restaurant.MenuJSON ? JSON.parse(restaurant.MenuJSON) : null,
        deliveryMenu: restaurant.MenuJSON ? JSON.parse(restaurant.MenuJSON).filter(item => item.IsDelivrable) : null,
        menuNavigator: [{ index: 0 }],
        WorkHours: restaurant.WorkHours,
        availableHours: restaurant.availableHours,
        region1: restaurant.Region1,
        region2: restaurant.Region2,
        region3: restaurant.Region3,
        region4: restaurant.Region4,
        headerFrColor: restaurant.HeaderFrColor,
        restaurantInfoFrColor: restaurant.RestaurantInfoFrColor,
        familiesFrColor: restaurant.FamiliesFrColor,
        decalage: restaurant.Decalage,
        decalageDelivery: restaurant.DecalageDelivery,
        cartThreshold: restaurant.CartThreshold
      },
      DINING_MODES: [
        {
          id: "onspot",
          label: "Sur place",
          logo: require("../static/img/sur_place.png"),
          disabled: !restaurant.SupportsOnSpot,
          selected: state.cartDiningMode === "onspot",
        },
        {
          id: "takeaway",
          label: "A emporter",
          logo: require("../static/img/emporter.png"),
          disabled: !restaurant.SupportsPickUp,
          selected: state.cartDiningMode === "takeaway",
        },
        {
          id: "delivery",
          label: "Livraison",
          logo: require("../static/img/livraison.png"),
          disabled: !restaurant.SupportsDelivery,
          selected: state.cartDiningMode === "delivery",
        }
      ]
    }
  } catch (e) {
    return { ...state }
  }
}