import React from 'react';

import queryString from 'query-string'

import FormHOC from '../../../Utils/Forms'

import { PasswordForgotCallbackHttpService } from '../HttpServices'
import PasswordForgotCallback from '../Models/PasswordForgotCallback'
import withNavigateHook from '../../../components/WithNavigation';

import './style.scss';

class PasswordForgotView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedField: null,
      formState: {}
    }
  }

  setFieldValue(field, newValue) {
    const newFormState = {
      ...this.state.formState
    }
    newFormState[field] = newValue

    this.setState({ formState: newFormState })
  }

  Submit() {
    this.props.Submit({
      ...this.state.formState,
      token: this.props.URLParams.token,
      uid: this.props.URLParams.uid
    })
  }

  render() {
    return (
      <div className='u-Container'>
        <div className='u-form'>
          {/* Form body */}
          <div>
            {/* Fieldset */}
            {PasswordForgotCallback.map(fieldset =>
              <div key={fieldset.title} className="u-fieldSet">
                {/* Fieldset Header */}
                <div className='u-fieldSetHeader'>
                  <p className='u-fieldSetHeaderText'>{fieldset.title}</p>
                </div>

                {/* Fieldset Body */}
                <div className='u-fieldSetBody'>
                  {fieldset.fields.map(field =>
                    <div key={field.id} className="u-field">
                      <input
                        type={field.type}
                        placeholder={field.label}
                        value={this.state[field.id]}
                        onFocus={() => this.setState({ focusedField: field.id })}
                        onChange={(evt) => this.setFieldValue(field.id, evt.target.value)}
                        className="u-input"
                      />

                      {this.props.errors[field.id] &&
                        <div>
                          {this.props.errors[field.id].map((error) =>
                            <p key={error} className="u-error">{error}</p>
                          )}
                        </div>}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {this.props.error &&
            <div>
              <p key={this.props.error} className="u-error">{this.props.error}</p>
            </div>
          }

          <div className='u-formControls'>
            <button onClick={() => this.Submit()} className="u-submitButton">
              {this.props.loading
                ? <img src={"/static/img/tail-loading.svg"} alt="loading" className='tl-img' />
                : <p className='u-submitButtonText'>Envoyer</p>
              }
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default FormHOC(
  {
    successRedirect: "/user/forgot-password/callback/message",
    action: PasswordForgotCallbackHttpService
  },
  withNavigateHook(PasswordForgotView)
);