import React, { useContext } from 'react'

import RedirectAfter from '../../../../../Utils/Router'
import { MenuContext } from '../../../../../context'

import '../style.scss'

const PaymentSuccess = () => {
  const context = useContext(MenuContext)

  return (
    <div className='success-message-container'>
      <img src={require("../../../../../static/img/paymentCard.png")} alt="payment-card" className='sm-image' />
      <p className='sm-text'>Nous avons bien reçu votre commande, veuillez vous dériger vers le point de vente.</p>
      <p className='sm-text'>{'Pour plus d\'information veuillez contacter '} {context.restaurant.phoneNumber}</p>
    </div>
  )
}

export default RedirectAfter(
  {
    after: 5000,
    to: "/user/commande/",
  },
  PaymentSuccess
)