import React from 'react'

import CashForm from './Cash'
import TakeAwayForm from './TakeAway'
import OnSpotForm from './OnSpot'
import DeliveryForm from './Delivery'

export default (props) => {
  const { diningMode, paymentMethod } = props

  if (paymentMethod === 1)
    return <CashForm {...props} />

  if (paymentMethod === 2) {
    if (diningMode === "onspot")
      return <OnSpotForm {...props} />

    if (diningMode === "takeaway")
      return <TakeAwayForm {...props} />

    if (diningMode === "delivery")
      return <DeliveryForm {...props} />
  }

  return null
}