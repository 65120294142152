import React from 'react';
import { __ImageHost__ } from '../../../../../Infra/HttpServices';
import './style.scss';

const SelectionView = ({ cart, cost }) => {
  return (
    <div className='choices'>
      <div className='choices-products'>
        {cart.reverse().map((item, index) => (
          <div key={index} className="choice">
            {item.Quantity > 1 &&
              <p className='choice-count'>{`x${item.Quantity}`}</p>
            }
            <div className='image-container'>
              <img width="150px" src={`${item.Image}`} alt="product" />
            </div>
          </div>
        ))}
      </div>

      {cart.length <= 0 &&
        <p className='choice-cost mb-only'>Vous n'avez encore ajouté aucun produit.</p>
      }
      
      <p className='choice-cost'>Total : {cost} €</p>
      
    </div>
  )
}

function ReverseCart(WrappedComponent) {
  return class extends React.Component {
    reversedCart() {
      // Construct a new array, reverse changes the original array
      return [...this.props.cart].reverse()
    }

    render() {
      return (
        <WrappedComponent {...this.props} cart={this.reversedCart()} />
      )
    }
  }
}

export default ReverseCart(SelectionView);