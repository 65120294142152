import React, { useContext } from 'react'

import { MenuContext } from '../../../../context'

import TakeAwayMessage from './TakeAway'
import OnSpotMessage from './OnSpot'
import DeliveryMessage from './Delivery'

export default (props) => {
  const context = useContext(MenuContext)

  if(context.diningMode === "onspot")
    return <OnSpotMessage phoneNumber={context.restaurant.phoneNumber} {...props} />
  
  if(context.diningMode === "takeaway")
    return <TakeAwayMessage phoneNumber={context.restaurant.phoneNumber} {...props} />

  if(context.diningMode === "delivery")
    return <DeliveryMessage phoneNumber={context.restaurant.phoneNumber} {...props} />

  return null
}