import React from 'react';
import CloseIcon from "../../../../../../static/img/letter-xblack.svg";
import './style.scss';

const CardDesc = ({ description }) => (
    <div className='card-description-container'>
        <div className='cd-dialog-container'>
            <button className='cd-dialog-button'>
                <img src={CloseIcon} alt="close" className='cd-dialog-icon' />
            </button>
            <p className='desc-text'>
                {description}
            </p>
        </div>
    </div>
)

export default CardDesc;
