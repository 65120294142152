export default {
  fieldsets: [
    {
      title: "Changer votre mot de passe",
      fields: [
        {
          id: "CurrentPassword",
          label: "Votre mot de passe *",
          type: "password"
        },
        {
          id: "NewPassword",
          label: "Nouveau mot de passe *",
          type: "password"
        },
        {
          id: "ConfirmPassword",
          label: "Confirmation du mot de passe *",
          type: "password"
        }
      ]
    }
  ]
}