import React from 'react';

import { requireAuth } from '../../../Utils/Auth'

import { FacebookIcon, GoogleIcon } from '../../../components/SocialIcons'

import ProfileNavigation from './ProfileNavigation'
import AccountStatus from './AccountStatus';

import './style.scss';

class UserProfile extends React.Component {
  render() {
    return (
      <div>
        <AccountStatus />

        <div className='u-Container'>
          <ProfileNavigation navigate={this.props.navigate} />

          <div className='u-formContainer'>
            {/* Form body */}
            <div className='u-form'>
              <div>
                <div key={"socialLogin"} className="u-fieldSet">
                  {/* Fieldset Header */}
                  <div className='u-fieldSetHeader'>
                    <p className='u-fieldSetHeaderText'>Comptes associés</p>
                  </div>

                  {/* Fieldset Body */}
                  <div className='u-fieldSetBody'>
                    <FacebookIcon />
                    <GoogleIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default requireAuth(
  UserProfile
);