import React from 'react';
import './style.scss';

const GoogleIcon = () => (
  <div>
    <img />
    <p>Google</p>
    <button>
      <p>dissocier</p>
    </button>
  </div>
)

GoogleIcon.displayName = "GoogleIcon";

export default GoogleIcon;