import React, { useContext } from 'react';

import { MenuContext } from '../../../../context'

import CardPaymentMethod from '../../../../components/CardPaymentMethod/index'
import LoadingComponent from '../../../../components/utils/withLoading'
import { getRestaurant } from '../../../Restaurant/HttpServices'
import withNavigateHook from '../../../../components/WithNavigation';

import './style.scss';

const PaymentView = ({ navigate }) => {
  const context = useContext(MenuContext)

  // No future intervals
  if (!context.isRestaurantAvailable())
    return (
      <div className='pv-method-container'>
      {/* Payment Form */}
        <div className='method-section-center'>
          <p className='method-disponibility'>Ce restaurant est fermé pour aujourd'hui</p>
        </div>
      </div>
    )

  if (!context.restaurant.IsStripeActive && context.restaurant.IsOnSpotPaymentActive) {
    context.hydrateAuthentication(() => {
      context.setPaymentMethod(1)
      navigate("/payment")
    })
  }

  return (
    <div className='pv-method-container'>
      {/* Payment Form */}
      <div className='method-section-center'>
        <p className='choice'>Veuillez choisir le mode de paiement</p>
        <div className='method-items-container'>
          {context.restaurant.IsOnSpotPaymentActive && context.diningMode !== "delivery" &&
            <CardPaymentMethod
              id="pay-on-spot"
              title={"Au comptoir"}
              text={"La commande va être passée vers la cuisine dès que vous payez "}
              key={"1"}
              image={require(`../../../../static/img/paymentRestaurant.png`)}
              onPress={
                () => {
                  context.setPaymentMethod(1)
                  navigate("/payment")
                }}
            />
          }
          {context.restaurant.IsStripeActive &&
            <CardPaymentMethod
              id="pay-online"
              title={"En ligne"}
              text={"Vous allez trouver votre commande prête"}
              key={"2"}
              image={require(`../../../../static/img/paymentCard.png`)}
              onPress={
                () => {
                  context.setPaymentMethod(2)
                  navigate("/payment")
                }}
            />
          }
        </div>
      </div>
    </div>
  )
}

function getRestaurantProfile(WrappedComponent) {
  return class extends React.Component {
    // Context must be passed down explicitly
    static contextType = MenuContext

    constructor(props) {
      super(props)
      this.state = {
        loading: true
      }
    }

    componentDidMount() {   
      return getRestaurant(this.context.cartRestaurantId)
        .then(restaurant => {
          this.context.setActiveRestaurant(restaurant)
          this.context.hydrateAuthentication()

          this.setState({
            restaurant: restaurant,
            loading: false
          })
        })
        .catch(err =>
          this.setState({
            error: "Une erreur s'est produite lors de la connexion",
            loading: false
          })
        )
        .catch(err =>
          this.setState({
            error: "Une erreur s'est produite lors de la connexion",
            loading: false
          })
      )
    }

    render() {
      return (
        <LoadingComponent loading={this.state.loading}>
          <WrappedComponent
            restaurant={this.state.restaurant}
            loading={this.state.loading}
            error={this.state.error}
            {...this.props}
          />
        </LoadingComponent>
      )
    }
  }
}

// export default 
//   getRestaurantProfile(PaymentView)
// ;

export default withNavigateHook(
  getRestaurantProfile(PaymentView)
);