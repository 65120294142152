import React, { useState } from 'react'

import './style.scss'

export const DoorCode = ( { fieldValue, setFieldValue } ) => {
  const setDoorCode = (value) => {
    setFieldValue(value)
  }

  return (
    <div className='if-field'>
      <input
        value={fieldValue}
        placeholder={"Code Porte"}
        onChange={(evt) => setDoorCode(evt.target.value)}
        className="if-inputTest"
      />
    </div>
  )
}

export default DoorCode;