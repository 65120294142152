import React from 'react';
import { __ImageHost__ } from '../../Infra/HttpServices';

import './style.scss';

const IconList = ({ list, activeId, onPress, font }) => {
  return (
      <div className='iconlist-container'>
        {list.map((elem, index) =>
          <button key={elem.Id} onClick={() => onPress({ 'index': index })}>
            <div className='item-container'>
              <div className='image-container'>
                <img
                  src={`${elem.Image}` || ""}
                  alt="element"
                />
              </div>
              <div className='text-container'>
                {activeId === elem.Id
                  ? <p className='active'>{elem.Name}</p>
                  : <p className='black'>{elem.Name}</p>
                }
              </div>
            </div>
          </button>
        )}
      </div>
  )
}


export default IconList;