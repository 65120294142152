import React from 'react';
import RedirectAfter from '../../../../../Utils/Router';

const PaymentSuccess = ({ phoneNumber }) => {
  return (
    <div className='success-message-container'>
      <img src={require("../../../../../static/img/card.png")} alt="card" className='sm-image' />
      <p className='sm-text'>Nous avons bien reçu votre commande, elle est en cours de traitement</p>
      <p className='sm-text'>{'Pour plus d\'information veuillez contacter '} {phoneNumber}</p>
    </div>
  );
}

export default RedirectAfter(
  {
    after: 5000,
    to: "/user/commande/",
  },
  PaymentSuccess
)