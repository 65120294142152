import React from 'react'

import { toast } from 'react-toastify';
import { MenuContext } from '../../../context';


function Modules (WrappedComponent) {
  return class extends React.Component {
    static contextType = MenuContext

    constructor(props) {
      super(props);

      this.state = {
        ...this.props.menu
      }
    }

    componentDidMount() {
      if (this.props.onOpen)
        this.props.onOpen()
    }

    // Rule 1: Respect the component MaxSelection property
    isComponentMaxQuantity(component) {
      const currentQuantity = this.getComponentQuantity(component)
      return currentQuantity >= component.MaxSelection
    }

    // Rule 2: Respect the section MaxSelection property
    isSectionMaxQuantity() {
      const maxSelection = this.props.menu.Sections[this.state.sectionIndex].MaxSelection
      const currentSelection = this.getSectionQuantity(this.state.sectionIndex)

      return currentSelection >= maxSelection
    }

    nextSection() {
      // In case the user is editing his choices.
      if (this.state.isEditing || this.state.sectionIndex + 1 === this.props.menu.Sections.length) {
        toast.success('Article ajouté au panier')
        return this.props.closeMenu()
      }

      if ((this.state.sectionIndex === 0) && (this.getSectionQuantity(0) === 0))
        return

      this.setState({
        sectionIndex: this.state.sectionIndex + 1
      })
    }

    goToSection(index) {
      const components = this.state.Components.filter((c) => c.SectionIndex < index)
      const compositions = []
      components.map((comp) => {
        this.state.Compositions.map((item) => {
          if (item.ComponentId === comp.Id)
            compositions.push(item)
        })
      })

      this.setState({
        Components: components,
        Compositions: compositions,
        sectionIndex: index
      })
    }

    preComponentAddHooks(selectedComponent) {
      // Rule 1: Component is the next button      
      if (selectedComponent.IsNextButton) {
        if (this.props.isEditing) {
          this.props.onClose()
        } else {
          this.nextSection()
        }
        return false
      }

      // Rule 2: Respect the section MaxSelection property
      if (this.isSectionMaxQuantity() && this.isComponentMaxQuantity(selectedComponent)) {
        this.removeComponent(selectedComponent)
        return false
      }

      if (this.isSectionMaxQuantity()) {
        if (this.props.isEditing) {
          this.props.onClose()
        } else {
          this.nextSection()
        }
        return false
      }

      // Rule 3: Respect the component MaxSelection property
      if (this.isComponentMaxQuantity(selectedComponent)) {
        this.removeComponent(selectedComponent)
        return false
      }

      return true
    }

    postComponentAddHooks() {
      if (this.props.postComponentAdd)
        return this.props.postComponentAdd

      if (this.isSectionMaxQuantity() && !this.state.activeComponent)
        this.nextSection()
    }

    /* Add or update component quantity */
    addComponentToCart(selectedComponent) {
      const continueExecution = this.preComponentAddHooks(selectedComponent)
      if (!continueExecution)
      return
            
      // Always add the component
      this.addComponent({
        ...selectedComponent,
        SectionIndex: this.state.sectionIndex
      })

      if (selectedComponent.HasCompositions) {
        this.setState({
          activeComponent: selectedComponent,
          Compositions: [
            ...this.state.Compositions,
            ...selectedComponent.Compositions
              .filter((composition) => composition.IsDefault && composition.Quantity >= 1)
              .map((composition) => (
                {
                  ...composition,
                  ComponentId: selectedComponent.Id,
                  Quantity: 0
                }
              )
              )
          ],
          displayComposition: true
        })
      }
    }

    addComponent(component) {
      let updated = false

      let newCartEntries = this.state.Components.map(item => {
        if (item.Id === component.Id) {
          updated = true
          return {
            ...item,
            Quantity: item.Quantity + 1
          }
        }

        return item
      })

      if (!updated)
        newCartEntries = [
          ...this.state.Components,
          {
            ...component,
            Quantity: 1
          }
        ]

      this.setState({
        Components: newCartEntries
      }, () => {
        this.persist()
        this.postComponentAddHooks()
      })
    }

    removeComponent(component) {
      const newComponents = this.state.Components.filter(cartComponent => cartComponent.Id !== component.Id)
      const newCompositions = this.state.Compositions.filter(composition => composition.ComponentId !== component.Id)

      this.setState({
        Components: newComponents,
        Compositions: newCompositions
      }, () => this.persist())
    }

    getComponentQuantity(component) {
      const cartComponent = this.state.Components.find(cartComponent => cartComponent.Id === component.Id)

      if (!cartComponent)
        return 0

      return cartComponent.Quantity
    }

    resetActiveComponent() {
      this.setState({
        activeComponent: null,
        displayComposition: false
      }, () => {
        this.persist()
        this.postComponentAddHooks()
      })
    }

    getSectionQuantity(sectionIndex) {
      let currentSelection = 0

      this.state.Components.forEach(cartComponent => {
        if (cartComponent.SectionIndex === sectionIndex)
          currentSelection += cartComponent.Quantity
      })

      return currentSelection
    }

    isCompositionMaxQuantity(componentId, selectedComposition) {
      const currentQuantity = this.getCompositionQuantity(componentId, selectedComposition.Id)

      // Hack for inconsistent backoffice logic
      if (selectedComposition.MaxSelection === 0 && currentQuantity === 0)
        return false

      if (selectedComposition.MaxSelection === 0)
        return true

      return currentQuantity >= selectedComposition.MaxSelection
    }
    isComponentCmpsMaxQuantity(componentId) {
      const Compositions = this.state.Compositions.map(composition => ({ ...composition, quantity: this.getCompositionQuantity(componentId, composition.Id) }))
      const totalSelectedCmps = Compositions.filter((a) => a.IsDefault === false).reduce((a, b) => a + b.quantity, 0);

      return this.state.activeComponent.MaxCmpSelection !== 0 ? totalSelectedCmps >= this.state.activeComponent.MaxCmpSelection : false
    }
    preCompositionAddHooks(selectedComposition) {
      const componentId = this.state.activeComponent.Id

      // Rule 1: a composition quantity cannot exceed the section MaxQuantity
      if (this.isCompositionMaxQuantity(componentId, selectedComposition)) {
        this.removeComposition(componentId, selectedComposition.Id)
        return false
      }

      return true
    }

    postCompositionAddHooks(componentId) {
      // Empty
    }
    preTestCmpMaxSelection(selectedComposition) {
      const componentId = this.state.activeComponent.Id

      // Rule 1: total composition quantity cannot exceed the Component MaxQuantity
      if (this.isComponentCmpsMaxQuantity(componentId)) {
        this.removeComposition(componentId, selectedComposition.Id)
        return false
      }

      return true
    }
    addComposition(componentId, composition) {
      let continueTest = true
      if (composition.IsDefault === false) {
        continueTest = this.preTestCmpMaxSelection(composition)

      }
      if (!continueTest) {
        return
      }

      const continueExecution = this.preCompositionAddHooks(composition)
      if (!continueExecution)
        return

      let updated = false

      let newCompositions = this.state.Compositions.map(item => {
        if ((item.Id === composition.Id) && (item.ComponentId === componentId)) {
          updated = true
          return {
            ...item,
            Quantity: item.Quantity + 1,
            IsDefault: composition.IsDefault
          }
        }

        return item
      })

      if (!updated)
        newCompositions = [
          ...this.state.Compositions,
          {
            ...composition,
            ComponentId: componentId,
            Quantity: 1,
            IsDefault: composition.IsDefault
          }
        ]

      this.setState({
        Compositions: newCompositions
      }, () => {
        this.persist()
        this.postCompositionAddHooks(componentId)
      })
    }

    removeComposition(componentId, compositionId) {
      const newCompositions = this.state.Compositions.filter(composition => {
        return (composition.Id !== compositionId) && (composition.ComponentId === componentId)
      })

      this.setState({
        Compositions: newCompositions
      }, () => this.persist())
    }

    getComponentCompositionQuantity(componentId) {
      let count = 0
      const cartCompositions = this.state.Compositions.filter(composition => {
        return composition.ComponentId === componentId
      })

      if (!cartCompositions)
        return count

      cartCompositions.forEach(composition => count += composition.Quantity)

      return count
    }

    getCompositionQuantity(componentId, compositionId) {
      const cartComposition = this.state.Compositions.find(composition => {
        return (composition.Id === compositionId) && (composition.ComponentId === componentId)
      })

      if (!cartComposition)
        return 0

      return cartComposition.Quantity
    }

    getCompositionComponentQuantity(componentId) {
      let count = 0

      this.state.Compositions.forEach(cartComposition => {
        if (cartComposition.ComponentId === componentId)
          count += cartComposition.Quantity
      })

      return count
    }

    getCartTotalCost() {
      let cost = 0

      this.state.Components.forEach(item => cost += (item.Price * item.Quantity))
      this.state.Compositions.forEach(item => cost += (item.Price * item.Quantity))

      // Fix precision
      cost = Math.round(cost * 100) / 100

      return cost
    }

    getVerboseTotalCost() {
      return `${this.getCartTotalCost()}€`
    }

    onClose() {
      if (this.props.onClose)
        return this.props.onClose()

      this.props.context.decrementCartEntryQuantity(this.state.Id)
      this.props.closeMenu()
    }

    persist(complete = false) {
      this.props.context.saveCartEntry({
        Id: this.state.Id,
        ProductId: this.state.ProductId,
        RestaurantId: this.props.context.restaurant.id,
        Name: this.state.Components[0].Name,
        Quantity: this.state.Quantity,
        Type: "composable",
        Components: this.state.Components,
        Compositions: this.state.Compositions,
        Sections: this.state.Sections.map(section => { return { Id: section.Id, Name: section.Name } }),
        totalCost: this.getCartTotalCost(),
        complete: this.state.complete || complete,
        family: this.state.family || this.props.context.getActiveFamily(),
        menuNode: this.state.menuNode || this.props.activeChoice,
        diningMode: this.state.diningMode || this.props.context.diningMode
      })
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          cart={
            {
              Id: this.state.Id,
              Components: this.state.Components,
              Compositions: this.state.Compositions,
              totalCost: this.getCartTotalCost(),

              addComponent: (component) => this.addComponentToCart(component),
              removeComponent: (component) => this.removeComponent(component),
              getComponentQuantity: (component) => this.getComponentQuantity(component),

              getSectionQuantity: (component) => this.getSectionQuantity(component),

              getCompositionQuantity: (componentId, compositionId) => this.getCompositionQuantity(componentId, compositionId),
              getCompositionComponentQuantity: (componentId) => this.getCompositionComponentQuantity(componentId),
              addComposition: (componentId, composition) => this.addComposition(componentId, composition),
              removeComposition: (componentId, compositionId) => this.removeComposition(componentId, compositionId),

              persist: () => this.persist()
            }
          }
          goToSection={(index) => this.goToSection(index)}
          nextSection={() => this.nextSection()}
          onClose={() => this.onClose()}
          resetActiveComponent={() => this.resetActiveComponent()}
          sectionIndex={this.state.sectionIndex}
          activeComponent={this.state.activeComponent}
          displayComposition={this.state.displayComposition}
        />
      )
    }
  }
}

export default Modules;