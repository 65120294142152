import React, { useState } from 'react';

import { __ImageHost__ } from '../../../../../Infra/HttpServices';

import './style.scss';

const CompositionView = ({ componentId, compositions, activeComponent, addCompositionToCart, removeCompositionFromCart, getCompositionQuantity, displayAllergenes, validate, isEditing, onClose, Style }) => {
  const Compositions = compositions.map(composition => ({ ...composition, quantity: getCompositionQuantity(componentId, composition.Id) }))
  const totalSelectedCmps = Compositions.filter((a) => a.IsDefault === false).reduce((a, b) => a + b.quantity, 0);
  const [error, setError] = useState('')

  function addComposition(componentId, composition, retired=false) {
    if(retired)
    {
      setError('')
    }
    else if (totalSelectedCmps === activeComponent.MaxCmpSelection) {
      setError("Le nombre maximal de sélection est de " + activeComponent.MaxCmpSelection + " ingrédients")      
    }
    
    addCompositionToCart(componentId, composition)  
  }

  return (
    <div className='compo-outer-container'>
      <div className='outer-container'>
        {/* Modal header */}
        <div className='header'>
          <p>Compositions</p>
        </div>

          {/* Modal Body */}
          <div className='items'>
            <div className='items-container'>
              {Compositions.map((composition) =>
                <div
                  key={composition.Id}
                  className="card"
                >
                  <div className='card-container'>
                    {composition.IsDefault
                    ? composition.quantity > 1 &&
                      <p className='selection-count'>
                        {'x' + composition.quantity}
                      </p>
                      : composition.quantity >= 1 &&
                      <p className='selection-count'>
                        {'x' + composition.quantity}
                      </p>
                    }

                    <div>
                      <p className='title'>{composition.Name}</p>
                    </div>

                    {composition.Price > 0 && (
                      <div className='ffr'>
                        <p className='price'>{composition.Price}€</p>
                      </div>
                    )}

                    <img width="150px" src={`${composition.Image}`} alt="compo" className='img' />

                      {composition.Allergenes &&
                        <button
                          onClick={(e) => {
                            e.stopPropagation()
                            displayAllergenes(composition.Allergenes)
                          }}
                          className="card-button-allergen"
                        >
                          <p className='text'>allergènes</p>
                        </button>
                      }

                      {composition.IsDefault
                        ?

                        (composition.quantity === 1
                          ? <button onClick={() => addComposition(componentId, composition)} className="card-button-cancel">
                            <p className='text'>Annuler</p>
                          </button>
                          : <button onClick={() => addComposition(componentId, composition)} className="card-button-remove">
                            <p>Retirer</p>
                          </button>)
                          :
                          totalSelectedCmps >= activeComponent.MaxCmpSelection
                            ?
                          composition.quantity === 0
                            ?
                          <button onClick={() => addComposition(componentId, composition)} className="card-button-add">
                            <p>Ajouter</p>
                          </button>
                          :
                          <button onClick={() => addComposition(componentId, composition,true)} className="card-button-cancel">
                            <p className='text'>Retirer</p>
                          </button>
                          :
                          (composition.quantity < composition.MaxSelection
                            ? <button onClick={() => addComposition(componentId, composition)} className="card-button-add">
                              <p>Ajouter</p>
                            </button>
                            : <button onClick={() => addComposition(componentId, composition,true)} className="card-button-cancel">
                              <p className='text'>Retirer</p>
                            </button>)
                      }
                  </div>
                </div>
                  )}
              </div>
          </div>

        <div className='buttons-container'>
          <button
            onClick={() => {
              validate()

              if (isEditing) {
                onClose()
              }
            }}
            className="validate-button"
          >
            <p>Valider</p>
          </button>
        </div>

        {
          error &&
          <p className='selection-error-text'>{error}</p>
        }
      </div>
    </div>
  )
}

export default CompositionView;