import React from 'react'

import { Autocomplete } from '@react-google-maps/api'

import './style.scss'

const AutoCompleteWrapper = ({ onLoad, onPlaceChanged, country, children }) => (
  <Autocomplete
    onLoad={onLoad}
    onPlaceChanged={onPlaceChanged}
    restrictions={{ country: country }}
    className="restyle"
  >
    {children}
  </Autocomplete>
)

export default AutoCompleteWrapper