import React from 'react';
import './style.scss';

const Separator = ({ Style, isDone, isActive }) => (
  <div className='separator'>
    <div className={`separator-line ${isDone? "separator-line-active": ""} ${isActive? "bold-separator-line": ""}`}></div>
  </div>
)

const StepCrumb = ({ stepIndex, steps, onStepPress }) => {
  const isDone = (index) => {
    return index < stepIndex
  }

  const isActive = (index) => {
    return index === stepIndex
  }

  const isLast = (index) => {
    return index === steps.length - 1
  }

  return (
    <div className='step-container'>
      <div className='items'>
        {steps.map((step, index) =>
          isDone(index)
            ? <button key={index} onClick={() => onStepPress(index)} className="button">
              <div className='step'>
                <p className='step-count-done step-count-active'>&#10004;</p>
                <p className='text step-text-active'>{step.Name}</p>
              </div>
              {isLast(index)
                ? null
                : <Separator isDone={true} isActive={false} />}
            </button>
            : isActive(index)
              ? <button key={index} onClick={() => onStepPress(index)} className="button">
                <div className='step'>
                  <p className='step-count step-count-active'>{index}</p>
                  <p className='text step-text-active'>{step.Name}</p>
                </div>
                {isLast(index)
                  ? null
                  : <Separator isDone={false} isActive={true} />}
              </button>
              : <button key={index} className="button">
                <div className='step'>
                  <p className='step-count'>{index}</p>
                  <p className='step-text'>{step.Name}</p>
                </div>
                {isLast(index)
                  ? null
                  : <Separator isDone={false} isActive={false} />}
              </button>
        )}
      </div>
      <p className='mb-text'>
        {steps[stepIndex].Name}
      </p>
    </div>
  )
}

export default StepCrumb;