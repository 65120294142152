export const getDeviceLocation = async () => {
  const serviceAvailable = navigator.geolocation

  if (!serviceAvailable)
    throw new Error("Device location service unavailable")

  const location = await navigator.geolocation.getCurrentPosition()

  return location.coords
}

export const getAuthToken = async () => {
  const token = await localStorage.getItem("jwt")

  return token
}