import React from "react";

import { MenuContext } from "../../context";

import moment from "moment";

import './style.scss';

class CardImage extends React.Component {
  static contextType = MenuContext;

  constructor(props) {
    super(props);

    this.state = {
      isFavorite: this.props.isFavorite,
      hovering: false,
    };
  }

  UpperCaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  redirectTo (location) {
    this.props.redirectTo(location);
  }

  handleFavoriteClick(e) {
    e.stopPropagation();

    if(!this.context.jwt) {
      this.redirectTo('/login/');
    }else {
      this.props.toggleFavoriteRestaurant({id: this.props.Id, isFavorite: !this.state.isFavorite}).then(() => this.props.ReloadData());
      this.setState({
        isFavorite: !this.state.isFavorite
      });
    }
  }

  render() {
    return (
      <div className="fav-restaurant-card" style={{ backgroundImage: `url(${this.props.image})` }}>
        <button
          onClick={this.props.onPress}
          className={this.props.disabled ? "disabled": ""}
        >
          <div className="restaurant-infos">
            {this.props.disabled && (
              <div className="indisp-container">
                <p className="error">
                  Ce restaurant est indisponible
                </p>
              </div>
            )}
            <div>
              <p className="title bold">
                {this.props.title}
              </p>
            </div>
            {this.props.text && (
              <div className="desc-container">
                <img src={require('../../static/img/ci_location.png')} alt="location" />
                <p>
                  {this.props.text}
                </p>
              </div>
            )}
            <div className="image-container">
              <button onClick={(e) => this.handleFavoriteClick(e)}>
                <img src={this.state.isFavorite === true? require("../../static/img/heart_full.png"): require("../../static/img/heart_orange.png")} alt="heart" />
              </button>
            </div>
          </div>
        </button>
      </div>
    );
  }
}

export default CardImage;
