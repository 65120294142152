import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useOutsideClick } from '../../../Utils/UseOutsideClick';
import withNavigateHook from '../../WithNavigation';

import './style.scss';

const UserMenu = ({ navigate, open, close }) => {
  const ref = useRef();
  const [bool, setBool] = useState(false);

  useEffect(() => {
    setBool(open);
  }, [open]);

  const navigateAndClose = (to) => {
    navigate(to)
    close();
  };

  useOutsideClick(ref, () => {
    if(bool) close();
  });

  return(
    bool && <div className={"navbar-usermenu-container"} ref={ref}>
      <button onClick={() => navigateAndClose('/user/')}>
        <img src={require("../../../static/img/user_black.png")} alt="user" />
        <p>Mon profil</p>
      </button>

      <button onClick={() => navigateAndClose('/user/commande/')}>
        <img src={require("../../../static/img/trolley.png")} alt="orders" />
        <p>Mes commandes</p>
      </button>

      <button onClick={() => navigateAndClose('/restaurant/favorite/list')}>
        <img src={require("../../../static/img/heart.png")} alt="favorite-restaurant" />
        <p>Mes restaurants favoris</p>
      </button>

      <button onClick={() => navigateAndClose('/logout/')}>
        <img src={require("../../../static/img/logout.png")} alt="disconnect" />
        <p>Se déconnecter</p>
      </button>
    </div>
  );
}

export default withNavigateHook(UserMenu);