export default [
  {
    title: "Changer mon numéro de téléphone",
    fields: [
      {
        id: "PhoneNumber",
        label: "Numéro de téléphone",
      }
    ]
  }
]