import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import RestaurantProfile from './apps/Restaurant/Views/Profile'
import RestaurantList from './apps/Restaurant/Views/List'
import FavoriteRestaurantList from './apps/Restaurant/FavoriteRestaurant/Views'

import SignUp from './apps/Authn/Views/Signup'
import ConfirmAccount from './apps/Authn/Views/Signup/Messages/ConfirmAccount'
import EmailConfirmationCallback from './apps/Authn/Views/Signup/Callbacks/EmailConfirmation'

import SignIn from './apps/Authn/Views/Signin'
import SocialAuth from './apps/Authn/Views/Social'
import SignOut from './apps/Authn/Views/Signout'
import ResendConfirmation from './apps/Authn/Views/Confirmation'

import OrderList from './apps/Order/Views/List'
import { UserProfile, PasswordForgot, PasswordForgotMessage, PasswordForgotCallback, PasswordForgotCallbackMessage, PasswordReset, EmailReset, EmailResetMessage, EmailResetCallbackMessage, LinkedAccounts, PasswordResetMessage, PhoneChange, PhoneChangeMessage } from './apps/User/Views'

import Payment from './apps/Payment/Views/Profile'
import PaymentSuccess from './apps/Payment/Views/SuccessMessage'
import CashPaymentSuccess from './apps/Payment/Views/SuccessMessage/Cash'
import PaymentMethod from './apps/Payment/Views/Method'

import ToSDocument from './apps/Authn/Views/Legal/ToS'
import PPDocument from './apps/Authn/Views/Legal/PP'
import CustomerFidelite from './apps/User/Views/CustomerFidelite'
import Home from './apps/Home/Views/index'
import { MenuProvider } from './context/Provider';
import LoadScript from './components/gmaps/LoadScript';
import { __GmapsKey__ } from './Infra/HttpServices';
import './style.scss';
import AppLayout from './components/AppLayout';
import { ToastContainer } from 'react-toastify';

function App() {
  
  const [show, setShow] = useState(false);

  return (
    <div className='container'>
      <BrowserRouter>
        <MenuProvider>
          <LoadScript
            googleMapsApiKey={`${__GmapsKey__}`}
            libraries={["places"]}
            id={"TheOneAndOnlyGmaps"}
            language={"fr"}
            region={"FR"}
          >
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              style={{top:100}}
            />
            <Routes>
              <Route element={<AppLayout show={show} setShow={(bool) => setShow(bool)} />}>
                <Route path="/" exact element={<Home />} />
                <Route exact path={"/search"} element={<RestaurantList />} />
                <Route exact path={"/restaurant/:id/"} element={<RestaurantProfile />} />
                <Route exact path={"/restaurant/favorite/list"} element={<FavoriteRestaurantList />} />

                <Route exact path={"/signup"} element={<SignUp />} />
                <Route exact path={"/signup/confirm"} element={<ConfirmAccount />} />
                <Route exact path={"/signup/confirm/callback/"} element={<EmailConfirmationCallback />} />

                <Route exact path={"/login"} element={<SignIn />} />
                <Route exact path={"/login/resend"} element={<ResendConfirmation />} />
                <Route exact path={"/login/social"} element={<SocialAuth />} />
                <Route exact path={"/logout"} element={<SignOut />} />

                <Route exact path={"/user"} element={<UserProfile />} />
                <Route exact path={"/user/social"} element={<LinkedAccounts />} />

                {/* Change password */}
                <Route exact path={"/user/password/change"} element={<PasswordReset />} />
                <Route exact path={"/user/password/change/message"} element={<PasswordResetMessage />} />

                {/* Forgot password */}
                <Route exact path={"/user/forgot-password"} element={<PasswordForgot />} />
                <Route exact path={"/user/forgot-password/message"} element={<PasswordForgotMessage />} />
                <Route exact path={"/user/forgot-password/callback"} element={<PasswordForgotCallback />} />
                <Route exact path={"/user/forgot-password/callback/message"} element={<PasswordForgotCallbackMessage />} />

                {/* Change Email */}
                <Route exact path={"/user/email/change"} element={<EmailReset />} />
                <Route exact path={"/user/email/change/message"} element={<EmailResetMessage />} />
                <Route exact path={"/user/email/change/callback/message"} element={<EmailResetCallbackMessage />} />

                {/* Phone Change */}
                <Route exact path={"/user/change-phone/initiate"} element={<PhoneChangeMessage />} />
                <Route exact path={"/user/change-phone/"} element={<PhoneChange />} />

                <Route exact path={"/user/commande/"} element={<OrderList />} />

                <Route exact path={"/Payment"} element={<Payment />} />
                <Route exact path={"/Payment/method"} element={<PaymentMethod />} />
                <Route exact path={"/payment/success"} element={<PaymentSuccess />} />
                <Route exact path={"/payment/cash/success"} element={<CashPaymentSuccess />} />

                <Route exact path={"/legal/tos"} element={<ToSDocument />} />
                <Route exact path={"/legal/pp"} element={<PPDocument />} />
                {/* customer loyalty */}
                <Route exact path={"/user/fidelite"} element={<CustomerFidelite />} />
              </Route>
            </Routes>
          </LoadScript>
        </MenuProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
