import React from 'react';

import './style.scss';

class SignUpConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: null
    }
  }

  componentDidMount() {
    const email = localStorage.getItem("signupEmail")

    if (email)
      return this.setState({
        emailAddress: email
      }, async () => localStorage.removeItem("signupEmail"))

    return false;
  }

  render() {
    return (
      <div className="signup-messages-container">
        <img src={"/static/img/email.png"} alt="email" />
        {this.state.emailAddress &&
          <p>Un email de confirmation a été envoyé a {this.state.emailAddress}, veuillez vérifier votre boîte de réception pour activer votre compte.</p>
        }
      </div>
    );
  }
}

export default SignUpConfirm
