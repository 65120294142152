import { Outlet } from 'react-router-dom';
import Footer from '../Footer';
import Navbar from '../Navbar';
import SideMenu from '../SideMenu';
import CartModal from '../../apps/Cart/Views/Container'

const AppLayout = ({ show, setShow }) => (
  <>
    <Navbar show={show} setShow={setShow} />
    <CartModal />
    <SideMenu show={show} setShow={setShow} />
    <div className="main">
      <Outlet />
    </div>
    <Footer />
  </>
);

export default AppLayout;