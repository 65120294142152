import { __PublicAPI__ } from '../../../Infra/HttpServices';
import { getAuthToken } from '../../../Utils/Device';

export const signUpHttp = async (body) => {
  const response = await fetch(`${__PublicAPI__}/auth/register/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })

  const content = await response.json();

  if (response.status === 201) return { data: content };

  if (response.status === 409) return { errors: content.errors };

  if (response.status === 400) {
    if (content.Errors)
      return {
        errors: {
          Password: ["Mot de passe doit contenir aux moins 8 caractére"],
        },
      };

    if (content.errors) return { errors: content.errors };

    if (content.Error) return {error: content.error}
  }

  throw new Error("Unexpected behaviour");

};

export const loginHttp = async (body) => {
  const response = await fetch(`${__PublicAPI__}/auth/login/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (response.ok) return response.json();

  throw new Error("Something wrong happened, hooray!");
};

export const ResendConfirmationEmailHttp = async () => {
  const token = await getAuthToken()

  const response = await fetch(`${__PublicAPI__}/auth/register/resend`, {
    method: "GET", headers: {'Authorization': `Bearer ${token}`}
  });

  if (response.ok) return {data: {success: true}};

  return {error: "Une erreur s'est produite lors de l'envoie du mail de confirmation, veuillez reesayer plus tard"}
};

export const socialLoginHttp = async (sut) => {
  const response = await fetch(`${__PublicAPI__}/auth/social/sut?sut=${sut}`);

  if (response.ok) return response.json();

  throw new Error("Something wrong happened, hooray!");
};

export const getLegalDocs = async () => {
  const response = await fetch(`${__PublicAPI__}/api/legal/`)

  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion")

  return response.json()
}

export const getRecaptchaPk = async () => {
  const response = await fetch(`${__PublicAPI__}/api/appconf/recaptcha`)

  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion")

  return response.json()
}