import { getAuthToken } from '../../../Utils/Device'
import { __PublicAPI__ } from '../../../Infra/HttpServices'

export const createCommand = async (body) => {
  const token = await getAuthToken()

  const response = await fetch(`${__PublicAPI__}/api/command`,
    {
      method: "POST",
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
  )

  if (response.status !== 201) {
    throw new Error("Unable to create command")
  }

  return response.json()
}

export const createIntentHttp = async (body) => {
  const token = await getAuthToken()

  const response = await fetch(`${__PublicAPI__}/api/checkout/create`,
    {
      method: "POST",
      headers:
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'IdempotencyKey': body.idempotencyKey
      },
      body: JSON.stringify(body)
    }
  )

  return response.json()
}

export const confirmIntentHttp = async (body) => {
  const token = await getAuthToken()

  const response = await fetch(`${__PublicAPI__}/api/checkout/confirm`,
    {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'IdempotencyKey': body.IdempotencyKey
      },
      body: JSON.stringify(body)
    }
  )
  
  if (response.status !== 200)
    return {error: "Unable to confirm payment intent"}

  return {}
}

export const verifPromotionsExistance = async (restaurantId) => {
  const token = await getAuthToken()
  const response = await fetch(`${__PublicAPI__}/api/order/promotionsExist?restaurantId=${restaurantId}`,
  {
    method: "GET",
    headers:
    {
      'Authorization': `Bearer ${token}`
    }
  }
  )

  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion")

  const body = await response.json()

  return body;
}

export const findPromotion = async (restaurantId, code) => {
  
  const token = await getAuthToken()
  const response = await fetch(`${__PublicAPI__}/api/order/findPromotion?restaurantId=${restaurantId}&code=${code}`,
  {
    method: "GET",
    headers:
    {
      'Authorization': `Bearer ${token}`
    }
  }
  )

  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion")

  const body = await response.json()
  
  return body;
}