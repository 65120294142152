import React from 'react';

import FormHOC from '../../../Utils/Forms'

import User from '../Models/User'
import { GetAddressHttpService, DeleteAddressHttpService, GetPersonalInfoHttpService, UpdatePersonalInfoHttpService } from '../HttpServices'

import ProfileNavigation from './ProfileNavigation'
import AccountStatus from './AccountStatus'
import { MenuContext } from '../../../context'

import { Autocomplete } from '@react-google-maps/api'
import withNavigateHook from '../../../components/WithNavigation';

import LoadingLogo from '../../../static/img/tail-loading.svg'
import TrashLogo from '../../../static/img/trash.svg'

import './style.scss'
import Modal from '../../../components/Modal';

import CloseLogo from '../../../static/img/letter-xblack.svg';

class UserProfile extends React.Component {
  static contextType = MenuContext
  
  autoComplete;
  place;
  billingAutoComplete;
  billingPlace;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      addresses: [],
      selectLabels: [],
      focusedField: null,
      formState:{
        Address: {
          addressName: "",
          interphone: "",
          batiment: "",
          escalier: "",
          porte: "",
          codePorte: ""
        },
        BillingAddress: {
          interphone: "",
          batiment: "",
          escalier: "",
          porte: "",
          codePorte: ""
        },
        firstName: "",
        lastName: "",
        phoneNumber: "",
        acceptNotifs: false
      },
      addressError: false,
      billingAddressError: false,
      showAddress: false,
      selectedValue: 0,
      loading: false
    }
  }

  showConfirmation () {
    this.setState({
      open: true
    })
  }

  async loadData() {
    const address = await GetAddressHttpService();
    let selectLabels = [];
    if(address != null) {

      let noBillingAddress = address.filter(function (el) {
        return el.IsDefault !== true
      });

      address.map((a, index) => {
        if(a.IsDefault !== true) {
          let obj = {label: a.AddressName, value: index}
          selectLabels.push(obj)
        }
      })

      this.setState({
        addresses: noBillingAddress,
        formState: {
          ...this.state.formState,
          Address: {
            addressId: noBillingAddress.length > 0 ? noBillingAddress[0].Id : 0,
            addressName: noBillingAddress.length > 0 && noBillingAddress[0].AddressName !== ""? noBillingAddress[0].AddressName: "",
            address: noBillingAddress.length > 0 && JSON.parse(noBillingAddress[0].Address).formatted_address? JSON.parse(noBillingAddress[0].Address).formatted_address: "",
            interphone: noBillingAddress.length > 0 && noBillingAddress[0].Interphone !== ""? noBillingAddress[0].Interphone: "",
            batiment: noBillingAddress.length > 0 && noBillingAddress[0].Batiment !== ""? noBillingAddress[0].Batiment: "",
            escalier: noBillingAddress.length > 0 && noBillingAddress[0].Escalier !== ""? noBillingAddress[0].Escalier: "",
            porte: noBillingAddress.length > 0 && noBillingAddress[0].Porte !== ""? noBillingAddress[0].Porte: "",
            codePorte: noBillingAddress.length > 0 && noBillingAddress[0].Code !== ""? noBillingAddress[0].Code: ""
          }
        },
        selectLabels: selectLabels,
        showAddress: true,
        selectedValue: 0
      }, () => {
        address.map((a, index) => {
          if(a.IsDefault === true) {
            this.setState({
              formState: {
                ...this.state.formState,
                BillingAddress: {
                  address: JSON.parse(a.Address).formatted_address ? JSON.parse(a.Address).formatted_address: "",
                  interphone: a.Interphone !== ""? a.Interphone: "",
                  batiment: a.Batiment !== ""? a.Batiment: "",
                  escalier: a.Escalier !== ""? a.Escalier: "",
                  porte: a.Porte !== ""? a.Porte: "",
                  codePorte: a.Code !== ""? a.Code: ""
                }
              }
            })
          }
        })
      }
    )}
  }

  async componentDidMount() {
    this.loadData()
  }

  async componentDidUpdate(prevProps) {
    if (await this.context.asyncIsAuthenticated()) {
      if (this.props.formData !== prevProps.formData)
        this.setState({ formState: {...this.state.formState, ...this.props.formData} })
    }
    else {
      this.props.navigate("/login")
    }
  }

  setFieldValue(field, newValue) {
    const newFormState = {
      ...this.state.formState
    }
    newFormState[field] = newValue

    this.setState({ formState: newFormState })
  }

  setAddressValue(field, newValue) {
    const newFormState = {
      ...this.state.formState
    }

    newFormState["Address"] = {...this.state.formState.Address, [field]: newValue}
    this.setState({ formState: newFormState })
  }

  setBillingAddressValue(field, newValue) {
    const newFormState = {
      ...this.state.formState
    }

    newFormState["BillingAddress"] = {...this.state.formState.BillingAddress, [field]: newValue}
    this.setState({ formState: newFormState })
  }

  onAutoCompleteLoad(autocomplete){
    this.autoComplete = autocomplete
  }

  onBillingAutoCompleteLoad(billingautoComplete){
    this.billingAutoComplete = billingautoComplete
  }

  async onDeletePress() {
    this.setState({loading: true})

    const deleteAddress = await DeleteAddressHttpService(this.state.addresses[this.state.selectedValue].Id);

    if (deleteAddress) {
      this.setState({
        loading: false
      })
    }
  }

  deleteAddressFields() {
    delete this.state.formState.Address.longitude
    delete this.state.formState.Address.latitude
    delete this.state.formState.Address.route
    delete this.state.formState.Address.department
    delete this.state.formState.Address.country
    delete this.state.formState.Address.countryShortName
    delete this.state.formState.Address.codePostal
    delete this.state.formState.Address.address
    delete this.state.formState.Address.jsonAddress
  }

  deleteBillingAddressFields() {
    delete this.state.formState.BillingAddress.longitude
    delete this.state.formState.BillingAddress.latitude
    delete this.state.formState.BillingAddress.route
    delete this.state.formState.BillingAddress.department
    delete this.state.formState.BillingAddress.country
    delete this.state.formState.BillingAddress.countryShortName
    delete this.state.formState.BillingAddress.codePostal
    delete this.state.formState.BillingAddress.address
    delete this.state.formState.BillingAddress.jsonAddress
  }

  addAddress() {
    this.setState({
      formState: {
        ...this.state.formState,
        Address: {
          addressId: 0,
          addressName: "",
          address: "",
          interphone: "",
          batiment: "",
          escalier: "",
          porte: "",
          codePorte: ""
        }
      }
    })

    window.location.replace("/user/#add-address")

    this.setState({
      selectedValue: "none"
    })
    
    this.deleteAddressFields();
  }

  onSelectValueChange(value) {
    this.setState({
      formState: {
        ...this.state.formState,
        Address: {
          addressId: this.state.addresses[value].Id,
          addressName: this.state.addresses[value].AddressName !== ""? this.state.addresses[value].AddressName: "",
          address: JSON.parse(this.state.addresses[value].Address).formatted_address,
          interphone: this.state.addresses[value].Interphone !== ""? this.state.addresses[value].Interphone: "",
          batiment: this.state.addresses[value].Batiment !== ""? this.state.addresses[value].Batiment: "",
          escalier: this.state.addresses[value].Escalier !== ""? this.state.addresses[value].Escalier: "",
          porte: this.state.addresses[value].Porte !== ""? this.state.addresses[value].Porte: "",
          codePorte: this.state.addresses[value].Code !== ""? this.state.addresses[value].Code: ""
        }
      },
      selectedValue: value
    })

    this.deleteAddressFields();
  }

  onPlaceChanged () {

    this.deleteAddressFields();
    
    if (this.autoComplete === null)
      return
   
    this.place = this.autoComplete.getPlace(); 

    const newFormState = {
      ...this.state.formState
    }

    try {
      newFormState["Address"] = {
        ...this.state.formState.Address,
        "longitude": '' + this.place.geometry.location.lng(),
        "latitude": '' + this.place.geometry.location.lat(),
        "routeNumber": this.place.address_components.find(adressComponent => adressComponent.types.find(value => value === "street_number")).long_name,
        "department": this.place.address_components.find(adressComponent => adressComponent.types.find(value => value === "administrative_area_level_1")).long_name,
        "country": "France",
        "countryShortName": "FR",
        "codePostal": this.place.address_components.find(adressComponent => adressComponent.types.find(value => value === "postal_code")).long_name,
        "address": this.place.formatted_address,
        "route": this.place.address_components.find(adressComponent => adressComponent.types.find(value => value === "route")).long_name,

        "jsonAddress": JSON.stringify(this.place)
      }
      this.setState({ formState: newFormState })
      this.setState({addressError: false})
    }catch(e) {
      this.setState({addressError: true})
    }
  }

  onBillingPlaceChanged () {

    this.deleteBillingAddressFields();
    
    if (this.billingAutoComplete === null)
      return
   
    this.billingPlace = this.billingAutoComplete.getPlace(); 

    const newFormState = {
      ...this.state.formState
    }

    try {
      newFormState["BillingAddress"] = {
        ...this.state.formState.BillingAddress,
        "longitude": '' + this.billingPlace.geometry.location.lng(),
        "latitude": '' + this.billingPlace.geometry.location.lat(),
        "routeNumber": this.billingPlace.address_components.find(adressComponent => adressComponent.types.find(value => value === "street_number")).long_name,
        "department": this.billingPlace.address_components.find(adressComponent => adressComponent.types.find(value => value === "administrative_area_level_1")).long_name,
        "country": "France",
        "countryShortName": "FR",
        "codePostal": this.billingPlace.address_components.find(adressComponent => adressComponent.types.find(value => value === "postal_code")).long_name,
        "address": this.billingPlace.formatted_address,
        "route": this.billingPlace.address_components.find(adressComponent => adressComponent.types.find(value => value === "route")).long_name,

        "jsonAddress": JSON.stringify(this.billingPlace)
      }
      this.setState({ formState: newFormState })
      this.setState({billingAddressError: false})
    }catch(e) {
      this.setState({billingAddressError: true})
    }
  }

  changeAddressText(text) {

    this.setState({
      formState: {
        ...this.state.formState,
        Address: {
          ...this.state.formState.Address,
          address: text
        }
      }
    })

    this.deleteAddressFields();

    this.setState({
      addressError: false
    })
  }

  changeBillingAddressText(text) {

    this.setState({
      formState: {
        ...this.state.formState,
        BillingAddress: {
          ...this.state.formState.BillingAddress,
          address: text
        }
      }
    })

    this.deleteBillingAddressFields();

    this.setState({
      billingAddressError: false
    })
  }

  changeAddressName(text) {
    this.setState({
      formState: {
        ...this.state.formState,
        Address: {
          ...this.state.formState.Address,
          addressName: text
        }
      }
    })
  }

  render() {
    return (
      <div className='u-Page'>
        <AccountStatus />

        <div className='u-Container'>
          <div className='u-Header'>
            <div className='u-NavContainer'>
              <ProfileNavigation navigate={this.props.navigate} />
              <button onClick={() => this.addAddress()} className="u-addButton">
                <p className='u-addAddress'>
                + Ajouter une adresse
                </p>
              </button>
            </div>

            <div className='u-firstForm'>
              {User.fieldsets.map((fieldset, index) =>
                <div key={index}>
                  {fieldset.fields.map(field =>
                    <div key={field.id} className="u-field">
                      <input
                        readOnly={field.editable === false}
                        placeholder={field.label}
                        value={this.state.formState[field.id]}
                        onFocus={() => this.setState({ focusedField: field.id })}
                        onChange={(evt) => this.setFieldValue(field.id, evt.target.value)}
                        className="u-input"
                      />
  
                      {this.props.errors[field.id] &&
                        <div>
                          {this.props.errors[field.id].map((error, i) =>
                            <p key={i} className="u-error">{error}</p>
                          )}
                        </div>}
  
                    </div>
                  )}
                </div>  
              )}
            </div>
          </div>
  
          <div className='u-formContainer'>
            <div className='u-form'>
              {/* Form body */}
              <div>
                {/* Fieldset */}
                {User.fieldsets.map(fieldset =>
                  <div key={fieldset.title} className="u-fieldSet">
                    {/* Fieldset Body */}
                    <div className='u-fieldSetBody'>
                      <div className='u-deskForm'>
                        {fieldset.fields.map(field =>
                          <div key={field.id} className="u-field">
                            <p>{field.label}</p>
                            <input
                              readOnly={field.editable === false}
                              placeholder={field.label}
                              value={this.state.formState[field.id]}
                              onFocus={() => this.setState({ focusedField: field.id })}
                              onChange={(evt) => this.setFieldValue(field.id, evt.target.value)}
                              className="u-input"
                            />

                            {this.props.errors[field.id] &&
                              <div>
                                {this.props.errors[field.id].map((error, i) =>
                                  <p key={i} className="u-error">{error}</p>
                                )}
                              </div>}

                          </div>
                        )}
                      </div>
                      <div className='u-field'>
                        <p className='u-label'>Adresse de facturation</p>
                        <div> 
                          <Autocomplete
                            restrictions={{ country: "fr" }}
                            onPlaceChanged={() => this.onBillingPlaceChanged()}
                            onLoad={(billingautoComplete) => this.onBillingAutoCompleteLoad(billingautoComplete)}
                          >
                            <input
                              value={this.state.formState.BillingAddress.address}
                              onChange={(evt) => this.changeBillingAddressText(evt.target.value)}
                              className="u-inputAddress"
                            />
                          </Autocomplete>
                          {this.state.billingAddressError &&
                            <div>
                              <p className='u-error'>Adresse incomplète</p>
                            </div>}
                        </div>
                      </div>  
                      <div className='u-address'>
                        <div className='u-leftFlex'>
                          <div className='u-addressView'>
                            <p className='u-addressLabel'>Bâtiment</p>
                            <input className='u-input' value={this.state.formState.BillingAddress.batiment} onChange={(evt) => this.setBillingAddressValue("batiment", evt.target.value)} placeholder="Bâtiment" />
                          </div>
                          <div className='u-addressView'>
                            <p className='u-addressLabel'>Porte</p>
                            <input className='u-input' value={this.state.formState.BillingAddress.porte} onChange={(evt) => this.setBillingAddressValue("porte", evt.target.value)} placeholder="Porte" />
                          </div>
                          <div className='u-addressView'>
                            <p className='u-addressLabel'>Code Porte</p>
                            <input className='u-input' value={this.state.formState.BillingAddress.codePorte} onChange={(evt) => this.setBillingAddressValue("codePorte", evt.target.value)} placeholder="Code Porte" />
                          </div>
                        </div>
                        <div className='u-flex'>
                          <div className='u-addressView'>
                            <p className='u-addressLabel'>Interphone</p>
                            <input className='u-input' value={this.state.formState.BillingAddress.interphone} onChange={(evt) => this.setBillingAddressValue("interphone", evt.target.value)} placeholder="Interphone" />
                          </div>
                          <div className='u-addressView'>
                            <p className='u-addressLabel'>Escalier</p>
                            <input className='u-input' value={this.state.formState.BillingAddress.escalier} onChange={(evt) => this.setBillingAddressValue("escalier", evt.target.value)} placeholder="Escalier" />
                          </div>
                        </div>
                      </div>
                      {this.state.addresses.length >= 1 &&
                        <div className='u-addressSelectorContainer' id="add-address">
                          <p className='u-label'>Adresse</p>
                          {/*TODO: Adapt this select*/}
                          <select value={this.state.selectedValue} onChange={(e) => this.onSelectValueChange(e.target.value)} className="u-addressSelector">
                            <option value={"none"} selected hidden disabled>Sélectionner une adresse</option>
                            {this.state.selectLabels && this.state.selectLabels.map((sl, i) => {
                              return <option value={sl.value} key={i}>{sl.label}</option>
                            })}
                          </select>
                        </div>
                      }
                      <div className='u-field'>
                        <div className='u-addressFieldContainer'>
                          <div className='u-addressField'>
                            <p className='u-addressLabel'>Intitulé de l'adresse</p>
                            <input className='u-input' value={this.state.formState.Address.addressName} onChange={(evt) => {this.changeAddressName(evt.target.value)}} placeholder="Intitulé de l'adresse" />
                          </div>
                          { this.state.addresses.length >= 1 &&
                            <button disabled={this.state.loading} onClick={() => this.showConfirmation()} title="Supprimer cette adresse" className='u-IconButton u-se'>
                              <img src={TrashLogo} alt="trash" className='u-DeleteAddressButtonIcon' />
                            </button>
                          }
                        </div>
                        <p className='u-addressLabel'>Adresse</p>
                        <div>
                          <Autocomplete
                            restrictions={{ country: "fr" }}
                            onPlaceChanged={() => this.onPlaceChanged()}
                            onLoad={(autocomplete) => this.onAutoCompleteLoad(autocomplete)}
                          >
                            <input
                              value={this.state.formState.Address.address}
                              onChange={(evt) => this.changeAddressText(evt.target.value)}
                              className="u-inputAddress"
                            />
                          </Autocomplete>
                          {this.state.addressError &&
                            <div>
                              <p className='u-error'>Adresse incomplète</p>
                            </div>}
                        </div>
                      </div>  
                      <div className='u-address'>
                        <div className='u-flex u-margin'>
                          <div className='u-addressView'>
                            <p className='u-addressLabel'>Bâtiment</p>
                            <input className='u-input' value={this.state.formState.Address.batiment} onChange={(evt) => this.setAddressValue("batiment", evt.target.value)} placeholder="Bâtiment" />
                          </div>
                          <div className='u-addressView'>
                            <p className='u-addressLabel'>Porte</p>
                            <input className='u-input' value={this.state.formState.Address.porte} onChange={(evt) => this.setAddressValue("porte", evt.target.value)} placeholder="Porte" />
                          </div>
                          <div className='u-addressView'>
                            <p className='u-addressLabel'>Code Porte</p>
                            <input className='u-input' value={this.state.formState.Address.codePorte} onChange={(evt) => this.setAddressValue("codePorte", evt.target.value)} placeholder="Code Porte" />
                          </div>
                        </div>
                        <div className='u-flex'>
                          <div className='u-addressView'>
                            <p className='u-addressLabel'>Interphone</p>
                            <input className='u-input' value={this.state.formState.Address.interphone} onChange={(evt) => this.setAddressValue("interphone", evt.target.value)} placeholder="Interphone" />
                          </div>
                          <div className='u-addressView'>
                            <p className='u-addressLabel'>Escalier</p>
                            <input className='u-input' value={this.state.formState.Address.escalier} onChange={(evt) => this.setAddressValue("escalier", evt.target.value)} placeholder="Escalier" />
                          </div>
                        </div>
                      </div>
                      <div className='u-field'>
                        <div className='u-checkboxContainer'>
                          <input className='u-checkboxInput' type='checkbox' checked={this.state.formState.acceptNotifs} onChange={(event) => this.setFieldValue("acceptNotifs", event.target.checked)} placeholder="sms/mail" />
                          <div className='u-textContainer'>
                            <p className='u-text'>J'accepte de recevoir des offres, promotions par sms/mail</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className='u-formControls'>
                  <button onClick={() => this.addAddress()} className="u-addButtonMb">
                    <p className='u-addAddress'>
                      AJOUTER UNE ADRESSE
                    </p>
                  </button>
                  <button className='u-submitButton' disabled={this.state.addressError || this.state.billingAddressError} id="confirm-profile" onClick={() => {
                    if((this.state.formState.Address.address !== "" && this.state.addressError === true) || (this.state.formState.BillingAddress.address !== "" && this.state.billingAddressError === true)) {
                      return;
                    }
                    this.props.Submit(this.state.formState)}}
                  >
                    {this.props.loading
                      ? <img src={LoadingLogo} alt="loading" className='tl-img' />
                      : <p className='u-submitButtonText'>METTRE À JOUR MON COMPTE</p>
                    }
                  </button>
                </div>
                {this.props.message &&
                  <p>{this.props.message}</p>
                }
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.open}
          id={"Confirmation"}
        >
          <div className='u-ModalContainer' onClick={() => this.setState({open: false})}>
            <div id="delete-address-dialog" className='u-DialogContainer'>
              <button onClick={() => this.setState({open: false})} className="u-DialogCloseButton">
                <img src={CloseLogo} alt="close" className='u-DialogCloseButtonIcon' />
              </button>
              <p className='u-DialogText'>
                Supprimer cette adresse ?
              </p>
              <div className='u-ButtonsContainer'>
                <button id="cancel-mode" className='u-DialogCancelButton' onClick={() => this.setState({open: false})}>
                  <p className='u-modal-cancel-text'>
                    Annuler
                  </p>
                </button>
                <button
                  id="confirm-mode-dialog"
                  className='u-DialogConfimButton'
                  onClick={() => {
                    this.onDeletePress()
                }}>
                  <p className='u-modal-validate-text'>
                    Valider
                  </p>
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default FormHOC(
  {
    prefill: GetPersonalInfoHttpService,
    action: UpdatePersonalInfoHttpService,
    successMessage: "Mise à jour des informations personnelles avec succès"
  },
  withNavigateHook(UserProfile)
);