import React, { useState } from 'react'

import './style.scss'

export const FirstName = ( { fieldValue, setFieldValue } ) => {
  const [error, setError] = useState("")

  const validate = (value) => {
    if (!value)
      return 'Ce champ est obligatoire'

    return null
  }

  const setFirstName = (value) => {
    const error = validate(value)

    if (error)
      setError(error)
    else
      setError("")

    setFieldValue(value)
  }

  return (
    <div className='if-field'>
      <p className='if-fieldLabel'>Prénom</p>

      <input
        value={fieldValue}
        placeholder={"Entrez votre prénom"}
        onChange={(evt) => setFirstName(evt.target.value)}
        className="if-inputTest"
      />

      {error && <p className='if-fieldError'>{error}</p>}
    </div>
  )
}

export default FirstName;