import { getAuthToken } from "../../../Utils/Auth"
import { __PublicAPI__ } from "../../../Infra/HttpServices"

export const GetCartHttpService = async (id) => {
  let requestURI = `${__PublicAPI__}/api/cart/`
  let requestHeaders = {}

  const token = await getAuthToken()

  if (id)
    requestURI += `${id}`

  if (token)
    requestHeaders['Authorization'] = `Bearer ${token}`

  const response = await fetch(
    requestURI,
    {
      method: "GET",
      headers: requestHeaders
    }
  )

  return response.json()
}

export const AddCartHttpService = async (body, restaurantId, diningMode) => {
  let requestHeaders = { 'Content-Type': 'application/json' }

  const token = await getAuthToken()

  if (token)
    requestHeaders['Authorization'] = `Bearer ${token}`

  const response = await fetch(`${__PublicAPI__}/api/cart`,
    {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({
        content: JSON.stringify(body),
        restaurantId: restaurantId,
        diningMode: diningMode,
      })
    }
  )

  return response.json()
}

export const UpdateCartHttpService = async (id, body, diningMode, PromotionId, CodePromotion) => {
  let requestHeaders = { 'Content-Type': 'application/json' }

  const token = await getAuthToken()

  if (token)
    requestHeaders['Authorization'] = `Bearer ${token}`

  const response = await fetch(`${__PublicAPI__}/api/cart/${id}`,
    {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify({
        content: JSON.stringify(body),
        diningMode: diningMode,
        PromotionId,
        CodePromotion
      })
    }
  )

  if (response.status === 201 || response.status === 200)
    return {}

  return response.json()
}

export const DeleteCartHttpService = async (id) => {
  const token = await getAuthToken()
  
  const response = await fetch(`${__PublicAPI__}/api/cart/${id}`,
    {
      method: "DELETE",
      headers:
      {
        'Authorization': `Bearer ${token}`
      }
    }
  )

  if (response.status === 200)
    return {}

  return response.json()
}

export const getLoyaltyDetails = async (restaurantId) => {
  const token = await getAuthToken()
  const response = await fetch(`${__PublicAPI__}/api/order/loyaltyPoints?restaurantId=${restaurantId}`,
  {
    method: "GET",
    headers:
    {
      'Authorization': `Bearer ${token}`
    }
  }
  )

  if (!response.ok)
    throw new Error("Une erreur s'est produite lors de la connexion")

  const body = await response.json()

  return body;
}
