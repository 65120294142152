import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'

const FormHOC = ({ successRedirect, successMessage, action, prefill }, WrappedComponent) => {
  const HOC = (props) => {
    const navigate = useNavigate();
    const [state, setState] = useState({
      loading: false,
      errors: {},
      error: "",
      redirect: false,
      message: "",
      formData: {}
    });

    useEffect(() => {
      async function HOCFunc() {
        if (prefill) {       
          const HttpResponse = await prefill()
        
          if (HttpResponse.error)
            return setState({ ...state, error: HttpResponse.error, loading: false })
  
          return setState({ ...state, formData: HttpResponse, loading: false})
        }
      }
      HOCFunc();
    }, []);

    async function Submit(data) {
      // Reset form state
      setState({ ...state, loading: true, errors: {}, error: "" })

      const HttpResponse = await action(data)

      if (HttpResponse.errors)
        return setState({ ...state, errors: HttpResponse.errors, loading: false })

      if (HttpResponse.error)
        return setState({ ...state, error: HttpResponse.error, loading: false })

      if (HttpResponse.ok && successRedirect)
        return navigate(successRedirect)

      if (HttpResponse.ok && successMessage)
        return setState({ ...state, message: successMessage, loading: false })

      setState({ ...state, loading: false , errors: {}, error: "Une erreur s'est produite" })
    }

    return (
      <WrappedComponent
        {...props}
        Submit={(data) => Submit(data)}
        loading={state.loading}
        errors={state.errors}
        error={state.error}
        formData={state.formData}
        message={state.message}
      />
    )
  }

  return HOC;
}

export default FormHOC;