import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuContext } from '../../context'

import Address from '../Address'
import withNavigateHook from '../WithNavigation';
import './style.scss'

const Autocomplete = () => {
  const context = useContext(MenuContext);
  const navigate = useNavigate();
  const [autoComplete, setautoComplete] = useState(null);

  const onPlaceChanged = () => {
    if (autoComplete === null)
      return
   
    const place = autoComplete.getPlace();

    const formattedAddress = place.formatted_address
    const coords = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    }

    return context.updateUserPlace(formattedAddress, coords, () => navigate("/search"))
  }

  const onAutoCompleteLoad = (autocomplete) => setautoComplete(autocomplete)

  return (
    <div className='auto-complete-container'>
      {/* re-render hack */}
      {autoComplete === null
        ? <Address onPlaceChanged={onPlaceChanged} onLoad={onAutoCompleteLoad} />
        : <Address onPlaceChanged={onPlaceChanged} onLoad={onAutoCompleteLoad} />
      }
    </div>
  )
}

export default withNavigateHook(Autocomplete);