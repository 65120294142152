import React from 'react';
import { __ImageHost__ } from '../../../Infra/HttpServices';
import CloseIcon from "../../../static/img/letter-xblack.svg";
import './style.scss';

const AllergenList = ({ onClose, list }) => (
  
  <div className="allergen-container" onClick={() => onClose()}>
    <div className='dialog-container'>
      <div className='header'>
        <p>ALLERGÈNES :</p>
      </div>
      <button onClick={() => onClose()} className="dialog-close-button">
        <img src={CloseIcon} alt="close" />
      </button>
      <div className='items-container'>
        {list.map((allergen) =>
          <div
            key={allergen.Id}
            className="card"
          >
            <div className='allergen-container'>
              <div className='image-container'>
                <img width="150px"  src={`${allergen.Image}`} alt="allergen" />
              </div>
              <p className='name'>{allergen.Name}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
)

export default AllergenList;
