import React from 'react'
import Modal from '../../../../../components/Modal'

import AllergenList from '../../../../../components/Card/Allergens'

const AllergenesView = ({ allergenes, onClose, open, Style }) => {
  return (
    <Modal
      isOpen={open}
      id="Allergen"
    > 
      <AllergenList list={allergenes} onClose={onClose}/>
    </Modal>
  )
}

export default AllergenesView;