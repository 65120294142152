export const buildRequestParams = (params) => {
  let getParams = ``
    
  if (!params || Object.keys(params).length === 0)
    return getParams

  for (let [key, value] of Object.entries(params)) {
    if (value === undefined || null)
      continue

    if (typeof value === "string")
      value = encodeURIComponent(value)

    getParams += `&${key}=${value}`
  }

  // TODO: think of a better solution
  return `?${getParams.slice(1, getParams.length)}`
}