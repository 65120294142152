import React, { useState, useContext } from "react";
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';

import moment from "moment";

import { ToggleFavorite } from '../../../HttpServices';
import { MenuContext } from '../../../../../context';
import Dialog from '../../../../../components/Dialog';
import { getRestaurant } from '../../../../Restaurant/HttpServices';

import OrderModal from "../Modal"
import { __ImageHost__ } from "../../../../../Infra/HttpServices";
import Modal from "../../../../../components/Modal";

import '../thumbnail.scss';

require("moment/locale/fr");
moment().locale("fr");

const Delivery = ({ order, Style }) => {

  const context = useContext(MenuContext)
  const [favorite, setFavorite] = useState(order.IsFavorite)
  const [openModal, setOpenModal] = useState(false)
  const [func, setFunc] = useState(null)
  const [cartConfirmationOpen, setCartConfirmationOpen] = useState(false)
  const [diningConfirmationOpen, setDiningConfirmationOpen] = useState(false)

  const showCartConfirmation = (callback) => {
    setFunc(() => callback)
    setCartConfirmationOpen(true);
  }

  const showDiningModeConfirmation = (callback) => {
    if (!context.cartId) callback()

    if (context.diningMode !== "delivery") {
      setFunc(() => callback)
      setDiningConfirmationOpen(true);
    }
  }

  const onButtonPress = (items) => {
    let Items = [];
    let index = 0;
    let composableProduct;

    ///////////////////////////////////////
    ////////Add items to cart
    //////////////////////////////////////

    function addToCart(items) {

      for (let i = 0; i < items.length; i++) {
        // Item is a product
        if (items[i].Type === 'single') {
          for (let y = 0; y < items[i].Quantity; y++) {
            context.addCartEntryFromOrders({
              Id: items[i].Id,
              ProductId: items[i].Id,
              Type: "single",
              Name: items[i].Name,
              Image: items[i].Image,
              Price: items[i].Price,
              Allergenes: items[i].Allergenes,
              LoyaltyPointsPrice: items[i].LoyaltyPointsPrice,
              LoyaltyPointsProd: items[i].LoyaltyPointsProd,
              LoyaltyPointsRetrait: items[i].LoyaltyPointsRetrait,
              IdPcAchatOffert: items[i].IdPcAchatOffert,
              OfIsApply: items[i].OfIsApply,
              OfNbAc: items[i].OfNbAc,
              OfNbOf: items[i].OfNbOf,
              family: items[i].family
            })
          }
        }
        // Item is a Menu (composable product)
        if (items[i].Type === 'composable') {

          for (let y = 0; y < items[i].Quantity; y++) {
            context.saveCartEntryFromOrders(items[i]);
          }
        }
      }
      
      context.addCartToDbFromOrders();

      toast.success('Article(s) ajouté au panier');
    }

    async function changeModeAndAdd(items) {
      await context.setDiningMode('delivery');
      addToCart(items);
    }

    async function changeRestaurantAndAdd(items, restaurant) {
      if (context.diningMode !== "delivery")
        await context.setDiningMode("delivery")

      await context.setActiveRestaurant(restaurant)
      addToCart(items)
    }

    function setComposableProduct(item, family, category) {
      let Category = { ...category }

      Category.Products = []

      category.Products.map(p => {
        let prdct = { ...p }
        prdct.Components = []
        p.Components.map(c => {
          if (c.IsOutOfStock !== true) {
            prdct.Components = [...prdct.Components, c]
          }
        })
        Category.Products = [...Category.Products, prdct]
      });

      composableProduct = {
        Id: uuid(),
        Components: [],
        Compositions: [],
        Name: item.Name,
        ProductId: category.Id,
        Quantity: item.Quantity,
        RestaurantId: item.RestaurantId,
        Sections: [],
        Type: "composable",
        complete: true,
        diningMode: "delivery",
        family: family,
        menuNode: Category,
        totalCost: 0
      }

      category.Products.map(section => {
        composableProduct.Sections = [...composableProduct.Sections, { Id: section.Id, Name: section.Name }]
      })

      item.Components.map(component => {

        category.Products.map(productSection => {

          productSection.Components.map(productSectionComp => {
            if (productSectionComp.Id === component.Id && productSectionComp.IsOutOfStock !== true) {

              composableProduct.Components = setComponents(composableProduct.Components, productSectionComp, component, item);
            } else {
              //error
              return false;
            }
          })
        })
      })

      return composableProduct;
    }

    function calculatePrice(composableProduct, item) {
      let error = "false";
      let cost = 0
      let Mntoffered = item.Mntoffered || 0;
      composableProduct.Components.forEach(item => cost += (item.Price * item.Quantity))
      composableProduct.Compositions.forEach(item => cost += (item.Price * item.Quantity))

      cost = cost - Mntoffered
      // Fix precision
      cost = Math.round(cost * 100) / 100

      composableProduct.totalCost = cost;
      if (composableProduct.Components.length !== item.Components.length || composableProduct.Compositions.length !== item.Compositions.length) {
        //error
        error = "true";
      } else {
        Items[index] = composableProduct;
        index++;
      }

      if (error === "true")
        return "error";

      return cost;
    }

    function setComponents(Components, productSectionComp, component, item) {
      if (productSectionComp.Compositions.length !== 0) {

        item.Compositions.map(c => {

          productSectionComp.Compositions.map(pc => {

            if (pc.Id === c.Id) {
              composableProduct.Compositions = setCompositions(composableProduct.Compositions, pc, c);
            }
          })
        })
      }

      let newVal = [...Components, {
        Allergenes: productSectionComp.Allergenes,
        Compositions: productSectionComp.Compositions,
        HasCompositions: productSectionComp.HasCompositions,
        Id: productSectionComp.Id,
        Image: productSectionComp.Image,
        IsNextButton: productSectionComp.IsNextButton,
        LoyaltyPointsPrice: productSectionComp.LoyaltyPointsPrice,
        LoyaltyPointsProd: productSectionComp.LoyaltyPointsProd,
        LoyaltyPointsRetrait: productSectionComp.LoyaltyPointsRetrait,
        MaxSelection: productSectionComp.MaxSelection,
        Name: productSectionComp.Name,
        Price: productSectionComp.PriceDelivery,
        Quantity: component.Quantity,
        SectionIndex: component.SectionIndex,
        IdPcAchatOffert: component.IdPcAchatOffert,
        OfIsApply: component.OfIsApply,
        OfNbAc: component.OfNbAc,
        OfNbOf: component.OfNbOf,
      }]

      return newVal;
    }

    function setCompositions(Compositions, productComp, composition) {
      let newVal = [...Compositions, {
        Allergenes: productComp.Allergenes,
        ComponentId: composition.ComponentId,
        Id: productComp.Id,
        Image: productComp.Image,
        IsDefault: productComp.IsDefault,
        MaxSelection: productComp.MaxSelection,
        Name: productComp.Name,
        Price: productComp.Price,
        Quantity: composition.Quantity,
        quantity: composition.quantity
      }]

      return newVal;
    }

    getRestaurant(order.PointOfSale.Id).then(async (restaurant) => {
      await context.setActiveRestaurant(restaurant)

      const menu = restaurant.MenuJSON ? JSON.parse(restaurant.MenuJSON).filter(item => item.IsDelivrable) : null

      items.map((item, i) => {

        if (item.Type === "single") {
          ///////////////////////////////////////
          ////////Item is single
          //////////////////////////////////////
          let Menu = menu.filter(m => m.Id === item.family.Id)[0]

          if (Menu.length !== 0) {
            let Family = Menu.Categories.filter(c => c.Id === item.Id && c.IsOutOfStock !== true)[0]

            if (Family.length !== 0) {
              Items[index] = {
                Id: item.Id,
                ProductId: item.Id,
                Type: "single",
                Name: Family.Name,
                Image: Family.Image,
                Price: Family.PriceDelivery,
                Allergenes: Family.Allergenes,
                LoyaltyPointsPrice: Family.LoyaltyPointsPrice,
                LoyaltyPointsProd: Family.LoyaltyPointsProd,
                LoyaltyPointsRetrait: Family.LoyaltyPointsRetrait,
                family: Menu,
                IdPcAchatOffert: Family.IdPcAchatOffert,
                OfIsApply: Family.OfIsApply,
                OfNbAc: Family.OfNbAc,
                OfNbOf: Family.OfNbOf,
                Quantity: item.Quantity
              }
              index++;
            }
          } else {
            return false;
          }
        } else if (item.Type === "composable") {
          ///////////////////////////////////////
          ////////Item is composable
          //////////////////////////////////////
          menu.map(family => {

            if (family.Id === item.family.Id) {

              family.Categories.map(category => {

                if (category.Categories === undefined) {

                  if (category.Id === item.ProductId && category.IsOutOfStock !== "true") {
                    let composableProduct = setComposableProduct(item, family, category);

                    if (composableProduct === "error")
                      return false;

                    let cost = calculatePrice(composableProduct, item);

                    if (cost === "error")
                      return false;

                  } else {
                    //error
                    return false;
                  }

                } else {
                  category.Categories !== undefined && category.Categories.map(product => {
                    if (product.Categories != null && product.Categories.length > 1) {
                      product.Categories.map(categ => {
                        if (categ.Id === item.ProductId && categ.IsOutOfStock !== "true") {
                          let composableProduct = setComposableProduct(item, family, categ);

                          if (composableProduct === "error")
                            return false;

                          let cost = calculatePrice(composableProduct, item);

                          if (cost === "error")
                            return false;

                        } else {
                          //error
                          return false;
                        }
                      })
                    } else if (product.Id === item.ProductId && product.IsOutOfStock !== "true") {
                      let composableProduct = setComposableProduct(item, family, product);

                      if (composableProduct === "error")
                        return false;

                      let cost = calculatePrice(composableProduct, item);

                      if (cost === "error")
                        return false;


                    } else {
                      //error
                      return false;
                    }

                  })
                }

              })
            } else {
              //error
              return false;
            }
          })
        }
      })

      if (Items.length !== items.length) {
        toast.error('Un ou plusieurs produits sont indisponibles ou épuisés')
        return;
      } else {
        if (context.cart.length > 0 && restaurant.Id !== context.cart[0].RestaurantId) {
          return showCartConfirmation(() => changeRestaurantAndAdd(Items, restaurant));
        }

        if (context.diningMode !== "delivery") {
          if (context.cart.length > 0) {
            return showDiningModeConfirmation(() => changeModeAndAdd(Items));
          } else {
            return changeModeAndAdd(Items);
          }
        }else {
          addToCart(Items);
        }
      }

    })
  }

  const convertUTCDateToLocalDate = (date) => {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)

    var offset = date.getTimezoneOffset() / 60
    var hours = date.getHours()

    newDate.setHours(hours - offset)

    return newDate
  }

  // Transform decalage to user local time
  let orderTime
  if (order.PrepareFor) {
    var prepareTime = moment(order.PrepareFor)
    let orderPrepareFor = convertUTCDateToLocalDate(prepareTime.toDate())
    orderTime = moment(orderPrepareFor).format('LLL')
  }

  let createTime
  if (order.CreatedAt) {
    var orderDate = moment(order.CreatedAt, 'DD.MM.YYYY hh:mm')

    let orderCreatedAt = convertUTCDateToLocalDate(orderDate.toDate())
    createTime = moment(orderCreatedAt, 'DD.MM.YYYY hh:mm').format('LLL')
  }

  return (
    <div className='order-thumbnail-container'>
      <OrderModal setOpenModal={(bool) => setOpenModal(bool)} openModal={openModal} order={order} />

      <div className='logo-container'>
        <img src={`${order.Image && order.Image !== undefined ? __ImageHost__ + "/" + order.Image : "" }`} alt="pv" />
      </div>

      <div className="infos-container">
        <div className="dining-mode">
          <div className="dining-mode-payment">
            <button
              onClick={() => {
                setFavorite(!favorite);
                ToggleFavorite(order.Id, !favorite);
              }}
              className="fav-container"
            >
              {favorite === true ? <img src={require("../../../../../static/img/heart_full.png")} alt="heart" />: <img src={require("../../../../../static/img/heart_orange.png")} alt="heart" />}
            </button>
            {order.Status === "cashPayment"?
              <img src={require("../../../../../static/img/cash-payment_black.png")} alt="cash" />:
              <img src={require("../../../../../static/img/stripe-payment_black.png")} alt="stripe" />
            }
            <img src={require("../../../../../static/img/livraison_black.png")} alt="livraison" />
          </div>
        </div>

        <div>

          <div className="infos">
            <p>Référence: </p>
            <p>{order.Reference}</p>
          </div>
          
          <div className="infos">
            <p>Restaurant: </p>
            <p>{order.PointOfSale.Name}{order.PointOfSale.Address !== null ? ", " + order.PointOfSale.Address : null}</p>
          </div>

          <div className="infos">
            <p>Prix total: </p>
            <p>{order.Price}€</p>
          </div>

          {
            createTime &&
            <div className="infos">
              <p>Date: </p>
              <p>{createTime}</p>
            </div>
          }

          {
            orderTime &&
            <div className="infos">
              <p>A récupérer le: </p>
              <p>{orderTime}</p>
            </div>
          }

          <div className="infos">
            <p>Produits: </p>
            <div>
              <div className="p-container">
                {order.Products.map((product, index) => <p key={index}>{product.Name}</p>)}
              </div>
            </div>
          </div>

        </div>

        <button onClick={() => setOpenModal(true)} className="details">
          <p>Plus de détails</p>
        </button>

        <button onClick={() => onButtonPress(order.OrderDetailed)} className="button">
          <p>Commander à nouveau</p>
        </button>
      </div>

      <Modal
        isOpen={diningConfirmationOpen}
        id="Dialog-cart"
      >
        <Dialog
          close={() => setDiningConfirmationOpen(false)}
          context={context}
          onConfirm={func}
          message={
            "Certains produits ne sont pas disponibles dans le mode que vous avez choisi, videz le panier ?"
          }
        />
      </Modal>

      <Modal
        isOpen={cartConfirmationOpen}
        id="cart-confirmation"
      >
        <Dialog
          close={() => setCartConfirmationOpen(false)}
          context={context}
          onConfirm={func}
          message={
            "Vous devez vider le panier car il contient des produits d'un autre restaurant"
          }
        />
      </Modal>
    </div>
  );
};

export default Delivery;
