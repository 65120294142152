import React, { useContext } from 'react';

import { getDiningModeLabel } from '../../../../components/DiningModeControls/Utils'
import { MenuContext } from '../../../../context';

import './style.scss';

const CartListView = ({ restaurant, diningMode, cart, totalCost, totalPoints }) => {
  const context = useContext(MenuContext)
  
  return (
    <div className='cartlist-section'>
      {/* Command header */}
      <p className='cartlist-commandHeader'>Détails de la commande</p>
      <p className='cartlist-commandRestaurant'>{restaurant.name}</p>
      <p className='cartlist-commandDiningMode'>{getDiningModeLabel(diningMode)}</p>

      {/* Command list */}
      <div className='cartlist-commandList'>

        {/* list header */}
        <div className='cartlist-commandListHeader'>
          <p className='cartlist-commandListRowText'>Produit</p>
          <p className='cartlist-commandListRowQuantity'>Quantité</p>
          <p className='cartlist-commandListRowPrice'>Prix</p>
          <p className='cartlist-commandListRowPrice'>Points Cumulés</p>

        </div>

        {/* list rows */}
        {cart.map((entry, index) => (
          <div key={index} className="cartlist-commandListRow">
            <p className='cartlist-commandListRowText'>{entry.Name}</p>
            <p className='cartlist-commandListRowQuantity'>{`x ${entry.Quantity}`}</p>
            {context.remise && context.remise.IdPromo !== 0 &&
              <p className='cartlist-commandListRowPrice'>{context.getCartEntryTotalCostWithoutRemise(entry)}€</p>
            }
            {(!context.remise || context.remise.IdPromo === 0) &&
              <p className='cartlist-commandListRowPrice'>{context.getCartEntryTotalCost(entry)}€</p>
            }
            {context.getCartEntryLoyaltyPoints(entry) > 0 &&
              <p className='cartlist-commandListRowPrice'>{context.getCartEntryLoyaltyPoints(entry)} pts</p>
            }
            {context.getCartEntryLoyaltyPoints(entry) <= 0 &&
              <p className='cartlist-commandListRowPrice'>-</p>
            }
          </div>
        ))}
      </div>

      {context.remise && context.remise.IdPromo !== 0 &&
        <div className='cartlist-commandListFooter'>
          <p className='cartlist-commandListRowRemiseText'>Remise</p>
          <p className='cartlist-commandListRowRemise'></p>
          {context.remise.IsPct &&
            <p className='cartlist-commandListRowRemise'>{`-${context.remise.Pct}%`}</p>
          }
          {!context.remise.IsPct &&
            <p className='cartlist-commandListRowRemise'>{`-${context.remise.Mnt}€`}</p>
          }
          <p className='cartlist-commandListRowRemise'></p>
        </div>
      }

      {/* list footer */}
      <div className='cartlist-commandListFooter'>
        <p className='cartlist-commandListRowText'></p>
        <p className='cartlist-commandListRowQuantity'></p>
        <p className='cartlist-commandListRowPrice'>{totalCost}€</p>
        {totalPoints > 0 ?
          <p className='cartlist-commandListRowPrice'>{totalPoints}pts</p> :
          <p className='cartlist-commandListRowPrice'>-</p>
        }
      </div>
    </div>
  )
}

export default CartListView;